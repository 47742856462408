export const defaultValues = [
  {
    title: 'Pima Polos',
    tags: [],
    heading: 'PIMA POLOS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'moisture-wicking' },
      { poster: 'quick-dry' },
      { poster: 'upcycle' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Infinity Chino Pants',
    tags: ['a-infinity-chino-pants'],
    heading: 'INFINITY CHINO PANTS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'linear-flex' },
      { poster: 'moisture-wicking' },
      { poster: 'hidden-pockets' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Infinity Chino Shorts',
    tags: ['a-infinity-chino-shorts'],
    heading: 'INFINITY CHINO SHORTS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'linear-flex' },
      { poster: 'moisture-wicking' },
      { poster: 'hidden-pockets' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Knit Sweaters',
    tags: ['a-knit-sweaters'],
    heading: 'KNIT SWEATERS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'breathable' },
      { poster: 'easy-care' },
      { poster: 'soft-touch' },
      { poster: 'double-knit-jacquard-weaves' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Yeager Jacket',
    tags: ['a-yeager-jacket'],
    heading: 'YEAGER 4-WAY STRETCH JACKET',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'wrinkle-free' },
      { poster: 'soft-touch' },
      { poster: 'double-knit-jacquard-weaves' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Commuter Jacket',
    tags: ['a-commuter-jacket'],
    heading: 'COMMUTER 4-WAY STRETCH JACKET',
    detailsHeading: '',
    productFeatures: [
      { poster: 'soft-touch' },
      { poster: 'upcycle' },
      { poster: 'moisture-wicking' },
      { poster: 'wrinkle-free' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Country Road Jacket',
    tags: ['a-country-road-jacket'],
    heading: 'COUNTRY ROAD SHIRT JACKET',
    detailsHeading: '',
    productFeatures: [
      { poster: 'upcycle' },
      { poster: 'water-resistant' },
      { poster: 'linear-flex' },
      { poster: 'moisture-wicking' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Headwear',
    tags: ['a-headwear'],
    heading: 'FLEX-FIT HATS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'upcycle' },
      { poster: 'water-resistant' },
      { poster: 'linear-flex' },
      { poster: 'moisture-wicking' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Face Masks',
    tags: ['a-face-masks'],
    heading: 'FACE MASKS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'anti-dust' },
      { poster: 'washable' },
      { poster: 'skin-friendly' },
      { poster: 'breathable' },
    ],
  },
  {
    title: 'Infinity Jacket',
    tags: ['a-finity-jacket'],
    heading: 'INFINITY 4-WAY STRETCH JACKET',
    detailsHeading: '',
    productFeatures: [
      { poster: 'soft-touch' },
      { poster: 'breathable' },
      { poster: 'easy-care' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: '2-Way Stretch Pants - Ultimate',
    tags: ['a-ultimate-pant'],
    heading: 'ULTIMATE PANT',
    detailsHeading: '',
    productFeatures: [
      { poster: 'soft-touch' },
      { poster: 'breathable' },
      { poster: 'easy-care' },
      { poster: '2-way-stretch' },
    ],
  },
  {
    title: '4-Way Stretch Chino - Aiden',
    tags: ['a-aiden-chino-pant'],
    heading: 'AIDEN CHINO PANT',
    detailsHeading: '',
    productFeatures: [
      { poster: 'soft-touch' },
      { poster: 'breathable' },
      { poster: 'easy-care' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: '2-Way Stretch Pants - Oliver',
    tags: ['a-oliver-chino-pant'],
    heading: 'OLIVER CHINO PANT',
    detailsHeading: '',
    productFeatures: [
      { poster: 'soft-touch' },
      { poster: 'breathable' },
      { poster: 'easy-care' },
      { poster: '2-way-stretch' },
    ],
  },
  {
    title: 'Clifton Pants',
    tags: ['a-clifton-pants'],
    heading: 'CLIFTON PANT',
    detailsHeading: '',
    productFeatures: [
      { poster: 'soft-touch' },
      { poster: 'breathable' },
      { poster: 'easy-care' },
      { poster: '2-way-stretch' },
    ],
  },
  {
    title: 'Polos - Older Styles',
    tags: [],
    heading: 'POLOS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'antimicrobial' },
      { poster: 'wrinkle-free' },
      { poster: 'moisture-wicking' },
    ],
  },
  {
    title: 'Polos - Luxseam',
    tags: [],
    heading: 'POLOS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'moisture-wicking' },
      { poster: 'antimicrobial' },
      { poster: 'quick-dry' },
      { poster: 'luxseam' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Polos - Double Knit Jacquard',
    tags: [],
    heading: 'POLOS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'double-knit-jacquard-weave' },
      { poster: 'moisture-wicking' },
      { poster: 'easy-care' },
      { poster: 'wrinkle-free' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: '4-Way Stretch Polos',
    tags: [],
    heading: '4-Way Stretch Polos',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'antimicrobial' },
      { poster: 'easy-care' },
      { poster: 'moisture-wicking' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Hybrid Shorts Perforated',
    tags: [],
    heading: 'HYBRID SHORT',
    detailsHeading: '',
    productFeatures: [
      { poster: 'moisture-wicking' },
      { poster: 'water-resistant' },
      { poster: 'polymuse' },
      { poster: 'easy-care' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Hybrid Shorts Upcycled',
    tags: [],
    heading: 'HYBRID SHORTS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'moisture-wicking' },
      { poster: 'water-resistant' },
      { poster: 'quick-dry' },
      { poster: 'upcycle' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Hybrid Short Basic',
    tags: ['a-hybrid-short-basic'],
    heading: 'HYBRID SHORT',
    detailsHeading: '',
    productFeatures: [
      { poster: 'easy-care' },
      { poster: 'moisture-wicking' },
      { poster: 'water-resistant' },
      { poster: 'quick-dry' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: '2-Way Stretch Shirt - Dobby',
    tags: ['2-way-stretch-dobby-shirt'],
    heading: '2-WAY STRETCH BUTTON DOWN',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'easy-care' },
      { poster: 'soft-touch' },
      { poster: 'dobby-weave' },
      { poster: '2-way-stretch' },
    ],
  },
  {
    title: '2-Way Stretch Shirt',
    tags: ['a-2-way-stretch-short-sleeve'],
    heading: '2-WAY STRETCH BUTTON DOWN',
    detailsHeading: '',
    productFeatures: [
      { poster: '2-way-stretch' },
      { poster: 'shape-retention' },
      { poster: 'easy-care' },
      { poster: 'soft-touch' },
      { poster: 'breathable' },
    ],
  },
  {
    title: 'Core Active Short',
    tags: ['a-coreactiveshort'],
    heading: 'CORE ACTIVE SHORT',
    detailsHeading: '',
    productFeatures: [
      { poster: 'zipper-pockets' },
      { poster: 'moisture-wicking' },
      { poster: 'quick-dry' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Core Performance Collection',
    tags: ['a-core-performance-collection'],
    heading: 'CORE PERFORMANCE COLLECTION',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'hidden-pockets' },
      { poster: 'breathable' },
      { poster: 'soft-touch' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Restoration Collection',
    tags: ['a-restoration-collection'],
    heading: 'RESTORATION COLLECTION',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'hidden-pockets' },
      { poster: 'breathable' },
      { poster: 'soft-touch' },
      { poster: '4-way-stretch' },
    ],
  },
  {
    title: 'Infinity Button Down',
    tags: ['a-infinity-buttondown'],
    heading: 'LUXSEAM 4-WAY STRETCH',
    detailsHeading: '',
    productFeatures: [
      { poster: 'linear-flex' },
      { poster: 'luxseam' },
      { poster: 'moisture-wicking' },
      { poster: '4-way-stretch' },
      { poster: 'wrinkle-free' },
    ],
  },
  {
    title: 'Infinity 7-Pocket Pants',
    tags: ['a-infinity-7pocket'],
    heading: 'INFINITY 7-POCKET PANTS',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'linear-flex' },
      { poster: 'moisture-wicking' },
      { poster: '4-way-stretch' },
      { poster: 'seven-pockets' },
    ],
  },
  {
    title: 'Infinity Jogger',
    tags: ['a-infinity-jogger'],
    heading: 'Infinity Jogger',
    detailsHeading: '',
    productFeatures: [
      { poster: 'shape-retention' },
      { poster: 'linear-flex' },
      { poster: 'moisture-wicking' },
      { poster: '4-way-stretch' },
      { poster: 'hidden-pockets' },
    ],
  },
  {
    title: 'Generation Collection',
    tags: ['a-generation-jogger'],
    heading: 'GENERATION COLLECTION',
    detailsHeading: '',
    productFeatures: [
      { poster: '4-way-stretch' },
      { poster: 'breathable' },
      { poster: 'easy-care' },
      { poster: 'soft-touch' },
      { poster: 'double-knit-jacquard-weave' },
    ],
  },
  {
    title: '4-Way Stretch Short Sleeve',
    tags: ['a-4-way-stretch-short-sleeve'],
    heading: '4-WAY STRETCH SHORT SLEEVE',
    detailsHeading: '',
    productFeatures: [
      { poster: '4-way-stretch' },
      { poster: 'soft-touch' },
      { poster: 'wrinkle-free' },
      { poster: 'easy-care' },
      { poster: 'moisture-wicking' },
    ],
  },
  {
    title: '4-Way Stretch Long Sleeve',
    tags: ['a-4-way-stretch-long-sleeve'],
    heading: '4-WAY STRETCH LONG SLEEVE',
    detailsHeading: '',
    productFeatures: [
      { poster: '4-way-stretch' },
      { poster: 'soft-touch' },
      { poster: 'wrinkle-free' },
      { poster: 'easy-care' },
      { poster: 'moisture-wicking' },
    ],
  },
  {
    title: '4-Way Stretch Blazer',
    tags: ['a-4-way-stretch-blazer'],
    heading: '4-WAY STRETCH Blazer',
    detailsHeading: '',
    productFeatures: [
      { poster: '4-way-stretch' },
      { poster: 'wrinkle-free' },
      { poster: 'moisture-wicking' },
      { poster: 'water-resistant' },
      { poster: 'breathable' },
    ],
  },
  {
    title: 'Core V-Neck Tee',
    tags: ['a-core-v-neck-tee'],
    heading: 'CORE V-NECK TEE',
    detailsHeading: '',
    productFeatures: [
      { poster: '4-way-stretch' },
      { poster: 'upcycle' },
      { poster: 'quick-dry' },
      { poster: 'antimicrobial' },
      { poster: 'moisture-wicking' },
    ],
  },
  {
    title: 'Core Crew Neck Tee',
    tags: ['a-core-crew-neck-tee'],
    heading: 'CORE CREW NECK TEE',
    detailsHeading: '',
    productFeatures: [
      { poster: '4-way-stretch' },
      { poster: 'upcycle' },
      { poster: 'quick-dry' },
      { poster: 'antimicrobial' },
      { poster: 'moisture-wicking' },
    ],
  },
];

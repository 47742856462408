import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Svg } from '../Svg';

export function Select({
  collectionFiltersData,
  isSortButton,
  onSelect = () => {},
  options = [], // [{ label: 'label', value: 'value' }]
  placeholder,
  selectedOption, // { label: 'label', value: 'value' }
  setSortIsOpen,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const setFilterIsOpen = collectionFiltersData?.actions?.setIsOpen;

  useEffect(() => {
    if (!setFilterIsOpen) return undefined;
    const outsideClickHandler = (e) => {
      if (!ref.current.contains(e.target)) {
        setIsOpen(false);
        setSortIsOpen && setSortIsOpen(false);
      }
    };
    if (isOpen) {
      setFilterIsOpen(false);
      setSortIsOpen && setSortIsOpen(isOpen);
      document.addEventListener('click', outsideClickHandler);
    } else {
      document.removeEventListener('click', outsideClickHandler);
    }
    return () => {
      document.removeEventListener('click', outsideClickHandler);
    };
  }, [isOpen]);

  return (
    <div ref={ref} className="relative w-full">
      <button
        aria-label="Open account menu"
        className={`flex h-12 w-full items-center gap-4 py-3 px-5 text-base ${
          isSortButton
            ? 'justify-end max-md:gap-3 max-md:px-3'
            : 'h-[54px] justify-between border border-lightGray'
        }`}
        onClick={() => {
          if (options.length) {
            setIsOpen(!isOpen);
            setSortIsOpen && setSortIsOpen(!isOpen);
          }
        }}
        type="button"
      >
        <p className="text-[0.8125rem] font-semibold uppercase">
          {isSortButton ? placeholder : selectedOption?.label || placeholder}
        </p>

        <Svg
          className={`${isSortButton ? 'max-md:w-3' : ''} w-4 text-text ${
            isOpen ? 'rotate-180' : ''
          }`}
          src="/svgs/chevron-down.svg#chevron-down"
          title="Chevron"
          viewBox="0 0 24 24"
        />
      </button>

      <div
        className={`absolute left-0 z-10 w-full bg-background text-base transition duration-100 ${
          isOpen
            ? 'pointer-events-auto translate-y-0 opacity-100'
            : 'pointer-events-none -translate-y-1 opacity-0'
        }
        ${
          isSortButton
            ? 'top-[49px] left-auto right-0 w-[220px] max-md:text-sm md:right-0'
            : 'top-[calc(100%+0.5rem)] border border-gray'
        }`}
      >
        <ul className="flex max-h-[18rem] flex-col gap-y-1 overflow-y-auto py-3 md:gap-y-3 md:py-5">
          {options.map((option, index) => {
            const isActive = option.value === selectedOption?.value;
            return (
              <li key={index} className="flex">
                <button
                  aria-hidden={isOpen ? 'true' : 'false'}
                  className={`w-full py-1.5 px-5 ${
                    isSortButton ? 'text-right' : 'text-left'
                  } ${isActive ? 'font-bold' : 'font-medium'}`}
                  onClick={() => {
                    onSelect(option);
                    if (isSortButton) return;
                    setIsOpen(false);
                  }}
                  tabIndex={isOpen ? '0' : '-1'}
                  type="button"
                >
                  {option.label}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

Select.displayname = 'Select';
Select.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  selectedOption: PropTypes.object,
};

import React from 'react';
import { ProductItem } from '@snippets/ProductItem';

export function GWPSelectorModal({ products }) {
  return (
    <div className="py-6 px-8">
      <ul className="mx-auto grid grid-cols-1 gap-x-3 gap-y-5 xs:gap-x-5 xs:gap-y-8 md:grid-cols-2 lg:grid-cols-4">
        {products?.map((product, index) => {
          return (
            <li key={index}>
              <ProductItem
                enabledStarRating
                handle={product}
                index={index}
                isGWP
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

GWPSelectorModal.displayName = 'GWPSelectorModal';

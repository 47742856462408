import { useInView } from 'react-intersection-observer';

import { Picture } from '@snippets';

export function ProductMobileImage({
  media,
  title,
  priority,
  handleMediaClick,
}) {
  const { image } = media;

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  });

  const isVisible = priority || inView;

  if (!image?.src) return null;

  return (
    <div
      className="relative bg-offWhite"
      ref={ref}
      style={{ aspectRatio: 'var(--product-image-aspect-ratio)' }}
      onClick={handleMediaClick}
    >
      {isVisible && (
        <Picture
          alt={title}
          aboveTheFold
          images={[
            {
              src: image.src,
              width: 750,
              ratio: 0.74,
            },
          ]}
        />
      )}
    </div>
  );
}

ProductMobileImage.displayName = 'ProductMobileImage';

import { useState } from 'react';
import { useMediaPredicate } from 'react-media-hook';

import { Svg } from '@snippets';
import { CollectionFilterOption } from './CollectionFilterOption';

export function CollectionDropdownFilter({
  activeFilters,
  addFilter,
  defaultOpen = false,
  filter,
  index,
  removeFilter,
}) {
  const isDesktop = useMediaPredicate('(min-width: 768px)');
  const [isOpen, setIsOpen] = useState(defaultOpen || isDesktop);

  return (
    <div
      className={`flex flex-col border-t border-border md:border-none ${
        index === 0 ? 'max-md:border-none' : ''
      }`}
    >
      <button
        aria-label={filter.label}
        className={`relative flex w-full items-center justify-between gap-4 py-4 text-left md:w-max md:pt-0 md:pb-5 ${
          isOpen || isDesktop ? 'border-b md:border-none' : ''
        }`}
        onClick={() => {
          if (isDesktop) return;
          setIsOpen(!isOpen);
        }}
        type="button"
        disabled={isDesktop}
      >
        <h3 className="text-nav text-nowrap md:text-lg md:tracking-[2px]">
          {filter.label}
        </h3>

        <div className="hidden md:block">
          <Svg
            className={`hidden w-4 text-text ${isOpen ? 'rotate-180' : ''}`}
            src="/svgs/chevron-down.svg#chevron-down"
            title="Chevron"
            viewBox="0 0 24 24"
          />
        </div>

        <div className="mr-2 md:hidden">
          {isOpen ? (
            <Svg
              className="w-2 text-current"
              src="/svgs/minus.svg#minus"
              title="Minus"
              viewBox="0 0 12 13"
            />
          ) : (
            <Svg
              className="w-2 text-current"
              src="/svgs/plus.svg#plus"
              title="Plus"
              viewBox="0 0 12 13"
            />
          )}
        </div>
      </button>

      <ul
        className={`flex flex-col gap-y-5 pt-5 pb-8 ${
          isOpen || isDesktop ? '' : 'hidden'
        } md:grid md:grid-flow-col   md:grid-rows-8 md:items-start md:gap-x-8 md:pb-10 md:pt-0`}
      >
        {filter.values.map((value, index) => {
          return (
            <li key={index}>
              <CollectionFilterOption
                activeFilters={activeFilters}
                addFilter={addFilter}
                count={value.count}
                filterOption={value}
                isColor={filter.isColor}
                name={filter.name}
                removeFilter={removeFilter}
                value={value.value}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

CollectionDropdownFilter.displayName = 'CollectionDropdownFilter';

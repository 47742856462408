import { useMemo } from 'react';

import { Link } from '@snippets';

export function HeroContent({
  aboveTheFold,
  cmsSectionId,
  contentPlacement,
  isFirstSlide,
  slide,
}) {
  const { button, content, text } = slide;
  const {
    colorMobile,
    colorDesktop,
    enableHeadingDivider,
    enableSuperheadingLines,
    heading,
    subheading,
    superheading,
    style: textStyle,
    hiddenHeading,
    font,
  } = { ...text };
  const {
    alignmentMobile,
    alignmentDesktop,
    darkOverlay,
    maxWidthMobile,
    maxWidthDesktop,
    positionMobile,
    positionDesktop,
  } = {
    ...content,
  };
  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;
  const positionClasses = `${positionMobile} ${positionDesktop}`;
  const maxWidthContentClasses = `${maxWidthMobile} ${maxWidthDesktop}`;
  const darkOverlayClass = darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : '';

  const headingWithBreaks = useMemo(() => {
    if (!heading) return '';
    const splitHeading = heading.split('\n');
    if (splitHeading?.length === 1) return heading;
    return splitHeading?.reduce((acc, line, index, arr) => {
      acc.push(<span key={index}>{line}</span>);
      if (index < arr.length - 1) acc.push(<br key={`br${index}`} />);
      return acc;
    }, []);
  }, [heading]);
  const headingFont =
    font === 'editors'
      ? 'font-editors'
      : font === 'demi'
      ? 'font-demi'
      : 'font-sans';

  const headingClasses = ` ${
    textStyle === 'large' ? 'text-title-hero' : 'text-title-h3 text-4xl'
  }  ${hiddenHeading ? 'opacity-0 pointer-events-none' : ''} ${headingFont}`;

  return (
    <div
      className={`pointer-events-none inset-0 flex h-full w-full px-4 md:px-8 xl:px-12 ${
        contentPlacement === 'inside-image'
          ? 'absolute py-5 md:py-10'
          : 'relative py-5 md:py-8'
      } ${positionClasses} ${darkOverlayClass}`}
    >
      <style>
        {`
        .section-${cmsSectionId} .text-color {
            color: ${colorMobile} !important;
          } .background-color {
            background-color: ${colorMobile} !important;
          }

          @media only screen and (min-width: 768px) {
            .section-${cmsSectionId} .text-color {
              color: ${colorDesktop} !important;
            } .background-color {
              background-color: ${colorDesktop} !important;
            }
          }

          @media only screen and (min-width: 1024px) {
            .section-${cmsSectionId} .text-color {
              color: ${colorDesktop} !important;
            } .background-color {
              background-color: ${colorDesktop} !important;
            }
          }
        `}
      </style>
      <div
        className={`pointer-events-auto flex flex-col ${alignmentClasses} ${maxWidthContentClasses} text-color`}
      >
        {superheading && (
          <div className="relative mb-1 md:mb-5">
            {enableSuperheadingLines && (
              <>
                <div className="absolute top-[calc(50%-1px)] left-0 w-[2.3438rem] border-b" />
                <div className="absolute top-[calc(50%-1px)] right-0 w-[2.3438rem] border-b" />
              </>
            )}
            <p
              className={`text-superheading ${
                enableSuperheadingLines ? 'px-[3.75rem]' : ''
              }`}
            >
              {superheading}
            </p>
          </div>
        )}

        {headingWithBreaks &&
          (aboveTheFold && isFirstSlide ? (
            <h1 className={headingClasses}>{headingWithBreaks}</h1>
          ) : (
            <h2 className={headingClasses}>{headingWithBreaks}</h2>
          ))}

        {enableHeadingDivider && (
          <div className="background-color mt-4 mb-3 h-[0.0625rem] w-[4.5rem] lg:-mb-2" />
        )}

        {subheading && (
          <h2
            className={`text-lg ${
              textStyle === 'large'
                ? 'lg:text-title-h2'
                : 'tracking-[0.0625rem] lg:tracking-[0.125rem]'
            } mt-1 md:mt-6`}
          >
            {subheading}
          </h2>
        )}

        {button?.buttons?.length > 0 && (
          <div
            className={`${
              subheading ? 'mt-3 md:mt-8' : 'mt-6 md:mt-[3.5rem]'
            } relative z-[2] flex flex-col justify-center gap-3 xs:flex-row md:gap-36`}
          >
            {button?.buttons?.slice(0, 2).map(({ link, style }, index) => {
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link
                  aria-label={link?.text}
                  className={`${style} ${
                    style !== 'text-underline' ? 'md:w-[15.9375rem]' : ''
                  }`}
                  href={link?.url}
                  key={index}
                  newTab={link?.newTab}
                >
                  {link?.text}
                </Link>
              );
            })}
          </div>
        )}
      </div>

      {button?.clickableSlide && (
        <Link
          aria-label={button.buttons?.[0]?.link?.text}
          className="pointer-events-auto absolute inset-0 z-[1] h-full w-full"
          href={button.buttons?.[0]?.link?.url}
        />
      )}
    </div>
  );
}

HeroContent.displayName = 'HeroContent';

import { useCallback, useEffect, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { getPredictiveSearchData } from './queries';

export function usePredictiveSearch({ term, mounted = true }) {
  const settings = useSettings();

  const [allCollections, setAllCollections] = useState(null);
  const [autocompleteResults, setAutocompleteResults] = useState([]);
  const [collectionResults, setCollectionResults] = useState(null);

  const { collectionsEnabled } = { ...settings?.search?.results } || {
    collectionsEnabled: true,
  };
  const { enabled: autocompleteEnabled } = {
    ...settings?.search,
  } || { enabled: true };

  const setCollectionsOnMount = useCallback(async () => {
    try {
      if (allCollections) return;
      const response = await fetch('/json/collections-list.json');
      const list = await response.json();
      const listObj = list?.reduce((acc, col) => {
        acc[col.handle] = col;
        return acc;
      }, {});
      setAllCollections(listObj);
    } catch (error) {
      console.error(error.message);
    }
  }, [allCollections]);

  const searchPredictiveResultsOnChange = useCallback(async () => {
    if (!term) {
      setAutocompleteResults([]);
      setCollectionResults([]);
      return;
    }

    const { collections, queries: autoCompleteTerms } =
      await getPredictiveSearchData({ term });

    if (autocompleteEnabled) {
      setAutocompleteResults(autoCompleteTerms);
    }
    if (collectionsEnabled) {
      const validCollections = collections?.map(
        ({ handle }) => allCollections[handle]
      );
      setCollectionResults(validCollections);
    }
  }, [autocompleteEnabled, collectionsEnabled, term]);

  useEffect(() => {
    if (!mounted) return;
    setCollectionsOnMount();
  }, [mounted]);

  useEffect(() => {
    if (autocompleteEnabled || collectionsEnabled)
      searchPredictiveResultsOnChange();
  }, [autocompleteEnabled, collectionsEnabled, mounted, term]);

  return { autocompleteResults, collectionResults };
}

import { COLORS } from './common';

export default {
  label: 'Cart',
  name: 'cart',
  component: 'group',
  description: 'Gift Note, Gift Wrap, Free shipping meter, empty cart, totals',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
      defaultValue: 'My Cart',
    },
    {
      label: 'Empty Cart',
      name: 'emptyCart',
      component: 'group',
      description: 'Message, links',
      fields: [
        {
          label: 'Empty Cart Message',
          name: 'message',
          component: 'text',
          defaultValue: 'Your cart is empty',
        },
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
      ],
      defaultValue: {
        link: { url: '/collections/all', text: 'Shop All' },
      },
    },
    {
      label: 'Gift Note',
      name: 'giftNote',
      component: 'group',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'Enabled',
            false: 'Disabled',
          },
        },
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Text',
          name: 'text',
          component: 'text',
        },
      ],
      defaultValue: {
        enabled: false,
        heading: 'Gifting? Add a Gift Note!',
        text: 'Your message will be printed on the gift receipt, and prices will be hidden. Please do not use any special characters or emojis!',
      },
    },
    {
      label: 'Gift Wrap',
      name: 'giftWrap',
      component: 'group',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'Enabled',
            false: 'Disabled',
          },
        },
        {
          label: 'Product',
          name: 'product',
          component: 'productSearch',
        },
      ],
      defaultValue: {
        enabled: false,
      },
    },
    {
      label: 'Gift With Purchase',
      name: 'gwp',
      component: 'group',
      fields: [
        {
          label: 'Buy X Get Y Settings',
          name: 'buyXgetY',
          component: 'group',
          description:
            'Set requirements to either product types or required products',
          fields: [
            {
              label: 'Enabled',
              name: 'enabled',
              component: 'toggle',
              toggleLabels: {
                true: 'Enabled',
                false: 'Disabled',
              },
            },
            {
              label: 'Promo Type',
              name: 'type',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              options: [
                { label: 'Required Types', value: 'required-types' },
                { label: 'Required Products', value: 'required-products' },
              ],
              defaultValue: 'required-types',
            },
            {
              label: 'Locked Message',
              name: 'lockedMessage',
              component: 'text',
            },
            {
              name: 'requiredProductTypes',
              label: 'Required Product Type(s)',
              description:
                'Each item is a required type. Product Types are based on {filter-type::} product tags. If you want Hybrid Shorts and Chino Shorts, just add "Shorts".',
              component: 'list',
              field: {
                component: 'text',
              },
            },
            {
              name: 'requiredProducts',
              label: 'Required Product(s)',
              component: 'group-list',
              itemProps: {
                label: '{{item.product.handle}}' || 'Required product',
              },
              validate: {
                maxItems: 3,
              },
              fields: [
                {
                  label: 'Product',
                  name: 'product',
                  component: 'productSearch',
                },
              ],
            },
            {
              name: 'products',
              label: 'Gift With Purchase Product',
              component: 'group-list',
              itemProps: {
                label: '{{item.product.handle}}' || 'GWP product',
              },
              validate: {
                maxItems: 1,
              },
              fields: [
                {
                  label: 'Product',
                  name: 'product',
                  component: 'productSearch',
                },
                {
                  label: 'Text',
                  name: 'text',
                  component: 'text',
                },
                {
                  label: 'Image',
                  name: 'image',
                  description: 'Optional',
                  component: 'image',
                },
                {
                  name: 'additionalProducts',
                  label: 'Additional Product(s)',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.product.handle}}' || 'Required product',
                  },
                  fields: [
                    {
                      label: 'Product',
                      name: 'product',
                      component: 'productSearch',
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Spend X Get Y Settings',
          name: 'spendXgetY',
          component: 'group',
          fields: [
            {
              label: 'Enabled',
              name: 'enabled',
              component: 'toggle',
              toggleLabels: {
                true: 'Enabled',
                false: 'Disabled',
              },
            },
            {
              name: 'products',
              label: 'Gift With Purchase Product',
              component: 'group-list',
              itemProps: {
                label: '{{item.product.handle}}' || 'GWP product',
              },
              validate: {
                maxItems: 1,
              },
              fields: [
                {
                  label: 'Product',
                  name: 'product',
                  component: 'productSearch',
                },
                {
                  label: 'Text',
                  name: 'text',
                  component: 'text',
                },
                {
                  label: 'Image',
                  name: 'image',
                  description: 'Optional',
                  component: 'image',
                },
                {
                  name: 'additionalProducts',
                  label: 'Additional Product(s)',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.product.handle}}' || 'Required product',
                  },
                  fields: [
                    {
                      label: 'Product',
                      name: 'product',
                      component: 'productSearch',
                    },
                  ],
                },
              ],
            },
            {
              label: 'Minimum Cart Spend To Qualify For Gift (USD)',
              name: 'minCartSpend',
              component: 'number',
              description:
                'IMPORTANT: Dollar amount must be equal to the spend amount used in the published Shopify cart script.',
            },
          ],
        },
      ],
      defaultValue: {
        enabled: false,
      },
    },
    {
      label: 'Free Shipping Meter',
      name: 'freeShipping',
      component: 'group',
      description: 'Enable, minimum cart spend, messages',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Minimum Cart Spend',
          name: 'minCartSpend',
          component: 'number',
          description: 'Minimum cart spend to qualify for free shipping',
        },
        {
          label: 'Progress Message',
          name: 'progressMessage',
          component: 'text',
          description:
            'Message when cart has not yet reached minimum spend. Use {{amount}} to display the remaining amount',
        },
        {
          label: 'Qualified Message',
          name: 'qualifiedMessage',
          component: 'text',
          description: 'Message when cart has qualified',
        },
        {
          label: 'Progress Bar Color',
          name: 'progressBarColor',
          component: 'select',
          options: COLORS,
        },
      ],
      defaultValue: {
        enabled: false,
        minCartSpend: 100,
        progressMessage: `You're only {{amount}} away from free shipping!`,
        qualifiedMessage: `You've qualified for free shipping!`,
        progressBarColor: 'var(--primary)',
      },
    },
    {
      label: 'Totals',
      name: 'totals',
      component: 'group',
      description: 'Subtotal text, subtext, checkout text',
      fields: [
        {
          label: 'Subtotal Text',
          name: 'subtotalText',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'text',
        },
        {
          label: 'Checkout Text',
          name: 'checkoutText',
          component: 'text',
        },
      ],
      defaultValue: {
        subtotalText: 'Subtotal',
        subtext: '',
        checkoutText: 'Checkout',
      },
    },
  ],
};

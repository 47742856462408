import { COLORS } from './common';

export default {
  label: 'Product',
  name: 'product',
  component: 'group',
  description: 'Add to cart, back in stock, badges, reviews',
  fields: [
    {
      label: 'Add To Cart',
      name: 'addToCart',
      component: 'group',
      description: 'Add to cart, sold out, presale text, subtext',
      fields: [
        {
          label: 'Add To Cart Text',
          name: 'addToCartText',
          component: 'text',
        },
        {
          label: 'Sold Out Text',
          name: 'soldOutText',
          component: 'text',
        },
        {
          label: 'Preorder Text',
          name: 'preorderText',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'text',
          description: 'Text below the Add To Cart button',
        },
      ],
      defaultValue: {
        addToCartText: 'Add To Cart',
        soldOutText: 'Sold Out',
        preorderText: 'Preorder',
        subtext: '',
      },
    },
    {
      label: 'Back In Stock',
      name: 'backInStock',
      component: 'group',
      description: 'Enable notifications, notify me text, modal texts',
      fields: [
        {
          label: 'Enable Notifications',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Modal Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Modal Subtext',
          name: 'subtext',
          component: 'text',
        },
        {
          label: 'Modal Heading - Success',
          name: 'headingSuccess',
          component: 'text',
        },
        {
          label: 'Modal Text - Success',
          name: 'subtextSuccess',
          component: 'text',
        },
        {
          label: 'Notify Me Text',
          name: 'notifyMeText',
          component: 'text',
        },
      ],
      defaultValue: {
        enabled: false,
        notifyMeText: 'Notify Me When Available',
        heading: 'Notify Me When Available',
        subtext: `Enter your email below and we'll notify you when this product is back in stock.`,
        submitText: 'Submit',
      },
    },
    {
      label: 'Badges',
      name: 'badges',
      component: 'group',
      description: 'Badge colors',
      fields: [
        {
          label: 'Badge Colors',
          name: 'badgeColors',
          component: 'group-list',
          description:
            'Note: product badges are set up via Shopify tags using the format "badge::Some Value"',
          itemProps: {
            label: '{{item.tag}}',
          },
          fields: [
            {
              label: 'Tag Value',
              name: 'tag',
              component: 'text',
              description:
                'Letter casing must be same as tag value in Shopify, e.g. "New", "Sale"',
            },
            {
              label: 'Background Color',
              name: 'bgColor',
              component: 'select',
              options: COLORS,
            },
            {
              label: 'Text Color',
              name: 'textColor',
              component: 'select',
              options: COLORS,
            },
          ],
          defaultItem: {
            bgColor: 'var(--black)',
            textColor: 'var(--white)',
          },
          defaultValue: [
            {
              tag: 'Best Seller',
              bgColor: 'var(--black)',
              textColor: 'var(--white)',
            },
            {
              tag: 'New',
              bgColor: 'var(--secondary)',
              textColor: 'var(--white)',
            },
            {
              tag: 'Sale',
              bgColor: 'var(--primary)',
              textColor: 'var(--white)',
            },
          ],
        },
      ],
    },
    {
      label: 'Reviews',
      name: 'reviews',
      component: 'group',
      description: 'Star rating',
      fields: [
        {
          label: 'Enable Star Rating',
          name: 'enabledStarRating',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabledStarRating: true,
      },
    },
    {
      label: 'Size Guide',
      name: 'sizeGuide',
      component: 'group',
      description: 'Size guide settings',
      fields: [
        {
          label: 'Hide Size Guide',
          name: 'hideProductTypes',
          component: 'list',
          description:
            'Add corresponding shopify product types to hide size guide on pdp.',
          field: {
            component: 'text',
          },
        },
        {
          label: 'Men',
          name: 'men',
          component: 'group',
          description: "Men's size guide settings",
          fields: [
            {
              label: 'Size Table Rows (Inches)',
              name: 'rows',
              component: 'group-list',
              description: 'Add rows to size guide',
              itemProps: {
                label: '{{item.values[0]}}',
              },
              fields: [
                {
                  label: 'Values',
                  name: 'values',
                  component: 'list',
                  description: 'Add values to column',
                  field: {
                    component: 'text',
                  },
                },
              ],
              defaultItem: {
                values: ['xs', '0 - 2', '32 - 34', '29 - 30', '29 - 30'],
              },
              defaultValue: [
                {
                  values: [' ', 'US Numberic', 'Chest', 'Waist', 'Hips'],
                },
                {
                  values: ['xs', '0 - 2', '32 - 34', '29 - 30', '29 - 30'],
                },
                {
                  values: ['s', '4 - 6', '36 - 39', '31 - 32', '31 - 32'],
                },
                {
                  values: ['m', '8 - 10', '40 - 42', '33 - 34', '33 - 34'],
                },
                {
                  values: ['l', '12 - 14', '43 - 45', '35 - 37', '35 - 37'],
                },
                {
                  values: ['xl', '16 - 18', '45 - 47', '39 - 42', '39 - 42'],
                },
                {
                  values: ['xxl', '18 - 20', '48 - 50', '44 - 46', '44 - 46'],
                },
                {
                  values: ['xxxl', '22 - 24', '50 - 52', '46 - 48', '46 - 48'],
                },
              ],
            },
            {
              label: 'Size Table Rows (Centimeters)',
              name: 'rowsCm',
              component: 'group-list',
              description: 'Add rows to size guide',
              itemProps: {
                label: '{{item.values[0]}}',
              },
              fields: [
                {
                  label: 'Values',
                  name: 'values',
                  component: 'list',
                  description: 'Add values to column',
                  field: {
                    component: 'text',
                  },
                },
              ],
              defaultItem: {
                values: ['xs', '0 - 2', '81 - 86', '72 - 76', '72 - 76'],
              },
              defaultValue: [
                {
                  values: [' ', 'US Numberic', 'Chest', 'Waist', 'Hips'],
                },
                {
                  values: ['xs', '0 - 2', '81 - 86', '72 - 76', '72 - 76'],
                },
                {
                  values: ['s', '4 - 6', '91 - 97', '77 - 81', '77 - 81'],
                },
                {
                  values: ['m', '8 - 10', '102 - 107', '82 - 86', '82 - 86'],
                },
                {
                  values: ['l', '12 - 14', '108 - 114', '91 - 95', '91 - 95'],
                },
                {
                  values: [
                    'xl',
                    '16 - 18',
                    '116 - 122',
                    '97 - 107',
                    '97 - 107',
                  ],
                },
                {
                  values: [
                    'xxl',
                    '18 - 20',
                    '123 - 127',
                    '112 - 117',
                    '112 - 117',
                  ],
                },
                {
                  values: [
                    'xxxl',
                    '22 - 24',
                    '127 - 129',
                    '117 - 122',
                    '117 - 122',
                  ],
                },
              ],
            },
          ],
        },
        {
          label: 'Women',
          name: 'women',
          component: 'group',
          description: "Women's size guide settings",
          fields: [
            {
              label: 'Size Table Rows (Inches)',
              name: 'rows',
              component: 'group-list',
              description: 'Add rows to size guide',
              itemProps: {
                label: '{{item.values[0]}}',
              },
              fields: [
                {
                  label: 'Values',
                  name: 'values',
                  component: 'list',
                  description: 'Add values to column',
                  field: {
                    component: 'text',
                  },
                },
              ],
              defaultItem: {
                values: ['xs', '0 - 2', '32 - 34', '24 - 25', '34 - 35'],
              },
              defaultValue: [
                {
                  values: [' ', 'US Numberic', 'Chest', 'Waist', 'Hips'],
                },
                {
                  values: ['xs', '0 - 2', '32 - 34', '24 - 25', '34 - 35'],
                },
                {
                  values: ['s', '4 - 6', '34 - 35', '26 - 27', '36 - 37'],
                },
                {
                  values: ['m', '8 - 10', '36 - 37', '28 - 29', '38 - 39'],
                },
                {
                  values: ['l', '12 - 14', '38 - 39', '30 - 31', '40 - 41'],
                },
                {
                  values: ['xl', '16 - 18', '40', '32', '42'],
                },
                {
                  values: ['xxl', '18 - 20', '41', '33', '43'],
                },
              ],
            },
            {
              label: 'Size Table Rows (Centimeters)',
              name: 'rowsCm',
              component: 'group-list',
              description: 'Add rows to size guide',
              itemProps: {
                label: '{{item.values[0]}}',
              },
              fields: [
                {
                  label: 'Values',
                  name: 'values',
                  component: 'list',
                  description: 'Add values to column',
                  field: {
                    component: 'text',
                  },
                },
              ],
              defaultItem: {
                values: ['xs', '0 - 2', '81 - 87', '61 - 62', '86 - 87'],
              },
              defaultValue: [
                {
                  values: [' ', 'US Numberic', 'Chest', 'Waist', 'Hips'],
                },
                {
                  values: ['xs', '0 - 2', '81 - 87', '61 - 62', '86 - 87'],
                },
                {
                  values: ['s', '4 - 6', '86 - 87', '66 - 70', '91 - 92'],
                },
                {
                  values: ['m', '8 - 10', '91 - 96', '71 - 75', '96 - 97'],
                },
                {
                  values: ['l', '12 - 14', '96 - 97', '76 - 77', '102 - 103'],
                },
                {
                  values: ['xl', '16 - 18', '102', '81', '104'],
                },
                {
                  values: ['xxl', '18 - 20', '103', '82', '105'],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

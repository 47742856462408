import { Accordions } from './Accordions';
import { BlogCategories } from './BlogCategories';
import { BlogGrid } from './BlogGrid';
import { CollectionHero } from './CollectionHero';
import { CollectionPairs } from './CollectionPairs';
import { FormBuilder } from './FormBuilder';
import { HalfHero } from './HalfHero';
import { Hero } from './Hero';
import { HtmlBlock } from './HtmlBlock';
import { IconRow } from './IconRow';
import { Image } from './Image';
import { ImageTiles } from './ImageTiles';
import { InstagramFeed } from './InstagramFeed';
import { Markdown } from './Markdown';
import { PressSlider } from './PressSlider';
import { ProductDetailAccordions } from './ProductDetailAccordions';
import { ProductFeatures } from './ProductFeatures';
import { ProductReviews } from './ProductReviews';
import { ProductsGrid } from './ProductsGrid';
import { ProductsSlider } from './ProductsSlider';
import { Returns } from './Returns';
import { SearchResults } from './SearchResults';
import { SocialImagesGrid } from './SocialImagesGrid';
import { SplitBanner } from './SplitBanner';
import { StoreReviews } from './StoreReviews';
import { TestimonialSlider } from './TestimonialSlider';
import { TextBlock } from './TextBlock';
import { Wishlist } from './Wishlist';
import { Video } from './Video';

export default [
  Accordions,
  BlogCategories,
  BlogGrid,
  CollectionHero,
  CollectionPairs,
  FormBuilder,
  HalfHero,
  Hero,
  HtmlBlock,
  IconRow,
  Image,
  ImageTiles,
  InstagramFeed,
  Markdown,
  ProductFeatures,
  ProductReviews,
  PressSlider,
  ProductDetailAccordions,
  ProductsGrid,
  ProductsSlider,
  Returns,
  SearchResults,
  SocialImagesGrid,
  SplitBanner,
  StoreReviews,
  TestimonialSlider,
  TextBlock,
  Wishlist,
  Video,
];

import { useCallback, useState } from 'react';

export function useMenuDrawer() {
  const [menuDrawerContent, setMenuDrawerContent] = useState(null);

  const clearUnHoverTimer = useCallback(() => {
    if (window.unHover) {
      clearTimeout(window.unHover);
      window.unHover = null;
    }
  }, []);

  const handleMenuDrawerClose = useCallback(() => {
    setMenuDrawerContent(null);
  }, []);

  const handleMenuHoverIn = useCallback((content) => {
    clearUnHoverTimer();
    setMenuDrawerContent(content);
  }, []);

  const handleMenuHoverOut = useCallback(() => {
    clearUnHoverTimer();
    window.unHover = setTimeout(() => {
      setMenuDrawerContent(null);
      clearUnHoverTimer();
    }, 100);
  }, []);

  return {
    state: { menuDrawerContent },
    actions: {
      handleMenuDrawerClose,
      handleMenuHoverIn,
      handleMenuHoverOut,
    },
  };
}

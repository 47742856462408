import React from 'react';

import { useGlobalContext } from '@contexts';
import { GWPSelectorModal } from './GWPSelectorModal';

export function GWPSelector({ classes, disabled, products }) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  if (!products?.length) return null;
  return (
    <button
      aria-label="Open GWP"
      className={`${classes}`}
      disabled={disabled}
      onClick={() =>
        openModal(<GWPSelectorModal products={products} />, {
          className: '!max-w-[1000px]',
        })
      }
      type="button"
    >
      Select
    </button>
  );
}

GWPSelector.displayName = 'GWPSelector';

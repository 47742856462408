import { useMemo } from 'react';
import { useVariantPrices } from '@hooks';

export function ProductHeader({ product, selectedVariant }) {
  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  const title = product.title

  const newTitle = useMemo(() => {
    if (!title) return;
    const splitArr = title?.split(' ');

    const collectionIndex = splitArr?.findIndex((str) =>
      str?.includes('\u2122')
    );

    if (collectionIndex === -1) return;

    const collectionWithTm = splitArr?.[collectionIndex];

    const collectionName = collectionWithTm?.slice(
      0,
      collectionWithTm.length - 1
    );

    const trademark = `${collectionWithTm?.slice(
      collectionWithTm.length - 1,
      collectionWithTm.length
    )} `;

    const titleBegin = [
      ...splitArr?.slice(0, collectionIndex),
      collectionName,
    ].join(' ');

    const titleEnd = [
      ...splitArr?.slice(collectionIndex + 1, splitArr?.length),
    ].join(' ');

    return { titleBegin, trademark, titleEnd };
  }, [title]);

  return (
    <div className="flex items-center justify-between gap-2">
                {newTitle ? (
                  <h1 className="text-title-h3 mr-2 flex-1 tracking-normal">
                    {newTitle?.titleBegin}
                    <span className="align-super text-base leading-6 lg:leading-7">
                      {newTitle?.trademark}
                    </span>
                    {newTitle?.titleEnd}
                  </h1>
                ) : (
                  <h1 className="text-title-h3 flex-1 tracking-normal">{title}</h1>
                )}
      

      <div className="text-title-h4 flex min-h-[1.5rem] gap-2">
        {compareAtPrice && (
          <p className="text-title-h4 font-normal tracking-normal text-mediumGray line-through max-md:text-xl">
            {compareAtPrice}
          </p>
        )}
        <p className="text-title-h4 font-normal tracking-normal max-md:text-xl">
          {price}
        </p>
      </div>
    </div>
  );
}

ProductHeader.displayName = 'ProductHeader';

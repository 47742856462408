import React, {
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from 'react';
import _uniqBy from 'lodash.uniqby';

import { useCustomer } from '@backpackjs/storefront';
import { useWishlist } from '@hooks';

const Context = createContext();

const wishlistState = {
  anonymousWishlist: [],
  wishlist: [],
  wishlistEmail: null,
  wishlistId: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOAD_WISHLIST':
      return {
        ...state,
        wishlist: action.payload.items,
        wishlistEmail: action.payload.email,
        wishlistId: action.payload.wishlistId,
      };
    case 'UPDATE_ANONYMOUS_WISHLIST':
      return {
        ...state,
        anonymousWishlist: action.payload.items,
      };
    case 'UPDATE_WISHLIST':
      return {
        ...state,
        wishlist: action.payload.items,
      };
    case 'UPDATE_WISHLIST_EMAIL':
      return {
        ...state,
        wishlistEmail: action.payload.email,
      };
    case 'UPDATE_WISHLIST_ID':
      return {
        ...state,
        wishlistId: action.payload.id,
      };
    default:
      throw new Error(`Invalid Context action of type: ${action.type}`);
  }
};

const actions = (dispatch) => ({
  loadWishlist: ({ email, items, wishlistId }) => {
    window.localStorage.setItem('WISHLIST_EMAIL', JSON.stringify(email));
    window.localStorage.setItem('WISHLIST', JSON.stringify(items));
    window.localStorage.setItem('WISHLIST_ID', JSON.stringify(wishlistId));
    dispatch({
      type: 'LOAD_WISHLIST',
      payload: { email, items, wishlistId },
    });
  },
  updateAnonymousWishlist: (items) => {
    window.localStorage.setItem('WISHLIST_ANONYMOUS', JSON.stringify(items));
    dispatch({ type: 'UPDATE_ANONYMOUS_WISHLIST', payload: { items } });
  },
  updateWishlist: (items) => {
    window.localStorage.setItem('WISHLIST', JSON.stringify(items));
    dispatch({ type: 'UPDATE_WISHLIST', payload: { items } });
  },
  updateWishlistEmail: (email) => {
    window.localStorage.setItem('WISHLIST_EMAIL', JSON.stringify(email));
    dispatch({ type: 'UPDATE_WISHLIST_EMAIL', payload: { email } });
  },
  updateWishlistId: (id) => {
    window.localStorage.setItem('WISHLIST_ID', JSON.stringify(id));
    dispatch({ type: 'UPDATE_WISHLIST_ID', payload: { id } });
  },
});

export function WishlistContextProvider({ children }) {
  const { fetchUserWishlist, updateWishlist: swymUpdateWishlist } =
    useWishlist();
  const [state, dispatch] = useReducer(reducer, { ...wishlistState });

  const value = useMemo(() => ({ state, actions: actions(dispatch) }), [state]);

  const {
    loadWishlist,
    updateAnonymousWishlist,
    updateWishlist,
    updateWishlistEmail,
    updateWishlistId,
  } = {
    ...value?.actions,
  };

  const customer = useCustomer();

  const getWishlistedProducts = useCallback(async (_wishlistEmail) => {
    let data;

    if (_wishlistEmail) {
      data = await fetchUserWishlist({
        email: _wishlistEmail,
      });
    } else {
      data = { items: [] };
    }

    if (Array.isArray(data?.items)) {
      loadWishlist({
        email: _wishlistEmail,
        items: data.items,
        wishlistId: data.lid,
      });

      const parsedAnonymousWishlist = JSON.parse(
        localStorage.getItem('WISHLIST_ANONYMOUS')
      );

      // // add items from anonymous wishlist to swym wishlist
      if (parsedAnonymousWishlist?.length && customer?.email) {
        const uniqueItems = _uniqBy(
          [...data.items, ...parsedAnonymousWishlist],
          'epi'
        );

        updateWishlist(uniqueItems);

        await swymUpdateWishlist({
          params: {
            lid: data?.lid,
            email: _wishlistEmail,
            add: uniqueItems,
            remove: [],
          },
        });

        updateAnonymousWishlist([]);
      }
    } else {
      loadWishlist({
        email: null,
        items: [],
        wishlistId: null,
      });
    }
  }, []);

  useEffect(() => {
    // return until customer hook returns null or has fetched customer
    if (customer === undefined) return;

    if (customer === null) {
      updateWishlistEmail(null);
      updateWishlistId(null);
      return;
    }

    const _wishlistEmail = customer?.email || null;
    getWishlistedProducts(_wishlistEmail);
  }, [customer]);

  useEffect(() => {
    updateAnonymousWishlist(
      JSON.parse(window.localStorage.getItem('WISHLIST_ANONYMOUS')) || []
    );
    updateWishlist(JSON.parse(window.localStorage.getItem('WISHLIST')) || []);
    updateWishlistId(
      JSON.parse(window.localStorage.getItem('WISHLIST_ID')) || null
    );
    updateWishlistEmail(
      JSON.parse(window.localStorage.getItem('WISHLIST_EMAIL')) || null
    );
  }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export const useWishlistContext = () => useContext(Context);

import {
  useCartCount,
  useCartItems,
  useSettings,
} from '@backpackjs/storefront';

import { useGlobalContext } from '@contexts';
import { CartEmpty } from './CartEmpty';
import { CartGiftNote } from './CartGiftNote';
import { CartGWP } from './CartGWP';
import { CartHelp } from './CartHelp';
import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';

export function CartPage({
  enabledGiftNote,
  giftWrapLineItemId,
  giftWrapProduct,
  gwpBuyXGetY,
  gwpSpendXGetY,
  toggleGiftWrap,
}) {
  const settings = useSettings();
  const cartCount = useCartCount();
  const cartItems = useCartItems();
  const heading = settings?.cart?.heading;
  const hasCartItems = cartItems?.length > 0;

  const {
    actions: { closeCart },
  } = useGlobalContext();

  return (
    <div className="md-px-contained py-contained">
      <div className="mx-auto max-w-[80rem] pt-3">
        <h1 className="px-5 text-lg font-semibold  lg:py-5 lg:text-2xl">
          {heading || 'My Cart'} ({cartCount || 0})
        </h1>

        {enabledGiftNote && (
          <div className="lg:hidden">
            <CartGiftNote isCartPage closeCart={closeCart} />
          </div>
        )}

        <div
          className={`mb-2 grid gap-x-4 lg:grid-flow-col-dense lg:gap-y-4 ${
            hasCartItems
              ? 'grid-cols-1 lg:grid-cols-[auto_375px]'
              : 'grid-cols-1'
          }`}
        >
          <div className="px-5 max-lg:mt-2 lg:row-span-2 lg:max-w-[750px]">
            <ul
              className={`relative border-y border-border ${
                hasCartItems ? '' : 'min-h-[20rem] py-12 lg:min-h-[30rem]'
              }`}
            >
              {hasCartItems ? (
                cartItems.map((item) => {
                  const isGiftWrap =
                    giftWrapProduct?.handle === item?.variant.product?.handle;

                  const groupedProducts = [
                    ...((gwpSpendXGetY?.gwpProduct?.groupedProducts &&
                      gwpSpendXGetY?.gwpProduct?.groupedProducts) ||
                      []),
                    ...((gwpBuyXGetY?.gwpProduct?.groupedProducts &&
                      gwpBuyXGetY?.gwpProduct?.groupedProducts) ||
                      []),
                  ];

                  const isGWP = groupedProducts?.some(
                    (product) => product === item?.variant?.product?.handle
                  );

                  return (
                    <li
                      key={item.id}
                      className="border-b border-b-border last:border-none"
                    >
                      <CartItem
                        closeCart={closeCart}
                        isGiftWrap={isGiftWrap}
                        isGWP={isGWP}
                        item={item}
                      />
                    </li>
                  );
                })
              ) : (
                <CartEmpty />
              )}
            </ul>

            <CartGWP
              closeCart={closeCart}
              gwpBuyXGetY={gwpBuyXGetY}
              gwpSpendXGetY={gwpSpendXGetY}
              isCartPage
            />
          </div>

          {hasCartItems && (
            <div className="[&>div]:max-lg:border-t-0  [&>div]:lg:border [&>div]:lg:border-border">
              <CartTotals
                giftWrapLineItemId={giftWrapLineItemId}
                giftWrapProduct={giftWrapProduct}
                toggleGiftWrap={toggleGiftWrap}
                isCartPage
              />

              {enabledGiftNote && (
                <div className="mt-5 hidden p-5 lg:block">
                  <CartGiftNote isCartPage closeCart={closeCart} />
                </div>
              )}

              <div className="mt-5 hidden p-5 lg:block">
                <CartHelp />
              </div>
            </div>
          )}
        </div>

        <div className="px-5 lg:hidden">
          <CartHelp />
        </div>
      </div>
    </div>
  );
}

CartPage.displayName = 'CartPage';

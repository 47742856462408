import Head from 'next/head';
import Script from 'next/script';

export function StorefrontHead() {
  return (
    <>
      <Script
        type="text/javascript"
        key="gtm-dataLayer-js"
        id="gtm-dataLayer-js"
        dangerouslySetInnerHTML={{
          __html:
            "\n              window.dataLayer = [];\n              console.log('GTM dataLayer instantiated.');\n            ",
        }}
      />
      <Script
        type="text/javascript"
        key="gtm-script-js"
        id="gtm-script-js"
        dangerouslySetInnerHTML={{
          __html:
            "\n              console.log('GTM script initialized.');\n              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n              })(window,document,'script','dataLayer','GTM-PQ38MC7');\n            ",
        }}
      />

      <Script
        id="facebook-pixel"
        dangerouslySetInnerHTML={{
          __html: `
            !function(b,e,f,g,a,c,d){b.fbq||(a=b.fbq=function(){a.callMethod?a.callMethod.apply(a,arguments):a.queue.push(arguments)},b._fbq||(b._fbq=a),a.push=a,a.loaded=!0,a.version="2.0",a.queue=[],c=e.createElement(f),c.async=!0,c.src=g,d=e.getElementsByTagName(f)[0],d.parentNode.insertBefore(c,d))}(window,document,"script","https://connect.facebook.net/en_US/fbevents.js");fbq("init","267103295180428");fbq("track","PageView");
          `,
        }}
      />

      <Script
        type="text/javascript"
        id="yotpo-script"
        strategy="afterInteractive"
        src={`https://staticw2.yotpo.com/${process.env.NEXT_PUBLIC_YOTPO_KEY}/widget.js`}
      />

      <Script
        type="text/javascript"
        id="shareasale-script"
        src={`https://www.dwin1.com/61469.js`}
      />
      
      <Head>
        <title key="page-title">7Diamonds</title>
        <meta key="page:fb:title" property="og:title" content="7Diamonds" />
        {/* fonts */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />

        {/* favicon */}
        <link rel="shortcut icon" href="/icons/favicon.svg" />

        {/* additional open graph */}
        <meta
          property="og:site_name"
          content={process.env.NEXT_PUBLIC_SITE_TITLE}
        />

        <meta
          name="google-site-verification"
          content="8KuMEV7yYQUP6KqZvLHnrdVKR_Byc6yaznDMVB6enTY"
        />

        {/* pwa (progressive web app) */}
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/icons/icon-32x32.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="192x192"
          href="/icons/icon-384x384.png"
        />
        <meta
          name="application-name"
          content={process.env.NEXT_PUBLIC_SITE_TITLE}
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta
          name="apple-mobile-web-app-title"
          content={process.env.NEXT_PUBLIC_SITE_TITLE}
        />
        <meta name="format-detection" content="telephone=no" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="msapplication-tap-highlight" content="no" />
        <meta name="theme-color" content="#000000" />
      </Head>
    </>
  );
}

StorefrontHead.displayName = 'StorefrontHead';

import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export function ProductVideo({ media, handleMediaClick }) {
  const videoRef = useRef(null);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  const { sources, image } = media;
  const videoSources = sources?.filter(
    ({ mimeType }) => mimeType === 'video/mp4'
  );

  useEffect(() => {
    if (inView) {
      videoRef?.current?.load();
      videoRef?.current?.play();
    } else {
      videoRef?.current?.pause();
    }
  }, [inView, media]);

  return (
    <div
      ref={ref}
      onClick={handleMediaClick}
      className="cursor-zoom-in"
      style={{ aspectRatio: 'var(--product-image-aspect-ratio)' }}
    >
      <video
        ref={videoRef}
        muted
        playsInline
        loop
        controls={false}
        poster={image?.src}
        className="h-full w-full object-cover"
      >
        {inView && videoSources?.length
          ? videoSources.map((source) => {
              return (
                <source
                  key={source.url}
                  src={source.url}
                  type={source.mimeType}
                />
              );
            })
          : null}
      </video>
    </div>
  );
}

ProductVideo.displayName = 'ProductVideo';

import { Link, Markdown } from '@snippets';
import { Schema } from './TextBlock.schema';

export function TextBlock({ cms }) {
  const {
    buttons,
    buttonsPosition,
    heading,
    section,
    subtext,
    subheading,
    headingPosition,
    subheadingPosition,
    headingStyle,
    subheadingStyle,
    subtextAlignment,
  } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div
      className="px-contained py-contained "
      style={{ backgroundColor: section?.bgColor, color: section?.textColor }}
    >
      <div className={`mx-auto flex flex-col gap-4 md:gap-6 ${maxWidthClass} `}>
        {heading &&
          (section?.aboveTheFold ? (
            <h1
              className={`text-title-h2  ${headingPosition}
            
            ${headingStyle}
            max-w-[46rem] `}
            >
              {heading}
            </h1>
          ) : (
            <h2
              className={`text-title-h2  ${headingPosition} 
            ${headingStyle}
            max-w-[46rem] `}
            >
              {heading}
            </h2>
          ))}
        {subheading && (
          <h3
            className={` ${subheadingPosition} ${subheadingStyle} max-w-[46rem]`}
          >
            {subheading}
          </h3>
        )}

        {subtext && (
          <div className={` ${subtextAlignment} mx-auto max-w-[46rem] [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base`}>
            <Markdown>{subtext}</Markdown>
          </div>
        )}

        {buttons?.length > 0 && (
          <div
            className={`mt-4 flex flex-col ${buttonsPosition} items-center gap-4 xs:flex-row`}
          >
            {buttons.slice(0, 2).map(({ link, style }, index) => {
              return (
                <Link
                  aria-label={link?.text}
                  className={style}
                  href={link?.url}
                  key={index}
                  newTab={link?.newTab}
                >
                  {link?.text}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

TextBlock.displayName = 'TextBlock';
TextBlock.Schema = Schema;

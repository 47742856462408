import { useEffect, useState } from 'react';

import { Image, Link, Markdown } from '@snippets';
import { Schema } from './CollectionHero.schema';

export function CollectionHero({ cms, firstProductItemImage }) {
  const { image, link, text } = cms;

  const { alt, imageDesktop, imageMobile } = { ...image };
  const { heading, description } = { ...text };

  const [imageHeight, setImageHeight] = useState(null);

  const setImageHeightHandler = () => {
    setImageHeight(firstProductItemImage?.current?.clientHeight);
  };

  useEffect(() => {
    setImageHeightHandler();
    window.addEventListener('resize', setImageHeightHandler);
    return () => {
      window.removeEventListener('resize', setImageHeightHandler);
    };
  }, []);

  return (
    <div className="group flex h-full flex-col justify-between">
      <div className="flex flex-col items-start">
        <Link
          aria-label={heading}
          className="relative mb-3 w-full"
          href={link?.url}
          tabIndex="-1"
          style={
            imageHeight
              ? {
                  height: imageHeight,
                }
              : {
                  aspectRatio: 'var(--collection-hero-ingrid-aspect-ratio)',
                }
          }
        >
          {imageDesktop?.src && (
            <Image
              alt={alt}
              fill
              priority
              sizes="(min-width: 768px) 33vw, 50vw"
              src={imageDesktop.src}
            />
          )}

          {imageMobile?.src && (
            <Image
              alt={alt}
              fill
              priority
              sizes="(min-width: 768px) 33vw, 50vw"
              src={imageMobile.src}
            />
          )}
        </Link>

        <div className="grid w-full gap-y-3">
          <Link aria-label={heading} href={link?.url}>
            <h6 className="text-title-h6">{heading}</h6>
          </Link>

          <Markdown>{description}</Markdown>

          {link?.text && (
            <div>
              <Link
                aria-label={link?.text}
                className="text-underline font-normal normal-case"
                href={link?.url}
              >
                {link?.text}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

CollectionHero.displayName = 'CollectionHero';
CollectionHero.Schema = Schema;

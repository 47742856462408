import { useState, useEffect} from 'react';

import { Svg } from '@snippets';

export function ProductDetailAccordion({ accordion }) {
  const { body, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <div className="border-b border-[var(--border)]">
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="flex h-11 w-full items-center justify-between py-3 md:h-fit md:py-4"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <h5 className="text-nav md:text-xl">{header}</h5>

        {isOpen ? (
          <Svg
            className="w-[0.875rem] text-current"
            src="/svgs/minus.svg#minus"
            title="Minus"
            viewBox="0 0 12 13"
          />
        ) : (
          <Svg
            className="w-[0.875rem] text-current"
            src="/svgs/plus.svg#plus"
            title="Plus"
            viewBox="0 0 12 13"
          />
        )}
      </button>

      <div
        className={`product-detail-accordions pb-6 ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
    </div>
  );
}

ProductDetailAccordion.displayName = 'ProductDetailAccordion';

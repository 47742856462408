import { useMemo, useEffect, useState } from 'react';
import { useCart, useSettings } from '@backpackjs/storefront';

export const useCartGWP = () => {
  const cart = useCart();
  const settings = useSettings();

  const [spendXSpendYGwpProduct, setSpendXSpendYGwpProduct] = useState(null);
  const [buyXSpendYGwpProduct, setBuyXSpendYGwpProduct] = useState(null);

  const { spendXgetY, buyXgetY } = {
    ...settings?.cart?.gwp,
  };

  const {
    enabled: spendXGetYEnabled,
    minCartSpend,
    products: spendXGetYGiftProducts,
  } = { ...spendXgetY };

  const {
    enabled: buyXGetYEnabled,
    lockedMessage,
    requiredProducts,
    requiredProductTypes,
    type,
    products: buyXGetYGiftProducts,
  } = { ...buyXgetY };

  const gwpBuyXGetY = useMemo(() => {
    if (!buyXGetYEnabled) return null;

    let isEligible = false;

    if (type === 'required-types') {
      const requiredTypesInCart = {};
      cart?.lines?.forEach((item) => {
        requiredProductTypes?.forEach((type) => {
          if (
            item?.variant?.product?.tags?.find(
              (tag) => tag.startsWith(`filter-type`) && tag.indexOf(type) > -1
            )
          ) {
            requiredTypesInCart[type] = 1;
          }
        });
      });
      isEligible =
        requiredTypesInCart &&
        Object.values(requiredTypesInCart).length >=
          requiredProductTypes?.length;
    }

    if (type === 'required-products') {
      const requiredProductsHandles = requiredProducts?.map(
        ({ product }) => product?.handle
      );

      isEligible =
        cart?.lines?.filter((item) => {
          return requiredProductsHandles?.some(
            (handle) => handle === item?.variant?.product?.handle
          );
        })?.length >= requiredProductsHandles?.length;
    }

    return {
      isEligible,
    };
  }, [
    cart?.updatedAt,
    requiredProducts,
    requiredProductTypes,
    buyXGetYEnabled,
    type,
  ]);

  const gwpSpendXGetY = useMemo(() => {
    if (!spendXGetYEnabled || !minCartSpend) return null;
    const subtotal = cart?.estimatedCost?.subtotalAmount?.amount;
    const difference = minCartSpend - subtotal;
    return {
      isEligible: difference <= 0,
      priceDifference: difference <= 0 ? 0 : difference,
    };
  }, [cart?.updatedAt, spendXGetYEnabled, minCartSpend]);

  useEffect(() => {
    const getGwpProduct = async (gwpProductData) => {
      if (!gwpProductData) return null;

      const fetchUrls = gwpProductData.reduce((_, product) => {
        if (!product?.product?.handle) return null;

        const allUrls =
          product?.additionalProducts?.map(
            ({ product }) => `/json/products/${product?.handle}.json`
          ) || [];
        const mainProduct = `/json/products/${product?.product?.handle}.json`;
        return {
          gwp: {
            products: [mainProduct],
            text: product?.text,
            image: product?.image,
          },
          additionalProducts: { products: allUrls },
        };
      }, {});

      if (!fetchUrls) return null;

      const gwpProductReponses = await Object.keys(fetchUrls).reduce(
        async (carry, key) => {
          const responses = await Promise.all(
            fetchUrls[key]?.products?.map((url) => fetch(url))
          );
          const acc = await carry;
          return {
            ...acc,
            [key]: {
              ...fetchUrls[key],
              products: responses,
            },
          };
        },
        Promise.resolve({})
      );

      const gifts = await Object.keys(gwpProductReponses).reduce(
        async (carry, key) => {
          const responses = await Promise.all(
            gwpProductReponses[key]?.products?.map((url) => url.json())
          );
          const acc = await carry;
          return {
            ...acc,
            [key]: {
              ...fetchUrls[key],
              products: responses,
            },
          };
        },
        Promise.resolve({})
      );

      const groupedProducts =
        (gifts &&
          Object.values(gifts)
            .reduce((carry, { products }) => {
              return [
                ...carry,
                ...(products?.map((product) => {
                  return product?.grouping?.products || product?.handle;
                }) || []),
              ];
            }, [])
            ?.flat()) ||
        null;

      if (gifts) {
        return {
          ...gifts,
          groupedProducts,
        };
      }

      return null;
    };

    getGwpProduct(spendXGetYGiftProducts).then((res) => {
      if (res) {
        setSpendXSpendYGwpProduct(res);
      }
    });

    getGwpProduct(buyXGetYGiftProducts).then((res) => {
      if (res) {
        setBuyXSpendYGwpProduct(res);
      }
    });
  }, [spendXGetYGiftProducts]);

  return {
    gwpSpendXGetY: {
      ...gwpSpendXGetY,
      isActive: gwpSpendXGetY !== null,
      gwpProduct: spendXSpendYGwpProduct,
    },
    gwpBuyXGetY: {
      ...gwpBuyXGetY,
      isActive: gwpBuyXGetY !== null,
      gwpProduct: buyXSpendYGwpProduct,
      lockedMessage,
    },
  };
};

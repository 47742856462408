import { useProductByHandle } from '@backpackjs/storefront';

import { useVariantPrices } from '@hooks';
import { Image } from '../Image';
import { Link } from '../Link';

export function SearchItem({ closeSearch, index, item }) {
  const { product } = useProductByHandle({ handle: item.handle });

  const { price, compareAtPrice } = useVariantPrices({
    variant: product?.variants?.[0],
  });

  const url = `/products/${item.handle}`;
  const type = product?.productType;
  const image = product?.images?.[0];

  return (
    <Link
      aria-label={`View ${product?.title}`}
      className="relative grid grid-cols-[75px_1fr] gap-4"
      href={url}
      onClick={() => {
        // commented out for potential future use for data layer 3.1
        // PubSub.publish('CLICK_SEARCH_ITEM', {
        //   ...product?.variants?.[0],
        //   image,
        //   index,
        // });
        closeSearch();
      }}
    >
      {image?.src && (
        <Image
          alt={product?.title}
          className="bg-offWhite"
          height="100"
          src={image.src}
          width="75"
        />
      )}

      <div className="flex flex-col gap-1.5">
        <h5 className="text-base font-medium">{product?.title}</h5>

        {type && <p className="text-sm font-medium text-mediumGray">{type}</p>}
        <div className="flex flex-wrap gap-x-1.5">
          {compareAtPrice && (
            <p className="text-sm text-mediumGray line-through">
              {compareAtPrice}
            </p>
          )}
          <p className="text-sm">{price}</p>
        </div>
      </div>
    </Link>
  );
}

SearchItem.displayName = 'SearchItem';

import { useInView } from 'react-intersection-observer';
import { Image, Video } from '@snippets';
import { HeroContent } from './HeroContent';

export function HeroSlide({
  aboveTheFold,
  cmsSectionId,
  contentPlacement,
  isActiveSlide,
  isFirstSlide,
  slide,
}) {
  const { image, video } = slide;
  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });
  const isVisible =
    (aboveTheFold && (isActiveSlide || isFirstSlide)) ||
    (!aboveTheFold && inView);

  return (
    <>
      <div
        className={`relative w-full ${
          contentPlacement === 'below-image' ? 'content-below-image' : 'h-full'
        }`}
        ref={ref}
      >
        <div className="h-full w-full overflow-hidden md:hidden">
          {video?.srcMobile && (
            <Video
              isVisible={isVisible}
              posterSrc={video.posterMobile?.src}
              videoSrc={video.srcMobile}
            />
          )}

          {image?.imageMobile?.src && !video?.srcMobile && (
            <Image
              alt={image.alt}
              className={`${image.positionMobile}`}
              fill
              priority={aboveTheFold && isFirstSlide}
              sizes="100vw"
              src={image.imageMobile.src}
            />
          )}
        </div>

        <div className="hidden h-full w-full overflow-hidden md:block">
          {video?.srcDesktop && (
            <Video
              isVisible={isVisible}
              posterSrc={video.posterDesktop?.src}
              videoSrc={video.srcDesktop}
            />
          )}

          {image?.imageDesktop?.src && !video?.srcDesktop && (
            <Image
              alt={image.alt}
              className={`${image.positionDesktop}`}
              fill
              priority={aboveTheFold && isFirstSlide}
              sizes="100vw"
              src={image.imageDesktop.src}
            />
          )}
        </div>

        {contentPlacement === 'inside-image' && (
          <HeroContent
            aboveTheFold={aboveTheFold}
            cmsSectionId={cmsSectionId}
            contentPlacement={contentPlacement}
            isFirstSlide={isFirstSlide}
            slide={slide}
          />
        )}
      </div>
      {contentPlacement === 'below-image' && (
        <HeroContent
          aboveTheFold={aboveTheFold}
          cmsSectionId={cmsSectionId}
          contentPlacement={contentPlacement}
          isFirstSlide={isFirstSlide}
          slide={slide}
        />
      )}
    </>
  );
}

HeroSlide.displayName = 'HeroSlide';

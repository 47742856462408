import { useMemo } from 'react';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import { WairFitAdvisor } from '@snippets/WairFitAdvisor';
import { ProductColorOptionValue } from './ProductColorOptionValue';
import { ProductOptionValue } from './ProductOptionValue';

export function ProductOption({
  isModal,
  name,
  groupingProductsMapByColor,
  groupingProductsMapByNewColor,
  groupingProductsMapByStandardColor,
  product,
  selectedOptions,
  selectedVariant,
  setSelectedOptions,
  values,
  hideDuplicate,
}) {
  const { inventory } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });

  const optionsName = name === 'New-Colors' ? 'Color' : name;

  const parsedColorName = useMemo(() => {
    return selectedOptions?.[optionsName]
      ?.split('-')
      .map((word) => {
        return Number.isNaN(parseFloat(word))
          ? word.slice(0, 1).toUpperCase() + word.slice(1)
          : '';
      })
      .join(' ')
      .trim();
  }, [selectedOptions, optionsName]);

  const isSize = name === 'Size';
  const isColor = name === 'Color';
  const isNewColor = name === 'New-Colors';

  const productOrGrouping = product.grouping?.isTransformed
    ? product.grouping.optionsMap[name]
    : values;

  const fitColors = product.grouping?.toggleOptions?.fit
    ?.filter((opt) => opt.key === product?.toggles?.fit[0]?.key)
    .map((opt) => opt.color);

  const productOrGroupingValues =
    isColor && hideDuplicate ? fitColors : productOrGrouping;

  const titleNewColor =
    isNewColor &&
    groupingProductsMapByNewColor?.[selectedOptions?.[optionsName]]
      ? parsedColorName
      : '';

  const titleColor = useMemo(() => {
    if (isColor && !isNewColor) {
      const selectedOption = selectedOptions?.[optionsName];
      const standardColorMap = groupingProductsMapByStandardColor;
      const colorMap = groupingProductsMapByColor;

      if (
        standardColorMap &&
        selectedOption &&
        standardColorMap[selectedOption]
      ) {
        return parsedColorName;
      }
      if (
        selectedOption &&
        selectedOption?.color &&
        colorMap[selectedOption] &&
        !groupingProductsMapByNewColor
      ) {
        return parsedColorName;
      }
    }
    return '';
  }, [
    name,
    titleNewColor,
    groupingProductsMapByStandardColor,
    selectedOptions,
    optionsName,
    parsedColorName,
    groupingProductsMapByColor,
    groupingProductsMapByNewColor,
  ]);

  const optionName = useMemo(() => {
    if (name === 'New-Colors' && titleNewColor) {
      return 'New Color';
    }
    if (name === 'New-Colors' && !titleNewColor) {
      return 'New Colors';
    }
    if (name === 'Color' && titleColor) {
      return 'Color';
    }
    if (name === 'Color' && !titleColor) {
      return 'Colors';
    }
    return name;
  }, [name, titleNewColor, titleColor]);

  return (
    <div>
      <div className="mb-2 flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <h5 className="text-title-h6 font-[600] leading-6">{optionName}</h5>
          <p className="text-title-h6 font-normal">
            {isNewColor && titleNewColor}
            {isColor && titleColor}
            {!isNewColor && !isColor ? selectedOptions?.[name] : ''}
          </p>
        </div>
        {isSize && (
          <div className="flex justify-end self-start">
            <WairFitAdvisor product={product} />
          </div>
        )}
      </div>

      <ul className="flex flex-wrap gap-2">
        {productOrGroupingValues?.map((value, index) => {
          const isSelected = selectedOptions?.[optionsName] === value;
          return (
            <li key={`${value}-${index}`}>
              {isColor || isNewColor ? (
                <ProductColorOptionValue
                  groupingProductsMapByColor={groupingProductsMapByColor}
                  isModal={isModal}
                  isSelected={isSelected}
                  name={optionsName}
                  product={product}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  value={value}
                />
              ) : (
                <ProductOptionValue
                  inventory={inventory}
                  isLoading={!inventory}
                  isSelected={isSelected}
                  isSize={isSize}
                  name={name}
                  product={product}
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                  value={value}
                />
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

ProductOption.displayName = 'ProductOption';

import {
  useCart,
  useCartTotals,
  useCartCount,
  useCartCheckout,
  useSettings,
  useCartItems,
  useCartClear,
} from '@backpackjs/storefront';

import { useVariantPrices } from '@hooks';
import { initiateCheckout } from '@utils/dataLayer';

import { Link } from '@snippets/Link';
import { useLoopShopNow } from '@hooks/loopReturns';

export function CartTotals({
  closeCart,
  giftWrapLineItemId,
  giftWrapProduct,
  toggleGiftWrap,
  isCartPage,
}) {
  const cart = useCart();
  const { cartClear, ...cartClearStatus } = useCartClear();
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const { cartCheckout } = useCartCheckout();
  const cartItems = useCartItems();
  const settings = useSettings();
  const { isLoopShopNow, totalCredit, finishLoopShopNowTransaction } =
    useLoopShopNow({
      cartItems,
      cartClear,
    });

  const { checkoutText, subtext, subtotalText } = { ...settings?.cart?.totals };

  const enabledGiftWrap = settings?.cart?.giftWrap?.enabled;

  const { price: giftWrapPrice } = useVariantPrices({
    variant: giftWrapProduct?.variants[0],
  });

  const handleCheckoutOnClick = ({ cart, cartTotals }) => {
    if (isLoopShopNow) {
      finishLoopShopNowTransaction();
    } else {
      initiateCheckout({ cart, cartTotals });
      cartCheckout();
    }
  };

  return (
    <div className="flex flex-col gap-4 p-5">
      <div>
        <div className="flex justify-between">
          <p className="font-bold">{subtotalText || 'Subtotal'}</p>
          <p>
            $
            {parseFloat(cartTotals?.total || '0')
              .toFixed(2)
              .replace('.00', '')}
          </p>
        </div>

        {subtext && <p className="mt-2 text-xs text-mediumGray">{subtext}</p>}
        {isLoopShopNow && (
          <p className="mt-2 text-xs text-mediumGray">{totalCredit}</p>
        )}
      </div>

      {cartCount ? (
        <button
          id="checkout-button"
          aria-label="Go to checkout page"
          className="btn-primary w-full"
          onClick={() => handleCheckoutOnClick({ cart, cartTotals })}
          type="button"
        >
          {checkoutText || 'Checkout'}
        </button>
      ) : (
        <Link className="btn-primary w-full" href="/collections/all">
          Shop All
        </Link>
      )}
      <div className="flex items-center justify-between">
        {enabledGiftWrap && giftWrapPrice && (
          <label htmlFor="giftWrap" className="flex items-center">
            <input
              checked={!!giftWrapLineItemId}
              className="mr-2 h-5 w-5"
              name="giftWrap"
              onChange={() => toggleGiftWrap()}
              type="checkbox"
            />
            <span className="text-label text-[13px]">
              Gift Wrap My Items For {giftWrapPrice}
            </span>
          </label>
        )}
        {!isCartPage && (
             <div className="flex w-full flex-1 justify-end">
             <Link
               className="text-link-sm"
               href="/cart"
               onClick={() => closeCart()}
             >
               View Cart
             </Link>
           </div>
        )}
      </div>
    </div>
  );
}

CartTotals.displayName = 'CartTotals';

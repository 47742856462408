export function Schema() {
  return {
    category: 'Text',
    label: 'Html Block',
    key: 'html-block',
    fields: [
      {
        label: 'HTML',
        name: 'html',
        component: 'html',
        description: 'An HTML input',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Background color, above the fold, full width, full bleed, vertical padding',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes horizontal padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Vertical Padding',
            name: 'verticalPadding',
            component: 'toggle',
            description: 'Adds vertical padding to this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          fullWidth: true,
          fullBleed: true,
          verticalPadding: false,
        },
      },
    ],
  };
}

import { useProductsFromHandles, useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { SearchItem } from './SearchItem';

export function SearchResults({
  closeSearch,
  collectionResults,
  productResults,
}) {
  const settings = useSettings();
  // products for data layer event
  const { products } = useProductsFromHandles({
    handles: productResults?.slice(0, 10).map(({ item }) => item?.handle),
  });

  const { productsEnabled, collectionsEnabled } = {
    ...settings?.search?.results,
  };

  // commented out for potential future use for data layer 3.1
  // useEffect(() => {
  //   if (!products) return;
  //   PubSub.publish('VIEW_SEARCH_RESULTS', products);
  // }, [products]);

  return (
    <div className="scrollbar-hide relative flex flex-1 flex-col gap-4 overflow-y-auto pt-0">
      {productsEnabled && products?.length > 0 && (
        <div>
          <h4 className="px-5 text-base font-medium uppercase tracking-normal">
            Products
          </h4>

          <ul>
            {productResults?.slice(0, 10).map((item, index) => {
              return item ? (
                <li key={index} className="px-5 pt-3">
                  <SearchItem
                    closeSearch={closeSearch}
                    index={index}
                    item={item}
                  />
                </li>
              ) : null;
            })}
          </ul>
        </div>
      )}

      {collectionsEnabled && collectionResults?.length > 0 && (
        <div className="mb-8 px-5">
          <h4 className="mb-3 text-base font-medium uppercase tracking-normal">
            Collections
          </h4>

          <ul className="flex flex-col items-start gap-3">
            {collectionResults.map((item, refIndex) => {
              return (
                <li key={refIndex}>
                  <Link
                    aria-label={item.title}
                    href={`/collections/${item.handle}`}
                  >
                    <p className="text-underline">{item.title}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

SearchResults.displayName = 'SearchResults';

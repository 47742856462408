import { useRouter } from 'next/router';
import { useProductsFromHandles } from '@backpackjs/storefront';

import { Collection, Spinner } from '@snippets';
import { useSearchProductResults } from '@hooks';
import { Schema } from './SearchResults.schema';

export function SearchResults() {
  const { query } = useRouter();
  const { productResults } = useSearchProductResults({
    term: query.term,
  });
  const { products } = useProductsFromHandles({
    handles: productResults?.map((item) => item?.handle),
  });

  // commented out for potential future use for data layer 3.1
  // useEffect(() => {
  //   if (!products) return;
  //   PubSub.publish('VIEW_SEARCH_PAGE_RESULTS', products);
  // }, [products]);

  const productsReady = products?.length > 0;

  return (
    <div className="px-contained py-contained-2x">
      {products ? (
        <>
          <h1 className="text-title-h3 mx-auto mb-6 max-w-[50rem] text-center max-md:px-4 lg:mb-8">
            {query.term
              ? `Found ${products.length} ${
                  products.length === 0 ? 'result' : 'results'
                } for "${query.term}"`
              : null}
          </h1>

          {products.length > 0 && (
            <Collection
              handle="search"
              products={products}
              isSearch
              productsReady={productsReady}
            />
          )}
        </>
      ) : (
        <div className="flex min-h-[20rem] items-center justify-center">
          <Spinner width="32" />
        </div>
      )}
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
SearchResults.Schema = Schema;

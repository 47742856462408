import React from 'react';
import equal from 'fast-deep-equal';
import { Link } from '@snippets';

const removeColorProperty = (obj) => {
  const newObj = { ...obj };
  delete newObj.Color;
  return newObj;
};

export function FitToggles({
  product,
  selectedOptions,
  groupingProductsMapByColor,
}) {
  const toggleGroupKeys = Object.keys(product?.grouping?.toggleOptions);
  if (!product?.grouping?.toggleOptionsMap) return null;
  const toggles = product?.grouping?.toggleOptionsMap[product.handle];
  const currentToggle =
    (toggles?.length &&
      toggles.filter((toggle) => toggle.handle === product?.handle)[0]) ||
    null;

  if (!currentToggle?.key || !groupingProductsMapByColor)  return null;
  return (
    <div className="mb-2 flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <h5 className="text-title-h6 font-[600] capitalize leading-6">
          {toggleGroupKeys}
        </h5>
        <p className="text-title-h6 font-normal capitalize">
          {currentToggle?.key}
        </p>
      </div>

      <ul className="flex flex-wrap justify-start gap-2 ">
        {toggles?.map((toggle, idx) => {
          const selectedVariantFromOptions = () => {
            return groupingProductsMapByColor[toggle?.color]?.variants?.find(
              ({ selectedOptionsMap }) => {
                const selectedOptionsWithoutColor =
                  removeColorProperty(selectedOptions);
                const selectedOptionsMapWithoutColor =
                  removeColorProperty(selectedOptionsMap);
                return equal(
                  selectedOptionsWithoutColor,
                  selectedOptionsMapWithoutColor
                );
              }
            );
          };

          const isFromGrouping = product.grouping?.isTransformed;

          const url = () => {
            if (!isFromGrouping) return null;

            const variant = selectedVariantFromOptions();
            if (!variant) return null;

            let params = '';
            if (typeof window !== 'undefined') {
              const { search } = window.location;
              params = new URLSearchParams(search);
              params.set('variant', variant.legacyResourceId);
            }

            return variant.product.handle
              ? `/products/${variant.product.handle}?${params}`
              : null;
          };
          const disabled = !toggle.handle;

          return (
            <li key={idx}>
              <div
                className={`relative flex h-8 min-w-[2rem] items-center justify-center overflow-hidden rounded-[10%] border border-text p-0.5 text-xs transition md:h-10 md:min-w-[2.5rem] ${
                  disabled
                    ? 'bg-[linear-gradient(to_top_left,_transparent_calc(50%_-_0.0325rem),_#000,_transparent_calc(50%_+_0.09375rem))]'
                    : ''
                }`}
              >
                <Link
                  className={`flex h-full w-full items-center justify-center rounded-[10%] border-white p-1 capitalize transition-[border-width] duration-100  ${
                    currentToggle?.key === toggle?.key
                      ? 'bg-black font-semibold text-white'
                      : ''
                  }`}
                  onClick={(e) => {
                    if (currentToggle?.key === toggle?.key) {
                      e.preventDefault();
                    }
                  }}
                  href={url()}
                >
                  {toggle?.key}
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

FitToggles.displayName = 'FitToggles';

export function Schema() {
  return {
    category: 'Product',
    label: 'Product Reviews',
    key: 'product-reviews',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/2597/4944/files/product-reviews-preview.png?v=1679506120',
    fields: [],
  };
}

export const features = {
  '2-way-stretch': {
    heading: '2-Way Stretch',
    description: 'Allows you to move with ease',
    icon: '/svgs/icon-2way.svg#icon-2way',
  },
  '4-way-stretch': {
    heading: '4-Way Stretch',
    description: 'Allows you to always be on the move',
    icon: '/svgs/icon-4way.svg#icon-4way',
  },
  'anti-dust': {
    heading: 'Anti Dust & Pollen',
    description: 'Protective layer against dust & allergens',
    icon: '/svgs/icon-anti-dust.svg#icon-anti-dust',
  },
  antimicrobial: {
    heading: 'Antimicrobial',
    description: 'Fights odor while protecting you and your garment',
    icon: '/svgs/icon-antimicrobial.svg#icon-antimicrobial',
  },
  breathable: {
    heading: 'Breathable',
    description: 'Allows for air flow to keep you cool',
    icon: '/svgs/icon-breathable.svg#icon-breathable',
  },
  'dobby-weave': {
    heading: 'Dobby Weave',
    description:
      'Fabric weave characterized by small geometric textured patterns',
    icon: '/svgs/icon-dobby-weave.svg#icon-dobby-weave',
  },
  'double-knit-jacquard-weave': {
    heading: 'Double-Knit Jacquard Weave',
    description:
      'Fabric with an intricate weave construction with variegated pattern',
    icon: '/svgs/icon-dbl-knit-jacquard.svg#icon-dbl-knit-jacquard',
  },
  'easy-care': {
    heading: 'Easy Care',
    description: 'Created to make caring for your garment easy',
    icon: '/svgs/icon-easycare.svg#icon-easycare',
  },
  'hidden-pockets': {
    heading: 'Hidden Pockets',
    description: 'Hidden zip pockets to protect your valuables',
    icon: '/svgs/icon-hiddenpocket.svg#icon-hiddenpocket',
  },
  'linear-flex': {
    heading: 'Linear Flex®',
    description:
      'Uniquely knitted to have a smooth, silky interior and a sturdy, textured exterior',
    icon: '/svgs/icon-linearflex.svg#icon-linearflex',
  },
  luxseam: {
    heading: 'Luxseam®',
    description:
      'Engineered seamless technology for easy movement and the perfect fit',
    icon: '/svgs/icon-luxseam.svg#icon-luxseam',
  },
  polymuse: {
    heading: 'Polymuse',
    description:
      'Textured perforated performance fabric with premium comfort and breathability',
    icon: '/svgs/icon-polymuse.svg#icon-polymuse',
  },
  'moisture-wicking': {
    heading: 'Moisture Wicking',
    description: 'Actively draws moisture away from your body',
    icon: '/svgs/icon-moisture-w.svg#icon-moisture-w',
  },
  'quick-dry': {
    heading: 'Quick Dry',
    description: 'Dries quickly to always keep you warm',
    icon: '/svgs/icon-quickdry.svg#icon-quickdry',
  },
  'seven-pockets': {
    heading: 'Seven Pockets',
    description: 'Designed with seven pockets for extra carrying capability',
    icon: '/svgs/icon-7pocket.svg#icon-7pocket',
  },
  'shape-retention': {
    heading: 'Shape Retention',
    description: 'Retains its shapes for maximum comfort on the move',
    icon: '/svgs/icon-shape-retention.svg#icon-shape-retention',
  },
  'skin-friendly': {
    heading: 'Skin Friendly',
    description:
      'Hypo-allergenic fabric that is safe & effective for all skin types',
    icon: '/svgs/icon-skin.svg#icon-skin',
  },
  'soft-touch': {
    heading: 'Soft Touch',
    description: 'Fabric with a handfeel that is pleasant to the touch',
    icon: '/svgs/icon-soft-touch.svg#icon-soft-touch',
  },
  upcycle: {
    heading: 'Upcycle',
    description:
      'High performance fabric made from recycled plastic bottles collected from our ocean',
    icon: '/svgs/icon-upcycle.svg#icon-upcycle',
  },
  washable: {
    heading: 'Washable',
    description: 'Machine washable and reusable',
    icon: '/svgs/icon-wash.svg#icon-wash',
  },
  'water-resistant': {
    heading: 'Water Resistant',
    description: 'Resists water with extremely low absorbency',
    icon: '/svgs/icon-water-resist.svg#icon-water-resist',
  },
  'wrinkle-free': {
    heading: 'Wrinkle Free',
    description: 'Built to fight wrinkles so you can forget the iron',
    icon: '/svgs/icon-wrinkle-free.svg#icon-wrinkle-free',
  },
  'zipper-pockets': {
    heading: 'Zipper Pockets',
    description: 'YKK lock down zippers to protect your valuables',
    icon: '/svgs/icon-zipper-pocket.svg#icon-zipper-pocket',
  },
};

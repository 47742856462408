import { Image } from '../../Image';

export function ColorVariantOption({
  color,
  isCollectionPage,
  onClick,
  selectedVariant,
}) {
  const isActive = color === selectedVariant?.selectedOptionsMap.Color;
  const swatchImageUrl = `https://cdn.shopify.com/s/files/1/2597/4944/files/${color}_64x.png`;

  return (
    <div className="group/color relative">
      <button
        aria-label={`Select ${color} color variant`}
        className={`relative flex h-4 w-4 items-center justify-center overflow-hidden rounded-[50%] border border-border transition md:hover:border-text ${
          isActive ? 'border-text' : ''
        }
        ${isCollectionPage ? 'md:h-6 md:w-6' : ''}
        `}
        onClick={onClick}
        type="button"
      >
        <Image
          alt={color}
          className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
          height="24"
          src={swatchImageUrl}
          width="24"
        />

        <div
          className={`absolute top-1/2 left-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-[50%] border-white transition-[border-width] duration-100 ${
            isActive ? 'border-[2px]' : 'border-[0px]'
          }`}
        />
      </button>
    </div>
  );
}

ColorVariantOption.displayName = 'ColorVariantOption';

import React from 'react';

import { Image, Link, Video } from '@snippets';
import { Schema } from './SplitBanner.schema';

export function SplitBanner({ cms }) {
  const { section, tiles, heading } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const verticalMarginContainerClasses = `${section?.verticalMarginMobile} ${section?.verticalMarginTablet} ${section?.verticalMarginDesktop}`;
  const gridColumnClass =
    tiles?.length === 4
      ? 'md:grid-cols-4'
      : tiles?.length === 3
      ? 'md:grid-cols-3'
      : 'md:grid-cols-2';

  const mobileDisplay =
    section?.tileLayoutMobile === 'horizontal' ? 'flex' : 'block';

  return (
    <div
      className={`px-contained py-contained ${verticalMarginContainerClasses}`}
    >
      {heading && (
        <h2 className="text-title-h3 mx-auto mb-5 text-center md:text-4xl">
          {heading}
        </h2>
      )}
      <div
        className={`${maxWidthClass} mx-auto grid gap-3 md:gap-5 ${gridColumnClass}`}
      >
        {tiles?.slice(0, 4).map((item, index) => {
          const { button, content, image, text, video } = item;

          const {
            alignmentMobile,
            alignmentDesktop,
            darkOverlay,
            maxWidthMobile,
            maxWidthDesktop,
            positionMobile,
            positionDesktop,
            pxDt,
            pxMobile,
          } = {
            ...content,
          };

          const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;
          const positionClasses = `${positionMobile} ${positionDesktop}`;
          const maxWidthContentClasses = `${maxWidthMobile} ${maxWidthDesktop}`;
          const darkOverlayClass = darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : '';

          return (
            <div
              key={index}
              className={`relative ${mobileDisplay} w-full md:block`}
            >
              <div
                className={`relative flex basis-1/2 bg-offWhite ${section?.aspectRatio}`}
              >
                <Link
                  aria-label={button?.link?.text}
                  href={button?.link?.url}
                  newTab={button?.link?.newTab}
                  tabIndex="-1"
                >
                  <>
                    {video?.src && (
                      <Video
                        isVisible
                        posterSrc={video?.posterSrc}
                        videoSrc={video.src}
                      />
                    )}
                    {image?.image?.src && !video?.src && (
                      <Image
                        alt={image?.alt}
                        className={`${image?.position}`}
                        fill
                        sizes="(min-width: 1440px) 1000px, (min-width: 1024px) 40vw, 100vw"
                        src={image.image.src}
                      />
                    )}
                  </>
                </Link>
              </div>

              <div
                className={`py-contained ${
                  section?.contentPlacement === 'inside-image'
                    ? 'absolute inset-0 h-full w-full'
                    : ''
                } ${
                  section?.tileLayoutMobile === 'horizontal'
                    ? 'flex basis-1/2'
                    : ''
                } ${pxMobile ? 'px-contained' : 'px-0'} ${
                  pxDt ? 'md:px-contained' : 'md:px-0'
                } flex ${positionClasses} ${darkOverlayClass}
                `}
                style={{ color: text?.color }}
              >
                <div
                  className={`flex flex-col ${alignmentClasses} ${maxWidthContentClasses}`}
                >
                  <h3 className={`${text?.headingStyle}`}>{text?.heading}</h3>

                  {text?.enableHeadingDivider && (
                    <div
                      className="mt-2 h-[0.0625rem] w-[4.5rem] lg:mt-3"
                      style={{ backgroundColor: text?.color }}
                    />
                  )}

                  {text?.description && (
                    <p className="mt-3 text-base lg:mt-4">{text.description}</p>
                  )}

                  {button?.link?.url && button?.link?.text && (
                    <div className="mt-5">
                      <Link
                        aria-label={button.link?.text}
                        className={button.style}
                        href={button.link?.url}
                        newTab={button.link?.newTab}
                      >
                        {button.link?.text}
                      </Link>
                    </div>
                  )}

                  {button?.link2?.url && button?.link2?.text && (
                    <div className="mt-5">
                      <Link
                        aria-label={button.link2?.text}
                        className={button.style}
                        href={button.link2?.url}
                        newTab={button.link2?.newTab}
                      >
                        {button.link2?.text}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

SplitBanner.displayName = 'SplitBanner';
SplitBanner.Schema = Schema;

import { BUTTON_STYLES, COLORS } from './common';

export default {
  label: 'Collection',
  name: 'collection',
  component: 'group',
  description: 'Filters, sort, pagination, product item, promotion',
  fields: [
    {
      label: 'Filters',
      name: 'filters',
      component: 'group',
      description: 'Enable, custom filters, show option count',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Filters',
          name: 'filters',
          component: 'group-list',
          description: `A filter's options are automatically determined by the products in the collection.\n\nNote: a filter will be hidden if there are no options, or only has one option, which has a count equal to the total number of products in the collection.`,
          itemProps: {
            label: '{{item.label}}',
          },
          fields: [
            {
              label: 'Shopify Property',
              name: 'source',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              description:
                'Note: Shopify tags must separate name and value with "::", e.g. "colorfilter::Red"',
              options: [
                { label: 'Tag', value: 'tag' },
                { label: 'Option', value: 'option' },
                { label: 'Product Type', value: 'productType' },
              ],
            },
            {
              label: 'Name',
              name: 'name',
              component: 'text',
              description: `Letter casing must be same as name in Shopify.\nTags e.g. "colorfilter", "material"\nOptions e.g. "Color", "Size"\nNot applicable for Product Type`,
            },
            {
              label: 'Label',
              name: 'label',
              component: 'text',
            },
            {
              label: 'Default Open',
              name: 'defaultOpen',
              component: 'toggle',
              description:
                'Applicable only to filter dropdowns on tablet and desktop',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Search page result only',
              name: 'searchPageOnly',
              component: 'toggle',
              description: 'Show the filter in the search results only',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
              default: false,
            },
            {
              label: 'Order Options By',
              name: 'orderValuesBy',
              component: 'radio-group',
              direction: 'horizontal',
              variant: 'radio',
              options: [
                { label: 'Alphabet', value: 'alphabet' },
                { label: 'Number', value: 'number' },
                { label: 'Custom', value: 'custom' },
              ],
            },
            {
              label: 'Custom Order',
              name: 'customOrder',
              component: 'list',
              field: {
                component: 'text',
              },
            },
          ],
          defaultItem: {
            source: 'tag',
            name: 'tag',
            label: 'Tag',
            defaultOpen: false,
            orderValuesBy: 'alphabet',
          },
          defaultValue: [
            {
              source: 'productType',
              name: '',
              label: 'Product Type',
              defaultOpen: false,
              orderValuesBy: 'alphabet',
            },
            {
              source: 'option',
              name: 'Size',
              label: 'Size',
              defaultOpen: false,
              orderValuesBy: 'custom',
              customOrder: [
                'One Size',
                'OS',
                'O/S',
                'XXS',
                'XX-Small',
                'XS',
                'X-Small',
                'S',
                'Small',
                'M',
                'Medium',
                'L',
                'Large',
                'XL',
                'X-Large',
                'XXL',
                'XX-Large',
                'XXXL',
                'XXX-Large',
              ],
            },
          ],
        },
        {
          label: 'Color Groups',
          name: 'colorGroups',
          component: 'group-list',
          description: 'Groups of high level colors used for filtering',
          itemProps: {
            label: '{{item.group}}',
          },
          fields: [
            {
              label: 'Color Group',
              name: 'group',
              component: 'text',
              description: 'The name of the color group. i.e "Black"..',
            },
            {
              label: 'Swatch Color',
              name: 'color',
              component: 'color',
            },
            {
              label: 'Group Colors List',
              name: 'colors',
              component: 'list',
              defaultItem: 'white',
              description:
                'A list of colors which are part of this color group. The color must be defined in "handle" format. without the color code. i.e black-dark-grey or black-floral or midnight-maroon...',
              field: {
                component: 'text',
              },
            },
          ],
        },
      ],
    },
    {
      label: 'Pagination',
      name: 'pagination',
      component: 'group',
      description: 'Enable, products per load, load type, load text',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          component: 'toggle',
          description: 'Loads products in segments',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Products Per Load',
          name: 'resultsPerPage',
          component: 'number',
          description:
            'Count includes any promo tiles that may be positioned within each load',
        },
        {
          label: 'Load Type',
          name: 'loadType',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: [
            { label: 'Button', value: 'button' },
            { label: 'Infinite Scroll', value: 'infinite' },
          ],
        },
        {
          label: 'Load Text',
          name: 'loadText',
          component: 'text',
        },
        {
          label: 'Load Button Style',
          name: 'buttonStyle',
          component: 'select',
          options: BUTTON_STYLES,
        },
      ],
      defaultValue: {
        enabled: true,
        resultsPerPage: 24,
        loadType: 'button',
        loadText: 'Load More',
        buttonStyle: 'btn-inverse-dark',
      },
    },
    {
      label: 'Product Item',
      name: 'productItem',
      component: 'group',
      description: 'Star rating, color variant selector, quick shop',
      fields: [
        {
          label: 'Enable Star Rating',
          name: 'enabledStarRating',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Color Variant Selector',
          name: 'enabledColorSelector',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Quick Shop',
          name: 'enabledQuickShop',
          component: 'toggle',
          description:
            'Note: Quick shop is hidden on mobile and will only show if the product item has only one variant or multiple variants through a single option, e.g. "Size"',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
      ],
      defaultValue: {
        enabledStarRating: true,
        enabledColorSelector: true,
        enabledQuickShop: true,
      },
    },
    {
      label: 'Promotion',
      name: 'promotion',
      component: 'group',
      description: 'Promo tile campaigns',
      fields: [
        {
          label: 'Promo Tile Campaigns',
          name: 'campaigns',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          fields: [
            {
              label: 'Enable',
              name: 'enabled',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Name',
              name: 'name',
              component: 'text',
            },
            {
              label: 'Promo Tiles',
              name: 'promoTiles',
              component: 'group-list',
              itemProps: {
                label: '{{item.position}}: {{item.text.heading}}',
              },
              fields: [
                {
                  label: 'Grid Position',
                  name: 'position',
                  component: 'number',
                  description: 'Assigns grid order',
                },
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                  description: 'Makes the entire tile a clickable link',
                },
                {
                  label: 'Background Settings',
                  name: 'background',
                  component: 'group',
                  description: 'Background color, image, video, dark overlay',
                  fields: [
                    {
                      label: 'Background Color',
                      name: 'bgColor',
                      component: 'select',
                      options: COLORS,
                    },
                    {
                      label: 'Image Alt',
                      name: 'alt',
                      component: 'text',
                      description: 'Brief description of image',
                    },
                    {
                      label: 'Image',
                      name: 'image',
                      component: 'image',
                      description: 'Overrides background color',
                    },
                    {
                      label: 'Video URL',
                      name: 'videoSrc',
                      component: 'text',
                      description: 'Overrides image. Autoplays once in view',
                    },
                    {
                      label: 'Video Poster Image',
                      name: 'videoPoster',
                      component: 'image',
                      description: 'First frame of video while video loads',
                    },
                    {
                      label: 'Dark Overlay',
                      name: 'darkOverlay',
                      component: 'toggle',
                      description: 'Adds 20% opacity black overlay over media',
                      toggleLabels: {
                        true: 'On',
                        false: 'Off',
                      },
                    },
                  ],
                },
                {
                  label: 'Text Settings',
                  name: 'text',
                  component: 'group',
                  description: 'Heading, subtext, text color',
                  fields: [
                    {
                      label: 'Heading',
                      name: 'heading',
                      component: 'text',
                    },
                    {
                      label: 'Subtext',
                      name: 'subtext',
                      component: 'textarea',
                    },
                    {
                      label: 'Text Color',
                      name: 'textColor',
                      component: 'select',
                      options: COLORS,
                    },
                  ],
                },
              ],
              defaultItem: {
                position: 5,
                background: { bgColor: 'var(--off-white)', darkOverlay: false },
                text: {
                  heading: 'Promo Tile Heading',
                  subtext:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                  textColor: 'var(--text)',
                },
              },
            },
            {
              label: 'Collection Handles',
              name: 'collections',
              component: 'list',
              description:
                'Add all collection handles that will run this campaign, e.g. "all", "best-sellers".\n\nTo apply to search results page, add "search"',
              field: {
                component: 'text',
              },
            },
          ],
          defaultItem: {
            enabled: true,
            name: 'Campaign',
            promoTiles: [
              {
                position: 5,
                background: { bgColor: 'var(--off-white)', darkOverlay: false },
                text: {
                  heading: 'Promo Tile Heading',
                  subtext:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                  textColor: 'var(--text)',
                },
              },
            ],
            collections: ['all'],
          },
        },
      ],
    },
    {
      label: 'Sort',
      name: 'sort',
      component: 'group',
      description: 'Enable, sort labels',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Default Label',
          name: 'defaultLabel',
          component: 'text',
        },
        {
          label: 'Recently Added Label',
          name: 'recentlyAddedLabel',
          component: 'text',
        },
        {
          label: 'Price High to Low Label',
          name: 'highToLowLabel',
          component: 'text',
        },
        {
          label: 'Price Low to High Label',
          name: 'lowToHighLabel',
          component: 'text',
        },
      ],
      defaultValue: {
        defaultLabel: 'Featured',
        recentlyAddedLabel: 'Recently Added',
        highToLowLabel: 'High to Low',
        lowToHighLabel: 'Low to High',
      },
    },
    {
      label: 'Collection Pairs',
      name: 'collectionPairs',
      component: 'group',
      description: 'Enables Collection Pairs (Mens/Womens)',
      fields: [
        {
          label: 'Collections Mapping',
          name: 'collectionsMapping',
          component: 'group-list',
          itemProps: {
            label: '{{item.mensHandle}}',
          },
          fields: [
            {
              label: 'Mens Handle',
              name: 'mensHandle',
              component: 'text',
            },
            {
              label: 'Womens Handle',
              name: 'womensHandle',
              component: 'text',
            },
            {
              label: 'Mens Text Override',
              name: 'mensTextOverride',
              component: 'text',
            },
            {
              label: 'Womens Text Override',
              name: 'womensTextOverride',
              component: 'text',
            },
          ],
        },
      ],
    },
  ],
};

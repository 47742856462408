import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ReviewStars } from '../ReviewStars';

const isBrowser = typeof window !== 'undefined';

export function ProductStars({ isPdp, product }) {
  const [reviewAggregate, setReviewAggregate] = useState(null);

  const fetchReviewAggregate = useCallback(async () => {
    const { legacyResourceId } = { ...product };
    try {
      if (!legacyResourceId) return;

      const endpoint = '/api/reviews';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getProductReviewAggregate',
          productId: legacyResourceId,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();
      if (!data) {
        throw new Error('No data returned from fetchReviewAggregate');
      }
      setReviewAggregate({
        rating: data.average_score,
        count: data.total_reviews,
      });
    } catch (error) {
      console.error(`fetchReviewAggregate error: ${error.message}`);
      throw error;
    }
  }, [product]);

  const scrollToReviewSection = useCallback(() => {
    try {
      if (!isBrowser || !isPdp) return;
      const reviewsContainer = document.getElementById('YoptoProductReviews');
      reviewsContainer.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    } catch (error) {
      // throw new Error(error)
      console.warn('Error while scrolling to reviews form', error.message);
    }
  });

  useEffect(() => {
    fetchReviewAggregate();
  }, [product]);

  if (product?.handle === 'rise-ai-giftcard' || !reviewAggregate) return null;

  return (
    <div
      className="flex flex-wrap gap-1 md:gap-2"
      onClick={() => scrollToReviewSection()}
      aria-hidden="true"
    >
      {reviewAggregate?.rating || isPdp ? (
        <ReviewStars
          rating={reviewAggregate?.rating}
          size={isPdp ? 'large' : 'small'}
        />
      ) : null}

      {reviewAggregate?.count ? (
        <p className={`text-sm underline ${isPdp ? 'md:text-base' : ''}`}>
          {reviewAggregate.count} Review{reviewAggregate.count > 1 ? 's' : ''}
        </p>
      ) : !isPdp ? (
        <></>
      ) : (
        <p className="text-sm underline md:text-base">Write a Review</p>
      )}
    </div>
  );
}

ProductStars.displayName = 'ProductStars';
ProductStars.propTypes = {
  legacyResourceId: PropTypes.string,
};

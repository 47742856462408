import React from 'react';
import { useSettings } from '@backpackjs/storefront';
import { CollectionPairs as CollectionPairsContent } from '@snippets/Collection/CollectionPairs';

import { Schema } from './CollectionPairs.schema';

export function CollectionPairs() {
  const settings = useSettings();
  const { collectionPairs: collectionPairsSettings } = {
    ...settings?.collection,
  };

  return (
    <div className="flex justify-center pt-4 pb-2">
      <CollectionPairsContent
        collectionPairsSettings={collectionPairsSettings}
      />
    </div>
  );
}

CollectionPairs.displayName = 'CollectionPairs';
CollectionPairs.Schema = Schema;

import { Schema } from './HtmlBlock.schema';

export function HtmlBlock({ cms }) {
  const { html, section } = cms;

  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div
      className={`${section?.fullBleed ? '' : 'px-contained'} ${
        section?.verticalPadding ? 'py-contained' : ''
      } ${maxWidthContainerClass}`}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
}

HtmlBlock.displayName = 'HtmlBlock';
HtmlBlock.Schema = Schema;

import { useState, useEffect } from 'react';

const isBrowser = typeof window !== 'undefined';
const DEBUG = false;

const refreshYotpoWidgets = ({ delay = 250 }) => {
  // refresh yotpo on page navigation
  if (isBrowser) {
    const t0 = setInterval(() => {
      if (typeof window.yotpo?.refreshWidgets === 'function') {
        if (window.yotpo) {
          // eslint-disable-next-line no-unused-expressions
          DEBUG &&
            console.log('🔄 Refreshing Yotpo widgets', window.yotpo.widgets);
          window.yotpo.refreshWidgets();
          window.clearInterval(t0);
        }
      }
    }, delay);
  }
};

export const useYotpoRefreshOnMount = ({ delay = 100 }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;
    // eslint-disable-next-line no-unused-expressions
    DEBUG && console.log('🔄 Yotpo element mounted');
    refreshYotpoWidgets({ delay });
  }, [mounted]);
};

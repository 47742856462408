import Head from 'next/head';

export function BlogHead({ blog = {}, pageUrl }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Blog',
            '@id': pageUrl,
            mainEntityOfPage: pageUrl,
            name: blog.seo?.title || '',
            description: blog.seo?.description || '',
            publisher: {
              '@type': 'Organization',
              '@id': process.env.SITE_URL,
              name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
              logo: {
                '@type': 'ImageObject',
                '@id': process.env.NEXT_PUBLIC_SITE_LOGO || '',
                url: process.env.NEXT_PUBLIC_SITE_LOGO || '',
              },
            },
          }),
        }}
      />
    </Head>
  );
}

BlogHead.displayName = 'BlogHead';

import { useMemo } from 'react';
import { useCartRemoveItem, useCartItems } from '@backpackjs/storefront';

import { useAddToCart } from '@snippets/AddToCart/useAddToCart';
import { Spinner, Image, Link } from '@snippets';

import { GWPSelector } from './GWPSelector';

export function CartGWPItem({ closeCart, gwpProduct, qualifies }) {
  const cartItems = useCartItems();

  const product = gwpProduct?.gwp?.products[0];

  const groupedProducts =
    (gwpProduct &&
      Object.values(gwpProduct)
        .reduce((carry, { products }) => {
          return [
            ...carry,
            ...(products?.map((product) => {
              return product?.grouping?.products || product?.handle;
            }) || []),
          ];
        }, [])
        ?.flat()) ||
    null;

  const isAdded = useMemo(() => {
    return cartItems?.some((item) =>
      groupedProducts?.some(
        (product) => product === item?.variant?.product?.handle
      )
    );
  }, [cartItems, groupedProducts]);

  const lineItemId = useMemo(() => {
    return cartItems?.find((item) =>
      groupedProducts?.some(
        (product) => product === item?.variant?.product?.handle
      )
    );
  }, [cartItems, groupedProducts])?.id;

  const url = `/products/${product?.handle}`;
  const variant = product?.variants?.[0];

  const {
    state: { buttonText, isAdding, isLoading, isSoldOut },
    actions: { handleAddToCart },
  } = useAddToCart({
    addToCartText: 'ADD',
    selectedVariant: variant,
  });

  const { cartRemoveItem } = useCartRemoveItem();

  const variantTitle = useMemo(() => {
    const options = variant?.selectedOptions?.reduce((obj, option) => {
      const name = option?.name?.toLowerCase();

      if (name === 'color') {
        const color = option.value?.split('-').map((word) => {
          return isNaN(parseFloat(word))
            ? word.slice(0, 1).toUpperCase() + word.slice(1)
            : '';
        });
        obj[name] = color.join(' ').trim();
      } else {
        obj[name] = option.value;
      }
      return obj;
    }, {});

    return options ? Object.values(options).join(' / ') : '';
  }, [variant?.id]);

  let cursorClass = '';
  if (isAdding) cursorClass = 'cursor-default';
  else if (isLoading) cursorClass = 'cursor-wait';

  if (!product || isSoldOut) return null;

  return (
    <div className="relative grid grid-cols-[96px_1fr] items-center gap-5 py-5">
      <Link
        aria-label={`View ${variant.product.title}`}
        href={url}
        onClick={closeCart}
        tabIndex="-1"
      >
        <Image
          alt={variant.product.title}
          className="max-h-[120px] bg-offWhite object-cover"
          height="120"
          src={gwpProduct?.gwp?.image?.src || product?.images?.[0]?.src}
          width="96"
        />
      </Link>

      <div className="flex min-h-[6.25em] flex-col justify-between gap-4">
        <div className="flex justify-between">
          <div className="relative flex-1 pr-2">
            <h5 className="text-base tracking-normal">
              <Link
                aria-label={`View ${variant.product.title}`}
                href={url}
                onClick={closeCart}
              >
                {groupedProducts?.length > 1
                  ? 'Claim Your Gift'
                  : variant.product.title}
              </Link>
            </h5>

            {groupedProducts?.length > 1 ? (
              <p className="text-[13px]">
                {gwpProduct?.gwp?.text || `Select a free gift!`}
              </p>
            ) : (
              <>
                {variantTitle !== 'Default Title' && (
                  <p className="text-[13px]">{variantTitle}</p>
                )}
              </>
            )}
          </div>

          <div className="mb-6 flex flex-wrap justify-end gap-x-2">
            <p className="text-base">Free</p>
          </div>
        </div>

        <div className="flex items-center justify-between gap-3">
          {!isAdded ? (
            <>
              {groupedProducts?.length > 1 ? (
                <GWPSelector
                  classes={`btn-primary relative ${cursorClass} h-[40px] w-[100px] ${
                    qualifies
                      ? 'bg-black'
                      : 'border-[var(--medium-gray)] bg-mediumGray'
                  }`}
                  disabled={!qualifies}
                  products={groupedProducts}
                />
              ) : (
                <button
                  aria-label={`Add ${variant.product.title} to cart`}
                  className={`btn-primary relative ${cursorClass} h-[40px] w-[100px] ${
                    qualifies
                      ? 'bg-black'
                      : 'border-[var(--medium-gray)] bg-mediumGray'
                  }`}
                  disabled={!qualifies}
                  onClick={() => {
                    if (qualifies)
                      handleAddToCart({
                        attributes: [{ key: '_gwp', value: 'true' }],
                      });
                  }}
                  type="button"
                >
                  {isAdding ? <Spinner /> : buttonText}
                </button>
              )}
            </>
          ) : (
            <button
              aria-label={`Remove ${variant.product.title} from cart`}
              className="text-link-normal-sm"
              onClick={() => {
                if (lineItemId) cartRemoveItem({ lineId: lineItemId });
              }}
              type="button"
            >
              Remove
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

CartGWPItem.displayName = 'CartGWPItem';

import { useEffect } from 'react';

import { ColorVariantOptions } from './ColorVariantOptions';

export function ColorVariantSelector({
  isCollectionPage,
  product,
  selectedVariant,
  setProductFromColorSelector,
  setVariantFromColorSelector,
}) {
  const hasMultipleColorsFromProduct =
    !product?.grouping?.isTransformed && product?.optionsMap?.Color?.length > 1;
  const hasMultipleColorsFromGrouping =
    product?.grouping?.isTransformed &&
    product.grouping.optionsMap.Color?.length > 1;
  const hasMultipleColors =
    hasMultipleColorsFromProduct || hasMultipleColorsFromGrouping;

  // sets initial variant from initial color
  useEffect(() => {
    if (!product || !hasMultipleColors) return;
    setProductFromColorSelector(product);
    setVariantFromColorSelector(product.variants?.[0] || null);
  }, [product?.id, hasMultipleColors]);

  return hasMultipleColors && selectedVariant ? (
    <ColorVariantOptions
      isCollectionPage={isCollectionPage}
      product={product}
      selectedVariant={selectedVariant}
      setProductFromColorSelector={setProductFromColorSelector}
      setVariantFromColorSelector={setVariantFromColorSelector}
    />
  ) : null;
}

ColorVariantSelector.displayName = 'ColorVariantSelector';

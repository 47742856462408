import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';

export function CartEmpty({ closeCart }) {
  const settings = useSettings();
  const { link, message } = { ...settings?.cart?.emptyCart };

  return (
    <div className="absolute top-1/2 left-1/2 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-3 px-5">
      <h4 className="text-center text-base tracking-normal">{message}</h4>

      {link?.url && link?.text && (
        <Link
          aria-label={link?.text}
          className="text-sm font-medium underline"
          href={link?.url}
          newTab={link?.newTab}
          onClick={closeCart}
        >
          {link?.text}
        </Link>
      )}
    </div>
  );
}

CartEmpty.displayName = 'CartEmpty';

import { useSettings } from '@backpackjs/storefront';

import { useGlobalContext } from '@contexts';
import { Cart, Search } from '@snippets';
import { SideDrawerHeader } from './SideDrawerHeader';

export function SideDrawer() {
  const settings = useSettings();
  const promobar = settings?.header?.promobar;
  const promobarDisabled =
    promobar && (!promobar.enabled || !promobar.messages?.length);
  const {
    state: { cartOpen, searchOpen },
  } = useGlobalContext();

  return (
    <aside
      className={`fixed left-full z-[70] flex w-full flex-col justify-between overflow-hidden bg-background transition md:max-w-[var(--sidebar-width)] ${
        cartOpen || searchOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      } ${
        promobarDisabled
          ? 'top-0 h-full'
          : 'top-[var(--promobar-height)] h-[calc(100%-var(--promobar-height))] lg:top-[var(--promobar-height-desktop)] lg:h-[calc(100%-var(--promobar-height-desktop))]'
      }`}
    >
      <SideDrawerHeader />
      {cartOpen && <Cart isDrawer />}
      {searchOpen && <Search />}
    </aside>
  );
}

SideDrawer.displayName = 'SideDrawer';

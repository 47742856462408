import { useRef, forwardRef, useMemo } from 'react';

import { Badges, Picture, Spinner } from '@snippets';
import { ProductItemVideo } from './ProductItemVideo';
import { useProductItemMedia } from './useProductItemMedia';

export const ProductItemMedia = forwardRef(
  ({ selectedProduct, selectedVariant }, ref) => {
    const hoverVideoRef = useRef(null);

    const { primaryMedia, hoverMedia } = useProductItemMedia({
      selectedProduct,
      selectedVariant,
    });

    const fitImage = useMemo(() => {
      return selectedProduct?.collections?.find(
        (tag) =>
          tag.handle === 'womens-bottoms' ||
          tag.handle === 'women-bottoms' ||
          tag.handle === 'mens-bottoms' ||
          tag.handle === 'men-bottoms'
      )
        ? 'object-bottom'
        : 'object-top';
    }, [selectedProduct?.id]);

    return (
      <div
        className="group/media relative aspect-[var(--product-image-aspect-ratio)] bg-offWhite"
        ref={ref}
        onMouseEnter={() => {
          if (hoverMedia?.mediaContentType !== 'VIDEO') return;
          hoverVideoRef.current.play();
        }}
        onMouseLeave={() => {
          if (hoverMedia?.mediaContentType !== 'VIDEO') return;
          hoverVideoRef.current.pause();
        }}
      >
        {/* media w/o hover element */}
        {primaryMedia &&
          !hoverMedia &&
          (primaryMedia.mediaContentType === 'VIDEO' ? (
            <ProductItemVideo autoPlay media={primaryMedia} />
          ) : (
            <ProductItemPicture
              src={primaryMedia.image.src}
              alt={selectedProduct?.title}
              fitImage={fitImage}
            />
          ))}

        {/* media w/ hover element */}
        {primaryMedia && hoverMedia && (
          <>
            <div className="h-full w-full opacity-100 transition duration-300 md:group-hover/media:opacity-0">
              {primaryMedia.mediaContentType === 'VIDEO' ? (
                <ProductItemVideo autoPlay media={primaryMedia} />
              ) : (
                <ProductItemPicture
                  src={primaryMedia.image.src}
                  alt={selectedProduct?.title}
                  fitImage={fitImage}
                />
              )}
            </div>

            <div className="absolute inset-0 hidden h-full w-full opacity-0 transition duration-300 md:block md:group-hover/media:opacity-100">
              {hoverMedia.mediaContentType === 'VIDEO' ? (
                <ProductItemVideo
                  autoPlay={false}
                  media={hoverMedia}
                  ref={hoverVideoRef}
                />
              ) : (
                <ProductItemPicture
                  src={hoverMedia.image.src}
                  alt={selectedProduct?.title}
                  fitImage={fitImage}
                />
              )}
            </div>
          </>
        )}

        {/* loader */}
        {!primaryMedia && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-gray">
            <Spinner />
          </div>
        )}

        <div className="pointer-events-none absolute left-0 top-0 z-[1] p-2.5 xs:p-3 xl:p-4">
          <Badges
            className="max-xl:text-label-sm gap-2 xs:gap-2 [&_div]:max-xl:py-0.5 [&_div]:max-xl:px-1.5"
            isDraft={selectedProduct?.status === 'DRAFT'}
            tags={selectedVariant?.product?.tags}
          />
        </div>
      </div>
    );
  }
);

function ProductItemPicture({ alt, fitImage, src }) {
  return (
    <Picture
      alt={alt}
      images={[
        {
          src,
          width: 350,
          ratio: 0.74,
        },
        {
          src,
          width: 350,
          scale: 2,
          ratio: 0.74,
        },
        {
          src,
          width: 350,
          scale: 3,
          ratio: 0.74,
        },
      ]}
      imageClassName={fitImage}
      aboveTheFold
    />
  );
}

ProductItemMedia.displayName = 'ProductItemMedia';

import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { Link } from '../Link';

export function CollectionPairs({ collectionPairsSettings = {} }) {
  const router = useRouter();
  const currentHandle = router.query.handle;

  const isPage = router?.pathname?.includes('/pages/');

  const { collectionsMapping } = {
    ...collectionPairsSettings,
  };

  const handles = useMemo(() => {
    const emptyState = { mensHandle: null, womensHandle: null };
    if (!collectionsMapping || !currentHandle) return emptyState;

    const _handles = collectionsMapping?.find((col) => {
      return (
        col.mensHandle === currentHandle || col.womensHandle === currentHandle
      );
    });

    return _handles || emptyState;
  }, [collectionsMapping, currentHandle]);

  return handles.mensHandle && handles.womensHandle ? (
    <ul className="flex items-center gap-x-5">
      {Object.keys(handles).map((key, index) => {
        if (key !== 'mensHandle' && key !== 'womensHandle') {
          return null;
        }

        return (
          <li className="text-center" key={index}>
            <Link
              className="text-underline"
              href={`/${isPage ? 'pages' : 'collections'}/${handles[key]}`}
            >
              {key === 'mensHandle'
                ? handles?.mensTextOverride || 'Men'
                : handles?.womensTextOverride || 'Women'}
            </Link>
          </li>
        );
      })}
    </ul>
  ) : null;
}

CollectionPairs.displayName = 'CollectionPairs';

import { useCallback, useState, forwardRef, useMemo } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { AddToCart, Favorite, Link } from '@snippets';
import { ColorVariantSelector } from './ColorVariantSelector';
import { ProductItemMedia } from './ProductItemMedia';
import { ProductItemPrice } from './ProductItemPrice';
import { ProductStars } from '../ProductStars';
import { QuickShop } from './QuickShop';

export const ProductItem = forwardRef(
  (
    {
      enabledColorSelector,
      enabledQuickShop,
      enabledStarRating,
      enableNamePrice = true,
      handle,
      index,
      isSearchPage,
      isGWP,
      onClick,
      priority,
      product: passedProduct,
    },
    ref
  ) => {
    const { ref: viewRef, inView } = useInView({
      rootMargin: '400px',
      triggerOnce: true,
    });
    const { product: fetchedFullProduct } = useProductByHandle({
      handle: handle || passedProduct?.handle,
      fetchOnMount: priority || inView,
    });

    const initialProduct = useMemo(() => {
      if (!passedProduct?.loaded) return fetchedFullProduct;
      return {
        ...passedProduct,
        ...fetchedFullProduct,
      };
    }, [fetchedFullProduct, passedProduct]);

    const [productFromColorSelector, setProductFromColorSelector] =
      useState(null);
    const [variantFromColorSelector, setVariantFromColorSelector] =
      useState(null);

    const selectedProduct = productFromColorSelector || initialProduct;
    const selectedVariant =
      variantFromColorSelector || selectedProduct?.variants?.[0];

    const productUrl = selectedProduct
      ? `/products/${selectedProduct.handle}`
      : '';
    const title = selectedProduct?.title;

    const handleClick = useCallback(() => {
      // commented out for potential future use for data layer 3.1
      // PubSub.publish(
      //   isSearchPage ? 'CLICK_SEARCH_ITEM' : 'CLICK_COLLECTION_ITEM',
      //   {
      //     ...selectedVariant,
      //     image: selectedProduct?.images?.[0],
      //     index,
      //   }
      // );
      if (typeof onClick === 'function') onClick();
    }, [index, selectedProduct, selectedVariant?.id]);

    const newTitle = useMemo(() => {
      if (!title) return;
      const splitArr = title?.split(' ');

      const collectionIndex = splitArr?.findIndex((str) =>
        str?.includes('\u2122')
      );

      if (collectionIndex === -1) return;

      const collectionWithTm = splitArr?.[collectionIndex];

      const collectionName = collectionWithTm?.slice(
        0,
        collectionWithTm.length - 1
      );

      const trademark = `${collectionWithTm?.slice(
        collectionWithTm.length - 1,
        collectionWithTm.length
      )} `;

      const titleBegin = [
        ...splitArr?.slice(0, collectionIndex),
        collectionName,
      ].join(' ');

      const titleEnd = [
        ...splitArr?.slice(collectionIndex + 1, splitArr?.length),
      ].join(' ');

      return { titleBegin, trademark, titleEnd };
    }, [title]);

    return (
      <div className="group flex h-full flex-col justify-between" ref={viewRef}>
        <div className="flex flex-col items-start">
          <Link
            aria-label={title}
            className="relative mb-3 w-full"
            href={productUrl}
            onClick={handleClick}
            tabIndex="-1"
          >
            <ProductItemMedia
              priority={priority}
              selectedProduct={selectedProduct}
              selectedVariant={selectedVariant}
              ref={ref}
            />

            <Favorite selectedVariant={selectedVariant} />

            {enabledQuickShop && (
              <QuickShop selectedProduct={selectedProduct} />
            )}
          </Link>

          <div
            className={`${
              enableNamePrice ? 'grid' : ' hidden'
            } min-h-[62px] w-full gap-y-3`}
          >
            <div className="flex w-full flex-col items-center justify-center gap-2">
              <div className="flex text-center">
                <Link
                  aria-label={title}
                  className="flex-1"
                  href={productUrl}
                  onClick={handleClick}
                >
                  {newTitle ? (
                    <h6 className="text-title-h6 mr-2 items-center font-demi text-2base	tracking-one ">
                      {newTitle?.titleBegin}
                      <span className="align-super text-xs">
                        {newTitle?.trademark}
                      </span>
                      {newTitle?.titleEnd}
                    </h6>
                  ) : (
                    <h6 className="text-title-h6 mr-2 items-center font-demi text-2base tracking-one	 ">
                      {title}
                    </h6>
                  )}
                </Link>
              </div>
              <ProductItemPrice
                isGWP={isGWP}
                selectedVariant={selectedVariant}
              />

              {enabledColorSelector && (
                <ColorVariantSelector
                  product={initialProduct}
                  selectedVariant={selectedVariant}
                  setProductFromColorSelector={setProductFromColorSelector}
                  setVariantFromColorSelector={setVariantFromColorSelector}
                />
              )}
            </div>

            {enabledStarRating && !isGWP && (
              <div className="mb-1.5">
                <Link
                  aria-label={`Reviews for ${title}`}
                  href={productUrl}
                  onClick={handleClick}
                  tabIndex="-1"
                >
                  <ProductStars product={initialProduct} />
                </Link>
              </div>
            )}

            {isGWP && (
              <AddToCart selectedVariant={selectedVariant} isGWP={isGWP} />
            )}
          </div>
        </div>
      </div>
    );
  }
);

ProductItem.displayName = 'ProductItem';
ProductItem.propTypes = {
  enabledColorSelector: PropTypes.bool,
  enabledQuickShop: PropTypes.bool,
  enabledStarRating: PropTypes.bool,
  handle: PropTypes.string,
  index: PropTypes.number,
  isSearchPage: PropTypes.bool,
  onClick: PropTypes.func,
};

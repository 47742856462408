import {
  BUTTON_STYLES,
  HEADINGS,
  COLORS,
  FLEX_POSITIONS,
  OBJECT_POSITIONS,
  VERTICAL_MARGINS,
} from '@settings/common';

const image = {
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      label: 'Image Alt',
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Image',
      name: 'image',
      component: 'image',
    },
    {
      label: 'Image Position',
      name: 'position',
      component: 'select',
      options: OBJECT_POSITIONS.mobile,
    },
  ],
};

const video = {
  label: 'Video Settings',
  name: 'video',
  description: 'Video link, poster image',
  component: 'group',
  fields: [
    {
      label: 'Video URL',
      name: 'src',
      component: 'text',
      description: 'Overrides tablet/desktop image option',
    },
    {
      label: 'Poster Image',
      name: 'poster',
      component: 'image',
      description: 'First frame of video while video loads',
    },
  ],
};

const text = {
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, superheading, subheading, color',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Enable Heading Divider',
      name: 'enableHeadingDivider',
      component: 'toggle',
      description: 'Displays divider below Heading',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Description',
      name: 'description',
      component: 'textarea',
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'select',
      options: COLORS,
    },
    {
      label: 'Heading Style',
      name: 'headingStyle',
      component: 'select',
      options: HEADINGS,
    },
  ],
};

const button = {
  label: 'Button Settings',
  name: 'button',
  description: 'button links, button styles',
  component: 'group',
  fields: [
    {
      label: 'Link',
      name: 'link',
      component: 'link',
    },
    {
      label: 'Button Style',
      name: 'style',
      component: 'select',
      options: BUTTON_STYLES,
    },
    {
      label: 'Link 2',
      name: 'link2',
      component: 'link',
    },
  ],
  defaultItem: {
    link: { text: 'Shop All', url: '' },
    style: 'btn-primary',
  },
};

const content = {
  label: 'Content Settings',
  name: 'content',
  description: 'Dark overlay, content position, content alignment',
  component: 'group',
  fields: [
    {
      label: 'Dark Overlay',
      name: 'darkOverlay',
      component: 'toggle',
      description: 'Adds 20% opacity black overlay over media',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Content Position (tablet/desktop)',
      name: 'positionDesktop',
      component: 'select',
      options: FLEX_POSITIONS.desktop,
    },
    {
      label: 'Content Alignment (tablet/desktop)',
      name: 'alignmentDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'md:text-left md:items-start' },
        { label: 'Center', value: 'md:text-center md:items-center' },
        { label: 'Right', value: 'md:text-right md:items-end' },
      ],
    },
    {
      label: 'Max Content Width (tablet/desktop)',
      name: 'maxWidthDesktop',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'md:max-w-[22rem] lg:max-w-[28rem]' },
        { label: 'Medium', value: 'md:max-w-[30rem] lg:max-w-[38rem]' },
        { label: 'Wide', value: 'md:max-w-[38rem] lg:max-w-[48rem]' },
        { label: 'Full', value: 'md:max-w-full' },
      ],
    },
    {
      label: 'Horizontal Padding (tablet/desktop)',
      name: 'pxDt',
      component: 'toggle',
      description: 'Adds horizontal padding to content',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Content Position (mobile)',
      name: 'positionMobile',
      component: 'select',
      options: FLEX_POSITIONS.mobile,
    },
    {
      label: 'Content Alignment (mobile)',
      name: 'alignmentMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'text-left items-start' },
        { label: 'Center', value: 'text-center items-center' },
        { label: 'Right', value: 'text-right items-end' },
      ],
    },
    {
      label: 'Max Content Width (mobile)',
      name: 'maxWidthMobile',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'max-w-[16.5rem]' },
        { label: 'Medium', value: 'max-w-[22.5rem]' },
        { label: 'Wide', value: 'max-w-[28.5rem]' },
        { label: 'Full', value: 'max-w-full' },
      ],
    },
    {
      label: 'Horizontal Padding (mobile)',
      name: 'pxMobile',
      component: 'toggle',
      description: 'Adds horizontal padding to content',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
  ],
};

export function Schema() {
  return {
    category: 'Media',
    label: 'Split Banner',
    key: 'split-banner',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/two-tiles-preview.jpg?v=1675730356',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
      },
      {
        label: 'Tiles',
        name: 'tiles',
        description: 'Max of 4 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.text.heading}}',
        },
        validate: {
          maxItems: 4,
        },
        fields: [image, video, text, button, content],
        defaultItem: {
          image: {
            alt: 'Rack of green t-shirts',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
            },
            position: 'object-center',
          },
          text: {
            heading: 'Headline',
            description: 'Nulla vitae elit libero, a pharetra augue.',
            color: 'var(--primary)',
          },
          button: {
            link: {
              text: '',
              url: '',
            },
            style: 'btn-primary',
          },
          content: {
            darkOverlay: false,
            alignmentDesktop: 'md:text-left md:items-start',
            positionDesktop: 'md:justify-start items-center',
            maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
            alignmentMobile: 'text-left items-start',
            positionMobile: 'justify-start items-center',
            maxWidthMobile: 'max-w-[22.5rem]',
          },
        },
        defaultValue: [
          {
            image: {
              alt: 'Rack of green t-shirts',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
              },
              position: 'object-center',
            },
            text: {
              heading: 'Headline',
              description: 'Nulla vitae elit libero, a pharetra augue.',
              color: 'var(--primary)',
            },
            button: {
              link: {
                text: '',
                url: '',
              },
              style: 'btn-primary',
            },
            content: {
              darkOverlay: false,
              alignmentDesktop: 'md:text-left md:items-start',
              positionDesktop: 'md:justify-start items-center',
              maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
              alignmentMobile: 'text-left items-start',
              positionMobile: 'justify-start items-center',
              maxWidthMobile: 'max-w-[22.5rem]',
            },
          },
          {
            image: {
              alt: 'Rack of green t-shirts',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/tobias-tullius-Fg15LdqpWrs-unsplash.jpg?v=1672348152',
              },
              position: 'object-center',
            },
            text: {
              heading: 'Headline',
              description: 'Nulla vitae elit libero, a pharetra augue.',
              color: 'var(--primary)',
            },
            button: {
              link: {
                text: '',
                url: '',
              },
              style: 'btn-primary',
            },
            content: {
              darkOverlay: false,
              alignmentDesktop: 'md:text-left md:items-start',
              positionDesktop: 'md:justify-start items-center',
              maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
              alignmentMobile: 'text-left items-start',
              positionMobile: 'justify-start items-center',
              maxWidthMobile: 'max-w-[22.5rem]',
            },
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Image apsect ratio, full width',
        fields: [
          {
            label: 'Image Aspect Ratio',
            name: 'aspectRatio',
            component: 'select',
            options: [
              { label: '3:2', value: 'aspect-[3/2]' },
              { label: '4:3', value: 'aspect-[4/3]' },
              { label: '5:4', value: 'aspect-[5/4]' },
              { label: '8:7', value: 'aspect-[8/7]' },
              { label: '1:1', value: 'aspect-[1/1]' },
              { label: '7:8', value: 'aspect-[7/8]' },
              { label: '4:5', value: 'aspect-[4/5]' },
              { label: '3:4', value: 'aspect-[3/4]' },
              { label: '2:3', value: 'aspect-[2/3]' },
            ],
          },
          {
            label: 'Content Placement',
            name: 'contentPlacement',
            component: 'radio-group',
            direction: 'vertical',
            variant: 'radio',
            options: [
              { label: 'Inside Image', value: 'inside-image' },
              { label: 'Below Image', value: 'below-image' },
            ],
          },
          {
            label: 'Tile Layout (mobile)',
            name: 'tileLayoutMobile',
            description: 'Controls image / content layout on mobile.',
            component: 'radio-group',
            direction: 'vertical',
            variant: 'radio',
            options: [
              { label: 'Vertical', value: 'vertical' },
              { label: 'Horizontal', value: 'horizontal' },
            ],
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            name: 'verticalMarginDesktop',
            label: 'Vertical Margin (desktop)',
            component: 'select',
            options: VERTICAL_MARGINS.desktop,
          },
          {
            name: 'verticalMarginTablet',
            label: 'Vertical Margin (tablet)',
            component: 'select',
            options: VERTICAL_MARGINS.tablet,
          },
          {
            name: 'verticalMarginMobile',
            label: 'Vertical Margin (mobile)',
            component: 'select',
            options: VERTICAL_MARGINS.mobile,
          },
        ],
        defaultValue: {
          aspectRatio: 'aspect-[5/4]',
          contentPlacement: 'below-image',
          tileLayoutMobile: 'vertical',
          fullWidth: false,
          verticalMarginDesktop: 'lg:my-0 xl:my-0',
          verticalMarginTablet: 'md:my-0',
          verticalMarginMobile: 'my-0',
        },
      },
    ],
  };
}

import { HeroSlide } from './HeroSlide';
import { HeroSlider } from './HeroSlider';
import { Schema } from './Hero.schema';

export function Hero({ cms }) {
  const { section, slider, slides } = cms;
  const cmsSectionId = cms?.cmsId || cms?.id;
  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const verticalMarginContainerClasses = `${section?.verticalMarginMobile} ${section?.verticalMarginTablet} ${section?.verticalMarginDesktop}`;
  const isFullBleed = section?.fullBleed;

  return (
    <div
      className={`section-${cmsSectionId} ${
        isFullBleed ? '' : 'px-contained'
      } ${verticalMarginContainerClasses} `}
    >
      {/* to define hero height for SSG */}
      <style>
        {`
          .section-${cmsSectionId} .hero-container,
          .section-${cmsSectionId} .content-below-image {
            height: ${
              (section?.heightMobile || 600) / (448 / 100)
            }vw !important;
          }

          @media only screen and (min-width: 768px) {
            .section-${cmsSectionId} .hero-container,
            .section-${cmsSectionId} .content-below-image {
              height: ${
                (section?.heightTablet || 600) / (767 / 100)
              }vw !important;
            }
          }

          @media only screen and (min-width: 1024px) {
            .section-${cmsSectionId} .hero-container,
            .section-${cmsSectionId} .content-below-image {
              height: ${
                (section?.heightDesktop || 700) / (1440 / 100)
              }vw !important;
            }
          }
        `}
      </style>

      <div
        className={`mx-auto flex flex-col ${
          section?.contentPlacement === 'inside-image'
            ? 'hero-container bg-offWhite'
            : ''
        } ${maxWidthContainerClass}`}
      >
        {slides?.length > 1 && (
          <HeroSlider
            aboveTheFold={section?.aboveTheFold}
            cmsSectionId={cmsSectionId}
            contentPlacement={section?.contentPlacement}
            slider={slider}
            slides={slides}
          />
        )}

        {slides?.length === 1 && (
          <HeroSlide
            aboveTheFold={section?.aboveTheFold}
            cmsSectionId={cmsSectionId}
            contentPlacement={section?.contentPlacement}
            isFirstSlide
            slide={slides[0]}
          />
        )}
      </div>
    </div>
  );
}

Hero.displayName = 'Hero';
Hero.Schema = Schema;

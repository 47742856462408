import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link, ProductItem, Svg } from '@snippets';
import { Schema } from './ProductsSlider.schema';

export function ProductsSlider({ cms }) {
  const { button, text, productItem, products, section, slider } = cms;
  const {
    sliderStyle,
    slidesPerViewDesktop,
    slidesPerViewMobile,
    slidesPerViewTablet,
  } = { ...slider };

  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const maxWidthClass =
    section?.fullWidth || isFullBleedAndCentered
      ? 'max-w-none'
      : 'max-w-[var(--content-max-width)]';

  return (
    <div
      className={`py-contained ${
        !isFullBleedAndCentered ? 'lg-px-contained' : ''
      }`}
    >
      <div className="m-auto flex flex-col">
        <div
          className={`${text?.textAlignmentMobile} ${text?.textAlignmentDesktop} px-contained-2x flex flex-col`}
        >
          <h2
            className="text-title-h3 md:text-4xl"
            style={{ color: text?.color }}
          >
            {text?.heading}
          </h2>

          {text?.enableHeadingDivider && (
            <div
              className="mt-2 h-[0.0625rem] w-[4.5rem] lg:mt-4"
              style={{ backgroundColor: text?.color }}
            />
          )}
        </div>

        {products && (
          <Swiper
            centeredSlides={isFullBleedAndCentered}
            className={`mt-5 w-full ${maxWidthClass} ${
              sliderStyle === 'fullBleedWithGradient'
                ? 'before:swiper-offset-gradient-270-left after:swiper-offset-gradient-270-right'
                : ''
            }`}
            grabCursor
            loop={isFullBleedAndCentered || sliderStyle === 'containedWithLoop'}
            modules={[Navigation]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
              disabledClass: 'cursor-not-allowed opacity-20',
            }}
            slidesOffsetAfter={isFullBleedAndCentered ? 0 : 12}
            slidesOffsetBefore={isFullBleedAndCentered ? 0 : 12}
            slidesPerView={
              isFullBleedAndCentered
                ? slidesPerViewMobile + 0.85
                : slidesPerViewMobile + 0.25
            }
            spaceBetween={12}
            breakpoints={{
              768: {
                slidesPerView: isFullBleedAndCentered
                  ? slidesPerViewTablet + 0.85
                  : slidesPerViewTablet + 0.25,
                spaceBetween: 20,
                slidesOffsetBefore: isFullBleedAndCentered ? 0 : 32,
                slidesOffsetAfter: isFullBleedAndCentered ? 0 : 32,
              },
              1024: {
                slidesPerView: isFullBleedAndCentered
                  ? slidesPerViewDesktop + 0.85
                  : slidesPerViewDesktop,
                spaceBetween: 20,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
              },
            }}
          >
            {products.map(({ product }, index) => {
              return (
                <SwiperSlide key={index}>
                  <ProductItem
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledStarRating={productItem?.enabledStarRating}
                    handle={product?.handle}
                    index={index}
                  />
                </SwiperSlide>
              );
            })}

            <div className="z-1 absolute top-[calc(50%-50px)] left-0 right-0">
              <div
                className={`relative mx-auto ${maxWidthClass} ${
                  isFullBleedAndCentered ? 'min-[90rem]:max-w-full' : ''
                }`}
              >
                {products.length > slidesPerViewDesktop && (
                  <div
                    className={`swiper-button-prev top-[calc(50%-1.6875rem)] left-0 !hidden !h-[3.5rem] !w-[3.5rem] rounded-full after:hidden lg:!flex ${
                      !isFullBleedAndCentered ? 'lg:-left-5 xl:-left-8' : ''
                    }`}
                  >
                    <Svg
                      className="w-8 text-white"
                      src="/svgs/chevron-left.svg#chevron-left"
                      title="Arrow Left"
                      viewBox="0 0 24 24"
                    />
                  </div>
                )}

                {products.length > slidesPerViewDesktop && (
                  <div
                    className={`swiper-button-next top-[calc(50%-1.6875rem)] right-0 !hidden !h-[3.5rem] !w-[3.5rem] rounded-full after:hidden lg:!flex ${
                      !isFullBleedAndCentered ? 'lg:-right-5 xl:-right-8' : ''
                    }`}
                  >
                    <Svg
                      className="w-8 text-white"
                      src="/svgs/chevron-right.svg#chevron-right"
                      title="Arrow Right"
                      viewBox="0 0 24 24"
                    />
                  </div>
                )}
              </div>
            </div>
          </Swiper>
        )}

        {button?.link?.text && (
          <div
            className={`${text?.textAlignmentMobile} ${text?.textAlignmentDesktop} px-contained-2x mt-10`}
          >
            <Link
              aria-label={button.link.text}
              className={`${button.style || 'btn-primary'}`}
              href={button.link.url}
              newTab={button.link.newTab}
            >
              {button.link.text}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

ProductsSlider.displayName = 'ProductsSlider';
ProductsSlider.Schema = Schema;

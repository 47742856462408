import { useState, useEffect } from 'react';
import { ProductDetailAccordion } from './ProductDetailAccordion';
import { useMediaPredicate } from 'react-media-hook';

export function ProductDetailAccordions({
  product,
  sku,
  productFeaturesSection,
}) {
  const [accordionsFromProduct, setAccordionsFromProduct] = useState(null);
  const [skuHtml, setSkuHtml] = useState(null);
  const isDesktop = useMediaPredicate('(min-width: 768px)');

  useEffect(() => {
    if (!sku) return;
    const _skuHtml = `<ul><li><strong>SKU:</strong> ${sku}</li></ul>`;
    setSkuHtml(_skuHtml);
  }, [sku]);

  useEffect(() => {
    if (!product?.descriptionHtml) return;
    const accordions = [];

    if (product?.descriptionHtml.includes('<p')) {
      const detailsIndex = product.descriptionHtml.indexOf('Details');

      const description = product.descriptionHtml.substring(
        product.descriptionHtml.indexOf('<p'),
        detailsIndex
      );

      const descriptionAccordion = {
        header: 'Description',
        body: description,
        defaultOpen: true,
      };
      accordions.push(descriptionAccordion);
      setAccordionsFromProduct(accordions);
    }

    if (product?.descriptionHtml.includes('<ul')) {
      const details = product.descriptionHtml.substring(
        product.descriptionHtml.indexOf('<ul'),
        product.descriptionHtml.indexOf('</ul>') + 5
      );

      const detailsAccordion = {
        header: 'Details',
        body: skuHtml ? details + skuHtml : details,
        defaultOpen: isDesktop,
      };
      accordions.push(detailsAccordion);
      setAccordionsFromProduct(accordions);
    }

    const hasMultipleLists =
      product?.descriptionHtml?.split('</ul>')?.[1] &&
      product?.descriptionHtml?.split('</ul>')?.[1]?.includes('Care');

    if (hasMultipleLists) {
      const bodyText = product?.descriptionHtml?.split('</ul>')?.[1];
      const trimmedBodyText = bodyText?.split('</p>')?.[1];

      const tertiaryAccordion = {
        header: 'Care & Composition',
        body: trimmedBodyText,
        defaultOpen: isDesktop,
      };
      accordions.push(tertiaryAccordion);
      setAccordionsFromProduct(accordions);
    }
  }, [product?.descriptionHtml, skuHtml, isDesktop]);

  return accordionsFromProduct?.length ? (
    <ul className="grid grid-cols-1 gap-0 border-t border-[var(--border)]">
      {accordionsFromProduct.map((accordion, index) => {
        return (
          <li key={index}>
            <ProductDetailAccordion accordion={accordion} />
          </li>
        );
      })}
      {productFeaturesSection || null}
    </ul>
  ) : null;
}

ProductDetailAccordions.displayName = 'ProductDetailAccordions';

import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import {
  useSettings,
  useCartAttributes,
  useCartUpdateAttributes,
  useCartRemoveAttributes,
} from '@backpackjs/storefront';
import Expand from 'react-expand-animated';

import { Svg } from '@snippets/Svg';

export function CartGiftNote({ isCartPage = false }) {
  const settings = useSettings();
  const { cartUpdateAttributes } = useCartUpdateAttributes();
  const { cartRemoveAttributes } = useCartRemoveAttributes();
  const cartAttributes = useCartAttributes();
  const { heading, text } = { ...settings?.cart?.giftNote };
  const textareaRef = useRef(null);

  const giftNoteAttribute = useMemo(() => {
    return cartAttributes.find((item) => item.key === 'Gift note');
  }, [cartAttributes]);

  const [note, setNote] = useState(giftNoteAttribute?.value);
  const [saveText, setSaveText] = useState('Save Note');
  const [open, setOpen] = useState(isCartPage || false);

  const saveGiftNote = useCallback(async () => {
    const attributes = [
      {
        key: 'Gift note',
        value: note,
      },
    ];

    if (note?.length > 0) {
      await cartUpdateAttributes({ attributes });
    } else {
      await cartRemoveAttributes({
        attributeKeys: ['Gift note'],
      });
    }

    if (isCartPage) {
      setSaveText('Saved');
    } else {
      setOpen(false);
    }
  }, [note]);

  const updatedGiftNote = useCallback((e) => {
    if (e.target.value.length > 200) return;
    setNote(e.target.value);
  }, []);

  const updateSaveTextOnNoteChange = useCallback(() => {
    const giftNoteAttribute = cartAttributes.find(
      (item) => item.key === 'Gift note'
    );
    const noteChanged = giftNoteAttribute?.value !== note;
    setSaveText(noteChanged ? 'Save Note' : 'Saved');
  }, [note, open]);

  const setFocusOnView = useCallback(() => {
    if (open) textareaRef.current.focus();
  }, [open]);

  useEffect(() => {
    setNote(giftNoteAttribute?.value);
  }, [giftNoteAttribute]);

  useEffect(() => {
    updateSaveTextOnNoteChange();
  }, [note, open]);

  useEffect(() => {
    setFocusOnView();
  }, [open]);

  return (
    <>
      <div className="p-5 pb-2">
        <div className="relative flex items-center justify-between">
          <p className="text-label min-h-[24px] text-[13px]">
            {heading || 'Gifting? Add a Gift Note!'}
          </p>
          {open ? (
            <>
              {!isCartPage && (
                <button
                  aria-label="Close Gift Note"
                  className="absolute top-[-6px] -right-2 p-2"
                  onClick={() => {
                    setOpen(false);
                  }}
                  type="button"
                >
                  <Svg
                    className="w-3 text-text"
                    src="/svgs/close.svg#close"
                    title="Close"
                    viewBox="0 0 24 24"
                  />
                </button>
              )}
            </>
          ) : (
            <button
              type="button"
              className="text-link-normal-sm relative bottom-1"
              onClick={() => setOpen(true)}
            >
              Add Note
            </button>
          )}
        </div>

        <Expand open={open} duration={300}>
          <p className="mb-4 mt-2 text-mediumGray">
            {text ||
              'Your message will be printed on the gift receipt, and prices will be hidden. Please do not use any special characters or emojis!'}
          </p>
          <textarea
            className="h-[100px] w-full resize-none border border-mediumGray p-3 text-sm"
            maxLength="200"
            onChange={updatedGiftNote}
            placeholder="Add a note (up to 200 character limit)"
            ref={textareaRef}
            value={note}
          />
          <div className="flex justify-end">
            <button
              type="button"
              className="text-link-normal-sm py-2 px-1"
              onClick={saveGiftNote}
            >
              {saveText}
            </button>
          </div>
        </Expand>
      </div>
      {!isCartPage && (
        <div className="px-5">
          <div className="border-b border-b-border" />
        </div>
      )}
    </>
  );
}

CartGiftNote.displayName = 'CartGiftNote';

import { useSettings } from '@backpackjs/storefront';

import { EmailSignup } from './EmailSignup';
import { FooterLegal } from './FooterLegal';
import { FooterMenu } from './FooterMenu';
import { FooterSocial } from './FooterSocial';
import { Link } from '../Link';
import { Svg } from '../Svg';

export function Footer() {
  const settings = useSettings();
  const { bgColor, textColor } = { ...settings?.footer };

  return (
    <footer
      className="px-contained py-6"
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <div className="lg-px-contained lg-py-contained border-t border-[var(--border)] max-lg:py-5 max-md:py-0">
        <div className="mx-auto flex w-full max-w-[60rem] flex-col justify-between md:flex-row">
          <FooterMenu textColor={textColor} />
          <div className="mb-3 w-full md:w-[18.75rem]">
            <EmailSignup />
            <FooterSocial />
          </div>
        </div>
      </div>

      <div className="flex w-full border-[var(--border)] md:justify-center md:border-t md:pt-4 lg:pt-6">
        <FooterLegal />
      </div>
    </footer>
  );
}

Footer.displayName = 'Footer';

import { Image } from '@snippets';

export function ProductImage({ media, title, handleMediaClick }) {
  const { image } = media;

  if (!image?.src) return null;

  return (
    <div
      className="relative cursor-zoom-in bg-offWhite"
      style={{ aspectRatio: 'var(--product-image-aspect-ratio)' }}
      onClick={handleMediaClick}
    >
      <Image
        src={image.src}
        alt={title}
        fill
        priority
        sizes="(min-width: 1440px) 65vw, (min-width: 1024px) 50vw, (min-width: 768px) 50vw, 100vw"
      />
    </div>
  );
}

ProductImage.displayName = 'ProductImage';

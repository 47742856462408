import { useCallback, useEffect, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { getProductSearchData } from './queries';

export function useSearchProductResults({ term, mounted = true }) {
  const settings = useSettings();

  const [allProducts, setAllProducts] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalProductResults, setTotalProductResults] = useState(null);
  const [productResults, setProductResults] = useState(null);
  const { productsEnabled } = { ...settings?.search?.results };

  const setProductsOnMount = useCallback(async () => {
    try {
      if (allProducts) return;
      const response = await fetch('/json/products-list.json');
      const list = await response.json();
      const listObj = list?.reduce((acc, item) => {
        acc[item.handle] = item;
        return acc;
      }, {});

      setAllProducts(listObj);
    } catch (error) {
      console.error(error.message);
    }
  }, [allProducts]);

  const searchProductsOnTermChange = useCallback(async () => {
    if (!allProducts) return;
    if (!term) {
      setProductResults([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const { products } = await getProductSearchData({
      term,
    });
    const validProducts = products
      ?.map(({ handle }) => allProducts[handle])
      ?.reduce((acc, item) => {
        if (item?.status !== 'ACTIVE' || item?.tags?.includes('hide-search'))
          return acc;
        return [...acc, item];
      }, []);

    setProductResults(validProducts);
    setTotalProductResults(validProducts?.length || 0);

    setLoading(false);
  }, [term, allProducts]);

  useEffect(() => {
    if (!mounted || !productsEnabled) return;
    setProductsOnMount();
  }, [mounted, productsEnabled]);

  useEffect(() => {
    searchProductsOnTermChange();
  }, [allProducts, term]);

  return { loading, productResults, totalProductResults };
}

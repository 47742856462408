import React from 'react';
import { Schema } from './Returns.schema';

export function Returns() {
  return (
    <div>
      <iframe
        id="nvo_frame"
        onLoad="onFrameLoad()"
        sandbox="allow-forms allow-popups allow-modals allow-same-origin allow-scripts allow-forms allow-top-navigation"
        scrolling="yes"
        seamless="seamless"
        src="https://shopifyzero.narvar.com/app_proxy/consumer_app?shid=8PEJ"
        innerhtml="Not Supported"
        style={{
          display: 'block',
          width: '100%',
          minHeight: '100vh',
          border: 'none',
        }}
      />
    </div>
  );
}

Returns.displayName = 'Returns';
Returns.Schema = Schema;

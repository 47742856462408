import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import equal from 'fast-deep-equal';

import { formatImageItemAsMediaItem } from '@utils';

export function useSelectedVariant({ product, isModal = false }) {
  const { query } = useRouter();

  const productPageReady = isModal ? !!product && product?.handle : !!product;
  const hasOneVariant = product?.variants?.length === 1;
  const isNonGroupedProductWithMultiColors =
    !product?.grouping?.isTransformed && product?.optionsMap?.Color?.length > 1;

  const productMedia = useMemo(() => {
    return (
      product?.media ||
      // if shopify media transforms is off, shape image item similar to media item
      product?.images.map((image) => {
        return formatImageItemAsMediaItem({ image, product });
      })
    );
  }, [product?.id]);

  const [selectedVariant, setSelectedVariant] = useState(
    productPageReady && hasOneVariant ? product?.variants[0] : null
  );
  const [selectedOptions, setSelectedOptions] = useState(
    productPageReady && hasOneVariant
      ? product?.variants[0].selectedOptionsMap
      : null
  );
  const [selectedMedia, setSelectedMedia] = useState(
    isNonGroupedProductWithMultiColors || !productPageReady
      ? null
      : productMedia
  );

  // set initial selected options on mount unless has one variant
  const setInitialSelectedOptionsOnProductMount = useCallback(() => {
    if (!productPageReady) {
      setSelectedOptions(null);
      return;
    }

    const selectedVariantFromOptions = product?.variants.find(
      ({ selectedOptionsMap }) => {
        return equal(selectedOptions, selectedOptionsMap);
      }
    );

    const params = new URLSearchParams(window.location.search);
    const queriedVariantId =
      params.get('variant') || selectedVariantFromOptions?.legacyResourceId;
    let queriedVariant = null;
    if (queriedVariantId) {
      queriedVariant = product?.variants.find(
        ({ legacyResourceId }) => legacyResourceId === queriedVariantId
      );
    }
    if (queriedVariant) {
      setSelectedOptions(queriedVariant.selectedOptionsMap);
    } else {
      const firstAvailableVariant = product?.variants.find(
        ({ availableForSale }) => availableForSale
      );

      if (firstAvailableVariant) {
        setSelectedOptions(firstAvailableVariant.selectedOptionsMap);
      } else {
        // If no variants are available, fall back to the first variant
        setSelectedOptions(product?.variants[0]?.selectedOptionsMap || null);
      }
    }
  }, [query.handle, product?.id]);

  // set selected variant from selected options unless has one variant
  const setSelectedVariantFromSelectedOptions = useCallback(() => {
    if (!productPageReady || !selectedOptions) {
      setSelectedVariant(null);
      return;
    }

    const selectedVariantFromOptions = product?.variants.find(
      ({ selectedOptionsMap }) => {
        return equal(selectedOptions, selectedOptionsMap);
      }
    );

    // we want to keep selectedVariant valid instead of null to prevent modal content from flickering
    if (selectedVariantFromOptions && isModal) {
      setSelectedVariant(selectedVariantFromOptions);
    } else if (!isModal) {
      setSelectedVariant(selectedVariantFromOptions || null);
    }
  }, [query.handle, product?.id, selectedOptions, selectedVariant?.id]);

  // set variant url param on selected variant change unless has one variant
  const setVariantUrlParamOnSelectedVariantChange = useCallback(() => {
    if (!productPageReady || isModal) return;

    if (product?.variants?.length === 1 || !selectedVariant) return;

    const { origin, search } = window.location;

    const params = new URLSearchParams(search);
    params.set('variant', selectedVariant.legacyResourceId);

    const updatedUrl = `${origin}/products/${selectedVariant.product?.handle}?${params}`;

    window.history.replaceState(window.history.state, '', updatedUrl);
  }, [isModal, query.handle, product?.id, selectedVariant?.id]);

  // set selected media on selected variant change
  const setSelectedMediaOnSelectedVariantChange = useCallback(() => {
    if (!productPageReady) {
      setSelectedMedia(null);
      return;
    }

    // filter media by color via possible alt tag if product is not grouped and has multiple colors
    if (product?.optionsMap.Color && selectedVariant) {
      const colorOptions = product?.optionsMap.Color.map((color) =>
        color.toLowerCase()
      );
      const selectedColor =
        selectedVariant.selectedOptionsMap.Color?.toLowerCase();
      setSelectedMedia(
        productMedia.filter((item) => {
          const alt = item.alt?.trim().toLowerCase();
          return alt === selectedColor || !colorOptions.includes(alt);
        })
      );
    } else {
      setSelectedMedia(productMedia);
    }
  }, [
    query.handle,
    product?.id,
    productMedia,
    selectedMedia,
    selectedVariant?.id,
  ]);

  useEffect(() => {
    setInitialSelectedOptionsOnProductMount();
  }, [product?.id]);

  useEffect(() => {
    setSelectedVariantFromSelectedOptions();
  }, [selectedOptions]);

  useEffect(() => {
    setVariantUrlParamOnSelectedVariantChange();
  }, [selectedVariant?.id]);

  useEffect(() => {
    setSelectedMediaOnSelectedVariantChange();
  }, [selectedVariant?.id]);

  return {
    selectedOptions,
    selectedVariant,
    setSelectedOptions,
    selectedMedia,
  };
}

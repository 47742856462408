import { useCallback, useState } from 'react';
import { useCustomer, useSettings } from '@backpackjs/storefront';

export function BackInStockModal({ selectedVariant }) {
  const customer = useCustomer();
  const settings = useSettings();
  const variantId = selectedVariant?.legacyResourceId;

  const [email, setEmail] = useState(customer?.email || '');
  const [success, setSuccess] = useState(false);
  const { heading, headingSuccess, subtext, subtextSuccess, submitText } = {
    ...settings?.product?.backInStock,
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        if (!email || !variantId) return;

        const endpoint = '/api/klaviyo';
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'backInStockSubscribe',
            email,
            variant: variantId,
          }),
        };

        const response = await fetch(endpoint, options);

        if (!response.ok) {
          throw new Error('No data returned from subscribe');
        }

        setSuccess(true);
      } catch (error) {
        console.error(`subscribe error: ${error.message}`);
        throw error;
      }
    },
    [email]
  );

  return (
    <div className="flex flex-col gap-2 border border-[#dddddd] px-3 py-4 md:p-6">
      <div>
        <h2 className="text-lg font-semibold tracking-normal">
          {success ? headingSuccess : heading}
        </h2>
        {subtext && (
          <p className="mt-2 text-sm font-medium">
            {success ? subtextSuccess : subtext}
          </p>
        )}
      </div>

      {!success && (
        <form className="flex w-full flex-col" onSubmit={handleSubmit}>
          <input
            className="input-text w-full text-text"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email..."
            required
            type="email"
            value={email}
          />

          <button
            aria-label="Notify Me"
            className="btn-primary mt-3 h-[42px] w-full normal-case md:h-[46px]"
            type="submit"
          >
            {submitText}
          </button>
        </form>
      )}
    </div>
  );
}

BackInStockModal.displayName = 'BackInStockModal';

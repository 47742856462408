const extractId = (str) => {
  const parts = str.split('/');
  return parts[parts.length - 1];
};

/**
 * Transforms a list of items into a cart object, calculating various totals and preparing data.
 * @param {Array} items - The items to be transformed into the cart.
 * @return {Object} The transformed cart object.
 */
export const transformItemsToCart = (items) => {
  if (!Array.isArray(items)) {
    throw new Error('Invalid input: items should be an array.');
  }

  const initialCart = {
    attributes: {},
    cartLevelDiscountApplications: [],
    checkoutChargeAmount: '0',
    currency: {},
    discountApplications: [],
    discounts: [],
    isEmpty: items.length === 0,
    itemCount: items.length,
    items: [],
    itemsSubtotalPrice: '0',
    note: 'Hello this is a note',
    originalTotalPrice: '0',
    requiresShipping: false,
    taxesIncluded: false,
    totalDiscount: '0',
    totalPrice: '0',
    totalWeight: 0,
  };

  // If there are no items, return the initial cart
  if (items.length === 0) {
    return initialCart;
  }

  // Set the currency once
  initialCart.currency.currencyCode =
    items[0].estimatedCost.totalAmount.currencyCode;

  const transformedCart = items.reduce((cart, item) => {
    const itemSubtotal = parseFloat(item.estimatedCost.totalAmount.amount);
    const itemWeight = item.variant.weight;

    cart.items.push({
      id: extractId(item.variant.id),
      title: item.variant.product.title,
      quantity: item.quantity,
      price: item.variant.priceV2.amount,
      image: item.variant.image.src,
    });

    cart.itemsSubtotalPrice = (
      parseFloat(cart.itemsSubtotalPrice) + itemSubtotal
    ).toFixed(2);
    cart.originalTotalPrice = cart.itemsSubtotalPrice;
    cart.totalWeight += itemWeight;
    cart.requiresShipping =
      cart.requiresShipping || item.variant.requiresShipping;

    return cart;
  }, initialCart);

  // Placeholder for additional calculations (discounts, total price, etc.)

  return transformedCart;
};

export function processCartObject(cartObject) {
  // Extract and repeat IDs using map and flat
  const ids = cartObject.items
    .map((item) => Array(item.quantity).fill(Number(item.id)))
    .flat();

  // Convert the object to a JSON string
  const jsonString = JSON.stringify(cartObject);

  // Encode the JSON string in Base64
  // const shopify = btoa(unescape(encodeURIComponent(jsonString)));
  const shopify = '';

  // Return the result
  return {
    cart: ids,
  };
}

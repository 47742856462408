export function Schema() {
  return {
    label: 'Store Reviews',
    key: 'store-reviews',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/2597/4944/files/store-reviews-preview.png?v=1680903090',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: '7Diamond Reviews',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'text',
      },
    ],
  };
}

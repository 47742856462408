import React, { useState } from 'react';

import { Link } from '../Link';
import { Svg } from '../Svg';

export function MobileMenuItem({ item, textColor }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-b-border">
      <button
        aria-label={
          isOpen ? `Close ${item.title} menu` : `Open ${item.title} menu`
        }
        className="flex h-11 w-full items-center justify-between py-3"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <h5 className="text-nav">{item.title}</h5>

        <div className="mr-2">
          {isOpen ? (
            <Svg
              className={`w-[0.875rem] text-${textColor}`}
              src="/svgs/minus.svg#minus"
              title="Minus"
              viewBox="0 0 12 13"
            />
          ) : (
            <Svg
              className={`w-[0.875rem] text-${textColor}`}
              src="/svgs/plus.svg#plus"
              title="Plus"
              viewBox="0 0 12 13"
            />
          )}
        </div>
      </button>

      <ul
        className={`flex-col items-start gap-2 px-2 pb-6 ${
          isOpen ? 'flex' : 'hidden'
        }`}
      >
        {item.links?.map(({ link }, linkIndex) => {
          return (
            <li className="text-sm" key={linkIndex}>
              <Link
                aria-label={link?.text}
                className="hover-text-underline"
                href={link?.url}
                newTab={link?.newTab}
              >
                {link?.text}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

MobileMenuItem.displayName = 'MobileMenuItem';

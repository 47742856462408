import { useMemo } from 'react';
import {
  useProductByHandle,
  useProductInventoryByHandle,
} from '@backpackjs/storefront';
import equal from 'fast-deep-equal';
import { useGlobalContext } from '@contexts';

import { Image, Link } from '@snippets';

function ColorOptionValue({ isSelected, value, disabled }) {
  const swatchImageUrl = `https://cdn.shopify.com/s/files/1/2597/4944/files/${value}_80x.png`;

  return (
    <div
      className={`relative flex ${
        disabled
          ? ' after:absolute after:top-0 after:right-0 after:bottom-0 after:left-0 after:z-[13] after:m-auto after:h-[calc(100%)] after:w-[4px] after:origin-center after:rotate-45 after:border-l-[1.5px] after:border-r-[1.5px] after:border-white after:bg-black after:content-[""]'
          : ''
      }`}
    >
      <div
        className={`absolute z-[12] flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border  bg-transparent transition first-letter:relative md:h-10 md:w-10 
`}
      />
      {disabled && (
        <div className="absolute z-[11]  h-8 w-8 rounded-full border bg-white opacity-20  md:h-10 md:w-10" />
      )}
      <div
        className={`relative flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border transition md:h-10 md:w-10 `}
      >
        <Image
          alt={value}
          className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover ${
            disabled ? 'opacity-80' : ''
          }`}
          height="40"
          src={swatchImageUrl}
          width="40"
        />
        <div
          className={`absolute top-1/2 left-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-full border-white transition-[border-width] duration-100 ${
            isSelected ? 'border-[3px]' : 'border-0'
          }
          ${isSelected && disabled ? 'z-10 bg-disabled ' : ''}
          `}
        />
      </div>
    </div>
  );
}
export function ProductColorOptionValue({
  groupingProductsMapByColor,
  isModal,
  isSelected,
  name,
  product,
  selectedOptions,
  setSelectedOptions,
  value,
}) {
  const {
    actions: { updateModalProduct },
  } = useGlobalContext();

  const newSelectedOptions = useMemo(() => {
    return selectedOptions
      ? {
          ...selectedOptions,
          [name]: value,
        }
      : null;
  }, [name, selectedOptions, value]);

  const selectedVariantFromOptions = useMemo(() => {
    if (!newSelectedOptions || !groupingProductsMapByColor) return null;
    const variant = groupingProductsMapByColor[
      newSelectedOptions.Color
    ]?.variants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
    return (
      variant ||
      groupingProductsMapByColor[newSelectedOptions.Color]?.variants[0]
    );
  }, [groupingProductsMapByColor, newSelectedOptions]);

  const { product: newProduct } = useProductByHandle({
    handle: selectedVariantFromOptions?.product?.handle,
  });

  const isFromGrouping = product.grouping?.isTransformed;

  const url = useMemo(() => {
    if (!isFromGrouping) return null;
    if (!selectedVariantFromOptions) return null;

    let params = '';
    if (typeof window !== 'undefined') {
      const { search } = window.location;
      params = new URLSearchParams(search);
      params.set('variant', selectedVariantFromOptions.legacyResourceId);
    }

    return `/products/${selectedVariantFromOptions.product.handle}?${params}`;
  }, [isFromGrouping, newSelectedOptions, selectedVariantFromOptions]);

  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedVariantFromOptions?.product?.handle,
    withInventory: false,
  });
  const optionValueIsAvailable =
    inventory?.variants?.[selectedVariantFromOptions?.id]?.availableForSale ||
    false;
  const disabled = !inventoryFetched || !selectedVariantFromOptions;
  const isUnavailable = !optionValueIsAvailable && !disabled;

  return isFromGrouping && !isModal ? (
    <Link
      aria-label={value}
      href={url}
      onClick={(e) => {
        if (isSelected) e.preventDefault();
      }}
      scroll={false}
    >
      <ColorOptionValue
        isSelected={isSelected}
        value={value}
        disabled={isUnavailable}
      />
    </Link>
  ) : (
    <button
      aria-label={value}
      onClick={() => {
        if (isSelected) return;
        setSelectedOptions(newSelectedOptions);
        if (isModal && newProduct) {
          updateModalProduct(newProduct);
        }
      }}
      type="button"
    >
      <ColorOptionValue
        isSelected={isSelected}
        value={value}
        disabled={isUnavailable}
      />
    </button>
  );
}

ProductColorOptionValue.displayName = 'ProductColorOptionValue';
ColorOptionValue.displayName = 'ColorOptionValue';

import { useCallback, useMemo } from 'react';
import { useCustomer } from '@backpackjs/storefront';
import _xorBy from 'lodash.xorby';

import { useWishlistContext } from '@contexts';
import { useWishlist } from '@hooks';
import { Svg } from '@snippets';

export function Favorite({ selectedVariant, isPdp }) {
  const customer = useCustomer();
  const { updateWishlist } = useWishlist();
  const {
    state: { anonymousWishlist, wishlist, wishlistId: lid, wishlistEmail },
    actions: {
      updateAnonymousWishlist: setAnonymousWishlist,
      updateWishlist: setWishlist,
    },
  } = useWishlistContext();

  const isWishlisted = useMemo(() => {
    if (!Array.isArray(wishlist)) return false;
    const _selectedResourceId =
      parseInt(selectedVariant?.legacyResourceId, 10) || null;
    return wishlist?.find(({ epi }) => epi === _selectedResourceId);
  }, [wishlist, selectedVariant]);

  const toggleWishlist = useCallback(
    async (event) => {
      event.preventDefault();

      const item = {
        epi: parseInt(selectedVariant.legacyResourceId, 10),
        empi: parseInt(selectedVariant.product.legacyResourceId, 10),
        du: `https://${process.env.NEXT_PUBLIC_SEO_SHOPIFY_DOMAIN}/products/${selectedVariant.product.handle}`,
      };

      const updatedWishlist = _xorBy(wishlist, [item], 'epi');
      const updatedAnonymousWishlist = _xorBy(
        customer?.email ? wishlist : anonymousWishlist,
        [item],
        'epi'
      );
      setWishlist(updatedWishlist);

      if (!customer?.email) {
        setAnonymousWishlist(updatedAnonymousWishlist);
      }

      await updateWishlist({
        params: {
          lid,
          email: wishlistEmail,
          add: isWishlisted ? [] : [item],
          remove: isWishlisted ? [item] : [],
        },
      });
    },
    [selectedVariant, wishlist, isWishlisted, customer]
  );

  return (
    <button
      aria-label={
        isWishlisted
          ? `Add ${selectedVariant?.product?.title || ''} to favorites.`
          : `Remove ${selectedVariant?.product?.title || ''} from favorites.`
      }
      className={ isPdp ? "flex items-center gap-2" : "group absolute top-1 right-1 flex items-center p-2" }
      onClick={toggleWishlist}
      type="submit"
    >
      {isWishlisted ? (
        <>
          { !isPdp && (
            <span className="mr-2 text-[13px] leading-none opacity-0 duration-300 group-hover:opacity-100 max-md:hidden">
              delete
            </span>
          )}
          <Svg
            className={isPdp ? "w-5 fill-black" : "w-4 fill-black" }
            viewBox="0 0 24 24"
            alt="Favorite"
            src="/svgs/heart-filled.svg#heart-filled"
          />
          { isPdp && (
            <span className="text-nav text-underline">
              Remove from Wishlist
            </span>
          )}
        </>
      ) : (
        <>
          { !isPdp && (
            <span className="mr-2 text-[13px] leading-none opacity-0 duration-300 group-hover:opacity-100 max-md:hidden">
              save
            </span>
          )}
          <Svg
            className={isPdp ? "w-5" : "w-4" }
            viewBox="0 0 24 24"
            alt="Favorite"
            src="/svgs/heart.svg#heart"
          />
          { isPdp && (
            <span className="text-nav text-underline">
              Add to wishlist
            </span>
          )}
        </>
      )}
    </button>
  );
}

Favorite.displayName = 'Favorite';

import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { MobileMenuItem } from './MobileMenuItem';

export function FooterMenu({ textColor }) {
  const settings = useSettings();
  const { menuItems } = { ...settings?.footer?.menu };

  return (
    <>
      {/* mobile */}
      <ul className="flex-1 text-current md:hidden">
        {menuItems?.map((item, menuIndex) => {
          return (
            <li key={menuIndex}>
              <MobileMenuItem item={item} textColor={textColor} />
            </li>
          );
        })}
      </ul>

      {/* desktop */}
      <ul className="hidden w-full flex-1 flex-wrap justify-between text-current md:flex">
        {menuItems?.map(({ title, links }, menuIndex) => {
          return (
            <li
              className="mb-4 w-[33.33%] pr-4 lg:mb-0 lg:w-[25%]"
              key={menuIndex}
            >
              <h5 className="text-nav mb-4">{title}</h5>

              <ul className="flex flex-col items-start gap-5">
                {links?.map(({ link }, linkIndex) => {
                  return (
                    <li key={linkIndex} className="text-sm">
                      <Link
                        aria-label={link?.text}
                        className="hover-text-underline"
                        href={link?.url}
                        newTab={link?.newTab}
                      >
                        {link?.text}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </>
  );
}

FooterMenu.displayName = 'FooterMenu';

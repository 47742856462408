import { useEffect, useState } from 'react';
import { fetchProductsFromHandles } from '@utils';
import { ProductOption } from './ProductOption';
import { FitToggles } from './FitToggles';

export function ProductOptions({
  isModal,
  product,
  selectedOptions,
  selectedVariant,
  setSelectedOptions,
}) {
  const [groupingProductsMapByColor, setGroupingProductsMapByColor] =
    useState(null);

  useEffect(() => {
    if (!product?.grouping?.isTransformed) {
      setGroupingProductsMapByColor({
        [product?.optionsMap?.Color?.[0]]: product,
      });
      return;
    }

    const fetchProductsForMap = async () => {
      const products = await fetchProductsFromHandles(
        product.grouping.products
      );
      setGroupingProductsMapByColor(
        products?.reduce((acc, groupProduct) => {
          const color =
            groupProduct?.optionsMap?.Color?.[0] ||
            groupProduct?.optionsMap['New Colors']?.[0];
          if (!color) return acc;
          return {
            ...acc,
            [color]: groupProduct,
          };
        }, {})
      );
    };
    fetchProductsForMap();
  }, [product?.id]);

  let groupingProductsMapByNewColor = null;
  let groupingProductsMapByStandardColor = null;
  if (
    groupingProductsMapByColor &&
    typeof groupingProductsMapByColor === 'object'
  ) {
    ({ groupingProductsMapByNewColor, groupingProductsMapByStandardColor } =
      Object.entries(groupingProductsMapByColor).reduce(
        (acc, [key, value]) => {
          if (
            value.tags &&
            Array.isArray(value.tags) &&
            value.tags.includes('color-group::new')
          ) {
            acc.groupingProductsMapByNewColor[key] = { id: value.id };
          } else {
            acc.groupingProductsMapByStandardColor[key] = { id: value.id };
          }
          return acc;
        },
        {
          groupingProductsMapByNewColor: {},
          groupingProductsMapByStandardColor: {},
        }
      ));
  }

  const options = product?.grouping?.options || product?.options;

  return (
    <>
      {options?.map(({ name, values }, index) => (
        <ProductOption
          isModal={isModal}
          key={`${name}-${index}`}
          name={name}
          groupingProductsMapByColor={groupingProductsMapByColor}
          groupingProductsMapByNewColor={groupingProductsMapByNewColor}
          groupingProductsMapByStandardColor={
            groupingProductsMapByStandardColor
          }
          product={product}
          selectedOptions={selectedOptions}
          selectedVariant={selectedVariant}
          setSelectedOptions={setSelectedOptions}
          values={values}
          hideDuplicate={product?.toggles}
        />
      ))}
      {product?.toggles && (
        <FitToggles
          product={product}
          selectedOptions={selectedOptions}
          groupingProductsMapByColor={groupingProductsMapByColor}
        />
      )}
    </>
  );
}

ProductOptions.displayName = 'ProductOptions';

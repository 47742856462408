import { Image, Link, Svg } from '@snippets';

export function SubmenuSidebar({
  handleCloseSidebar,
  handleSubmenuSidebar,
  submenuSidebarContent,
  nestedSidebarContent,
}) {
  const { menuItem } = { ...nestedSidebarContent };
  const { link, sublinks, imagesMobile } = {
    ...submenuSidebarContent,
  };

  return (
    <div
      data-comp={SubmenuSidebar.displayName}
      className={`scrollbar-hide absolute left-0 top-0 z-[1] h-full w-full bg-background ${
        submenuSidebarContent ? 'visible' : 'invisible'
      }`}
    >
      <div className="scrollbar-hide h-full w-full overflow-y-auto">
        <button
          aria-label="Go back to main menu"
          className="sticky top-0 z-[1] flex w-full items-center justify-between gap-3 border-b border-b-border bg-background pb-2"
          onClick={() => handleSubmenuSidebar(null)}
          type="button"
        >
          <Svg
            className="w-3 pb-[2px] text-[#979797]"
            src="/svgs/arrow-left.svg#arrow-left"
            title="Arrow Left"
            viewBox="0 0 24 24"
          />

          <h3 className="text-nav flex-1 text-left font-bold">
            {menuItem?.text}
          </h3>
        </button>

        <div className="px-0 pt-4">
          <ul className="mb-8 flex flex-col gap-3">
            <Link
              aria-label={link?.text}
              className="text-nav flex-1 text-left capitalize"
              href={link?.url}
              newTab={link?.newTab}
              onClick={handleCloseSidebar}
              type={link?.type}
            >
              All {link?.text}
            </Link>
            {sublinks?.map(({ link }, index) => {
              return (
                <li key={index}>
                  <Link
                    aria-label={link?.text}
                    className="text-nav flex-1 text-left capitalize"
                    href={link?.url}
                    newTab={link?.newTab}
                    onClick={handleCloseSidebar}
                    type={link?.type}
                  >
                    {link?.text}
                  </Link>
                </li>
              );
            })}
          </ul>

          {imagesMobile?.length > 0 && (
            <ul className="mt-24 grid grid-cols-2 gap-2">
              {imagesMobile?.map(({ alt, caption, image, link }, index) => {
                return (
                  <li key={index} className="h-full w-full">
                    <Link
                      aria-label={caption}
                      href={link?.url}
                      newTab={link?.newTab}
                      onClick={handleCloseSidebar}
                      type={link?.type}
                      className="h-full w-full"
                    >
                      <div className="relative aspect-[3/4] w-full overflow-hidden bg-offWhite">
                        {image?.src && (
                          <Image
                            alt={alt}
                            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                            crop="center"
                            height={Math.floor(768 / (3 / 4))}
                            src={image.src}
                            width="768"
                          />
                        )}
                      </div>

                      <p className="text-nav mt-1 capitalize leading-[1.375rem]">
                        {caption}
                      </p>
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

SubmenuSidebar.displayName = 'SubmenuSidebar';

import Head from 'next/head';

export function ArticleHead({ article = {}, pageUrl }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BlogPosting',
            '@id': pageUrl,
            mainEntityOfPage: pageUrl,
            headline: article.seo?.title || '',
            name: article.seo?.title || '',
            description: article.seo?.description || '',
            datePublished: article.article?.publishedAt || '',
            dateModified: article.article?.createdAt || '',
            author: [
              {
                '@type': 'Person',
                name: article.article?.author || '',
              },
            ],
            publisher: {
              '@type': 'Organization',
              '@id': process.env.SITE_URL,
              name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
              logo: {
                '@type': 'ImageObject',
                '@id': process.env.NEXT_PUBLIC_SITE_LOGO || '',
                url: process.env.NEXT_PUBLIC_SITE_LOGO || '',
              },
            },
            image: {
              '@type': 'ImageObject',
              '@id': article.seo?.image || '',
              url: article.seo?.image || '',
            },
            url: pageUrl,
            isPartOf: {
              '@type': 'Blog',
              '@id': `${process.env.SITE_URL}/blogs/${article.blogs?.[0]?.handle}`,
              name: article.blogs?.[0]?.title,
              publisher: {
                '@type': 'Organization',
                '@id': process.env.SITE_URL,
                name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
              },
            },
          }),
        }}
      />
    </Head>
  );
}

ArticleHead.displayName = 'ArticleHead';

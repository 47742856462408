import { Image } from '../../snippets';

export function PressSliderThumb({ alt, image, isActive, onClick }) {
  return (
    <div className="relative flex w-full max-w-[8rem] justify-center pb-2">
      <button
        className="relative aspect-[3/2] w-full overflow-hidden"
        onClick={onClick}
        type="button"
      >
        <div className="absolute left-0 top-1/2 aspect-square w-full -translate-y-1/2">
          {image?.src && (
            <Image
              alt={alt}
              className={`object-contain transition ${
                isActive ? 'opacity-100' : 'opacity-30'
              }`}
              fill
              sizes="200px"
              src={image.src}
            />
          )}
        </div>
      </button>

      <div
        className={`absolute bottom-0 left-0 h-px w-full origin-center bg-current transition ${
          isActive ? 'scale-100' : 'scale-0'
        }`}
      />
    </div>
  );
}

PressSliderThumb.displayName = 'PressSliderThumb';

import { useMemo } from 'react';
import {
  useCartCount,
  useCartTotals,
  useSettings,
} from '@backpackjs/storefront';

export function FreeShippingMeter() {
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const settings = useSettings();
  const {
    enabled,
    minCartSpend,
    progressBarColor,
    progressMessage,
    qualifiedMessage,
  } = { ...settings?.cart?.freeShipping };
  const showMeter = enabled && cartCount > 0 && minCartSpend > 0;

  const progress = useMemo(() => {
    if (!showMeter) return null;

    const total = parseFloat(cartTotals.total);
    const fraction = total / minCartSpend;
    const remainder = minCartSpend - total;

    if (fraction >= 1) {
      return {
        percent: 100,
        message: qualifiedMessage,
      };
    }

    return {
      percent: fraction * 100,
      message: progressMessage?.replace(
        '{{amount}}',
        `<b>$${remainder.toFixed(2).replace('.00', '')}</b>`
      ),
    };
  }, [
    cartTotals.total,
    minCartSpend,
    progressMessage,
    qualifiedMessage,
    showMeter,
  ]);

  return showMeter ? (
    <>
      <div className="py-4 px-5">
        {progress?.message && (
          <p
            className="text-xs"
            dangerouslySetInnerHTML={{ __html: progress.message }}
          />
        )}
      </div>
      <div className="h-1 w-full overflow-hidden bg-lightGray">
        <div
          className="h-full w-full origin-left transition"
          style={{
            transform: `scaleX(${progress.percent}%)`,
            backgroundColor: progressBarColor,
          }}
        />
      </div>
    </>
  ) : null;
}

FreeShippingMeter.displayName = 'FreeShippingMeter';

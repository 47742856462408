import { useMemo } from 'react';
import equal from 'fast-deep-equal';

import { sizeMap } from '@utils';

export function ProductOptionValue({
  inventory,
  isLoading,
  isSelected,
  isSize,
  name,
  product,
  selectedOptions,
  setSelectedOptions,
  value,
}) {
  const newSelectedOptions = useMemo(() => {
    return selectedOptions
      ? {
          ...selectedOptions,
          [name]: value,
        }
      : null;
  }, [name, selectedOptions, value]);

  const variantFromOptionValue = useMemo(() => {
    if (!newSelectedOptions) return null;
    return product.variants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
  }, [newSelectedOptions, product.variants]);

  const optionValueIsAvailable =
    inventory?.variants?.[variantFromOptionValue?.id]?.availableForSale ||
    false;
  const disabled = isLoading || !variantFromOptionValue;
  const hasUnavailableStyle = !optionValueIsAvailable && !disabled;

  return (
    <button
      aria-label={value}
      className={`relative flex h-8 min-w-[2rem] items-center justify-center overflow-hidden rounded-[10%] border border-text p-0.5 text-xs transition md:h-10 md:min-w-[2.5rem] ${
        disabled
          ? 'bg-[linear-gradient(to_top_left,_transparent_calc(50%_-_0.0325rem),_#000,_transparent_calc(50%_+_0.09375rem))]'
          : ''
      }`}
      disabled={disabled}
      onClick={() => {
        if (isSelected || !newSelectedOptions) return;
        setSelectedOptions(newSelectedOptions);
      }}
      type="button"
    >
      <div
        className={`flex h-full w-full items-center justify-center rounded-[10%] border-white p-1 transition-[border-width] duration-100 ${
          isSelected ? 'bg-black font-semibold text-white' : ''
        }`}
      >
        {isSize ? sizeMap(value) : value}
      </div>

      {hasUnavailableStyle && (
        <div className="absolute h-full w-[100%] border-black bg-[linear-gradient(to_top_left,_transparent_calc(50%_-_0.0325rem),_#000,_transparent_calc(50%_+_0.09375rem))] " />
      )}
    </button>
  );
}

ProductOptionValue.displayName = 'ProductOptionValue';

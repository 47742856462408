export default {
  label: 'Register',
  name: 'register',
  component: 'group',
  fields: [
    {
      label: 'Register Heading',
      name: 'heading',
      component: 'text',
    },
    {
      label: 'Login Text (mobile)',
      name: 'loginText',
      component: 'text',
    },
    {
      label: 'Login Link Text (mobile)',
      name: 'loginLinkText',
      component: 'text',
    },
  ],
  defaultValue: {
    heading: 'Create Account',
    loginText: 'Already have an account?',
    loginLinkText: 'Sign In',
  },
};

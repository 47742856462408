import { COLORS } from './common';

export default {
  label: 'Header',
  name: 'header',
  component: 'group',
  description: 'Menu, promobar',
  fields: [
    {
      label: 'Promobar',
      name: 'promobar',
      component: 'group',
      description: 'Enable, messages, colors, slider settings',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Display Arrow Buttons',
          name: 'displayArrowButtons',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'Autohide',
          name: 'autohide',
          component: 'toggle',
          description:
            'Hides promobar after scrolling away from top of the page',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Autoplay Delay',
          name: 'delay',
          component: 'number',
          description: 'Delay between transitions (in ms)',
          defaultValue: 5000,
        },
        {
          label: 'Speed',
          name: 'speed',
          component: 'number',
          description: 'Duration of transition between slides (in ms)',
          defaultValue: 500,
        },
        {
          label: 'Background Color',
          name: 'bgColor',
          component: 'select',
          options: COLORS,
          defaultValue: 'var(--primary)',
        },
        {
          label: 'Text Color',
          name: 'color',
          component: 'select',
          options: COLORS,
          defaultValue: 'var(--white)',
        },
        {
          label: 'Messages',
          name: 'messages',
          component: 'group-list',
          itemProps: {
            label: '{{item.message}}',
          },
          fields: [
            {
              label: 'Message',
              name: 'message',
              component: 'textarea',
            },
            {
              label: 'Link (optional)',
              name: 'link',
              component: 'link',
              description: 'Link wrapping entire message',
            },
          ],
          defaultItem: {
            message: 'Free shipping on orders over $100. Shop Now',
            link: { url: '/', text: '' },
          },
        },
      ],
    },
    {
      label: 'Menu',
      name: 'menu',
      component: 'group',
      description: 'Menu items, products slider, links',
      fields: [
        {
          label: 'Menu Items',
          name: 'menuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.menuItem.text}}',
          },
          fields: [
            {
              label: 'Menu Item',
              name: 'menuItem',
              component: 'link',
            },
            {
              label: 'Submenu Links',
              name: 'links',
              component: 'group-list',
              itemProps: {
                label: '{{item.link.text}}',
              },
              fields: [
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                },
                {
                  label: 'Submenu Sub-Links',
                  name: 'sublinks',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.link.text}}',
                  },
                  fields: [
                    {
                      label: 'Link',
                      name: 'link',
                      component: 'link',
                    },
                  ],
                },
                {
                  label: 'Images for Mobile',
                  name: 'imagesMobile',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.caption}}',
                  },
                  fields: [
                    {
                      label: 'Image Alt',
                      name: 'alt',
                      component: 'text',
                      description: 'Brief description of image',
                      defaultValue: 'Do Not Leave Blank',
                    },
                    {
                      label: 'Image',
                      name: 'image',
                      component: 'image',
                    },
                    {
                      label: 'Caption',
                      name: 'caption',
                      component: 'text',
                    },
                    {
                      label: 'Link',
                      name: 'link',
                      component: 'link',
                    },
                  ],
                  defaultItem: {},
                },
                {
                  label: 'Images for Desktop',
                  name: 'images',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.caption}}',
                  },
                  fields: [
                    {
                      label: 'Image Alt',
                      name: 'alt',
                      component: 'text',
                      description: 'Brief description of image',
                      defaultValue: 'Do Not Leave Blank',
                    },
                    {
                      label: 'Image',
                      name: 'image',
                      component: 'image',
                    },
                    {
                      label: 'Caption',
                      name: 'caption',
                      component: 'text',
                    },
                    {
                      label: 'Link',
                      name: 'link',
                      component: 'link',
                    },
                  ],
                  defaultItem: {},
                },
              ],
              defaultItem: {},
            },
          ],
          defaultItem: {
            menuItem: { text: 'Shop', url: '/collections/all' },
          },
        },
        {
          label: 'Addtional Links',
          name: 'links',
          description: 'Visible only in menu sidebar',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
        },

        {
          label: 'Hide Collection Links',
          name: 'hideCollectionLinks',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },

        // header.collectionLinksMen -> Core, Restoration ..
        {
          label: "Shop By Men's Collection Links",
          name: 'collectionLinksMen',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
          defaultItem: {},
        },

        // header.collectionLinksWomen -> Core, Restoration ..
        {
          label: "Shop By Women's  Collection Links",
          name: 'collectionLinksWomen',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
          defaultItem: {},
        },
      ],
    },
  ],
};

const image = {
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      label: 'Image Alt',
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Image (tablet/desktop)',
      name: 'imageDesktop',
      component: 'image',
    },
    {
      label: 'Image (mobile)',
      name: 'imageMobile',
      component: 'image',
    },
  ],
  defaultValue: {
    alt: 'Rack of green t-shirts',
    imageDesktop: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
    },
    imageMobile: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
    },
  },
};

const text = {
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, subheading, color',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'textarea',
    },
    {
      label: 'Description',
      name: 'description',
      component: 'markdown',
    },
  ],
  defaultValue: {
    heading: 'Collection Heading',
  },
};

export function Schema({ collection }) {
  if (!collection) return null;

  return {
    category: 'Collection',
    label: 'Collection In-grid Hero',
    key: 'collection-hero',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/collection-hero-preview_e3870d31-1425-4aab-bc91-9bcc087a0ce8.jpg?v=1675795219',
    fields: [
      image,
      text,
      {
        label: 'Link',
        name: 'link',
        component: 'link',
      },
    ],
  };
}

import {
  BUTTON_STYLES,
  COLORS,
  FONTS,
  FLEX_POSITIONS,
  OBJECT_POSITIONS,
  VERTICAL_MARGINS,
} from '@settings/common';

const image = {
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      label: 'Image Alt',
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Image (tablet/desktop)',
      name: 'imageDesktop',
      component: 'image',
    },
    {
      label: 'Image Position (tablet/desktop)',
      name: 'positionDesktop',
      component: 'select',
      options: OBJECT_POSITIONS.desktop,
    },
    {
      label: 'Image (mobile)',
      name: 'imageMobile',
      component: 'image',
    },
    {
      label: 'Image Position (mobile)',
      name: 'positionMobile',
      component: 'select',
      options: OBJECT_POSITIONS.mobile,
    },
  ],
};

const video = {
  label: 'Video Settings',
  name: 'video',
  description: 'Video link, poster image',
  component: 'group',
  fields: [
    {
      label: 'Video URL (tablet/desktop)',
      name: 'srcDesktop',
      component: 'text',
      description: 'Overrides tablet/desktop image option',
    },
    {
      label: 'Poster Image (tablet/desktop)',
      name: 'posterDesktop',
      component: 'image',
      description: 'First frame of video while video loads',
    },
    {
      label: 'Video URL (mobile)',
      name: 'srcMobile',
      component: 'text',
      description: 'Overrides mobile image option',
    },
    {
      label: 'Poster Image (mobile)',
      name: 'posterMobile',
      component: 'image',
      description: 'First frame of video while video loads',
    },
  ],
};

const text = {
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, superheading, subheading, color',
  component: 'group',
  fields: [
    {
      label: 'Text Style',
      name: 'style',
      component: 'select',
      options: [
        { label: 'Large', value: 'large' },
        { label: 'Small', value: 'small' },
      ],
      defaultValue: 'large',
    },
    {
      label: 'Font Style',
      name: 'font',
      component: 'select',
      options: FONTS,
    },
    {
      label: 'Hidden Heading For SEO',
      name: 'hiddenHeading',
      component: 'toggle',
      description:
        'When displayed heading is part of media, keep heading text hidden but still available for SEO',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'textarea',
    },
    {
      label: 'Enable Heading Divider',
      name: 'enableHeadingDivider',
      component: 'toggle',
      description: 'Displays divider below Heading',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Superheading',
      name: 'superheading',
      component: 'text',
    },
    {
      label: 'Enable Superheading Lines',
      name: 'enableSuperheadingLines',
      component: 'toggle',
      description: 'Wraps lines around Superheading',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Subheading',
      name: 'subheading',
      component: 'text',
    },
    {
      label: 'Text Color (tablet/desktop)',
      name: 'colorDesktop',
      component: 'select',
      options: COLORS,
    },
    {
      label: 'Text Color (mobile)',
      name: 'colorMobile',
      component: 'select',
      options: COLORS,
    },
  ],
};

const buttons = {
  label: 'Button Settings',
  name: 'button',
  description: 'Clickable slide, button links, button styles',
  component: 'group',
  fields: [
    {
      label: 'Entire Slide Clickable',
      name: 'clickableSlide',
      component: 'toggle',
      description:
        'Makes entire slide clickable using the first link below. Will not work if content is placed below image',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Buttons',
      name: 'buttons',
      component: 'group-list',
      description: 'Max of two buttons',
      itemProps: {
        label: '{{item.link.text}}',
      },
      validate: {
        maxItems: 2,
      },
      fields: [
        {
          label: 'Link',
          name: 'link',
          component: 'link',
        },
        {
          label: 'Button Style',
          name: 'style',
          component: 'select',
          options: BUTTON_STYLES,
        },
      ],
      defaultItem: {
        link: { text: 'Shop All', url: '' },
        style: 'btn-primary',
      },
    },
  ],
};

const content = {
  label: 'Content Settings',
  name: 'content',
  description: 'Dark overlay, content position, content alignment',
  component: 'group',
  fields: [
    {
      label: 'Dark Overlay',
      name: 'darkOverlay',
      component: 'toggle',
      description: 'Adds 20% opacity black overlay over media',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Content Position (tablet/desktop)',
      name: 'positionDesktop',
      component: 'select',
      options: FLEX_POSITIONS.desktop,
    },
    {
      label: 'Content Alignment (tablet/desktop)',
      name: 'alignmentDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'md:text-left md:items-start' },
        { label: 'Center', value: 'md:text-center md:items-center' },
        { label: 'Right', value: 'md:text-right md:items-end' },
      ],
    },
    {
      label: 'Max Content Width (tablet/desktop)',
      name: 'maxWidthDesktop',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'md:max-w-[22rem] lg:max-w-[28rem]' },
        { label: 'Medium', value: 'md:max-w-[30rem] lg:max-w-[38rem]' },
        { label: 'Wide', value: 'md:max-w-[38rem] lg:max-w-[48rem]' },
        { label: 'Full', value: 'md:max-w-full' },
      ],
    },
    {
      label: 'Content Position (mobile)',
      name: 'positionMobile',
      component: 'select',
      options: FLEX_POSITIONS.mobile,
    },
    {
      label: 'Content Alignment (mobile)',
      name: 'alignmentMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'text-left items-start' },
        { label: 'Center', value: 'text-center items-center' },
        { label: 'Right', value: 'text-right items-end' },
      ],
    },
    {
      label: 'Max Content Width (mobile)',
      name: 'maxWidthMobile',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'max-w-[16.5rem]' },
        { label: 'Medium', value: 'max-w-[22.5rem]' },
        { label: 'Wide', value: 'max-w-[28.5rem]' },
        { label: 'Full', value: 'max-w-full' },
      ],
    },
  ],
};

const defaultSlide = {
  image: {
    alt: 'Three men outside wearing outerwear',
    imageDesktop: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/tanya-pro-J2Cr4cBnN-0-unsplash_20_281_29.jpg?v=1672724643',
    },
    positionDesktop: 'md:object-center',
    imageMobile: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/tanya-pro-J2Cr4cBnN-0-unsplash_20_281_29.jpg?v=1672724643',
    },
    positionMobile: 'object-center',
  },
  text: {
    heading: 'All new products\nthis season',
    superheading: '',
    subheading: 'New styles and new fits',
    color: 'var(--white)',
  },
  button: {
    clickableSlide: false,
    buttons: [
      {
        link: {
          text: 'Shop Now',
          url: '',
        },
        style: 'btn-primary',
      },
    ],
  },
  content: {
    darkOverlay: false,
    alignmentDesktop: 'md:text-left md:items-start',
    positionDesktop: 'md:justify-start items-center',
    maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
    alignmentMobile: 'text-left items-start',
    positionMobile: 'justify-start items-center',
    maxWidthMobile: 'max-w-[22.5rem]',
  },
};

export const Schema = () => {
  return {
    category: 'Hero',
    label: 'Hero',
    key: 'hero',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/hero-preview_2cd7154c-7ec6-4846-84ca-85aaef836165.jpg?v=1675795229',
    fields: [
      {
        name: 'slides',
        label: 'Slides',
        component: 'group-list',
        itemProps: {
          label: '{{item.text.heading}}',
        },
        fields: [image, video, text, buttons, content],
        defaultValue: [defaultSlide],
        defaultItem: defaultSlide,
      },
      {
        label: 'Slider Settings',
        name: 'slider',
        description: 'Autoplay, delay, transition effect, bullet color',
        component: 'group',
        fields: [
          {
            label: 'Enable Autoplay',
            name: 'autoplay',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Pagination Bullets',
            name: 'pagination',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Delay Between Transitions (ms)',
            name: 'delay',
            component: 'number',
          },
          {
            label: 'Effect Between Transitions',
            name: 'effect',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Slide', value: 'slide' },
              { label: 'Fade', value: 'fade' },
            ],
          },
          {
            label: 'Active Bullet Color',
            name: 'activeBulletColor',
            component: 'select',
            options: COLORS,
          },
        ],
        defaultValue: {
          autoplay: true,
          pagination: true,
          delay: 8000,
          effect: 'fade',
          activeBulletColor: 'var(--white)',
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Above the fold, full width, full bleed, height, margin',
        fields: [
          {
            label: 'Above The Fold',
            name: 'aboveTheFold',
            component: 'toggle',
            description: `Sets the first slide's heading as H1 and image load as priority`,
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Content Placement',
            name: 'contentPlacement',
            component: 'radio-group',
            direction: 'vertical',
            variant: 'radio',
            options: [
              { label: 'Inside Image', value: 'inside-image' },
              { label: 'Below Image', value: 'below-image' },
            ],
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Full Bleed',
            name: 'fullBleed',
            component: 'toggle',
            description: 'Removes padding of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Height (desktop)',
            name: 'heightDesktop',
            component: 'number',
            description: 'Sets desktop height of this section',
          },
          {
            label: 'Height (tablet)',
            name: 'heightTablet',
            component: 'number',
            description: 'Sets tablet height of this section',
          },
          {
            label: 'Height (mobile)',
            name: 'heightMobile',
            component: 'number',
            description: 'Sets mobile height of this section',
          },
          {
            name: 'verticalMarginDesktop',
            label: 'Vertical Margin (desktop)',
            component: 'select',
            options: VERTICAL_MARGINS.desktop,
          },
          {
            name: 'verticalMarginTablet',
            label: 'Vertical Margin (tablet)',
            component: 'select',
            options: VERTICAL_MARGINS.tablet,
          },
          {
            name: 'verticalMarginMobile',
            label: 'Vertical Margin (mobile)',
            component: 'select',
            options: VERTICAL_MARGINS.mobile,
          },
        ],
        defaultValue: {
          aboveTheFold: true,
          contentPlacement: 'inside-image',
          fullWidth: true,
          fullBleed: true,
          heightDesktop: 700,
          heightTablet: 600,
          heightMobile: 600,
          verticalMarginDesktop: 'lg:my-0 xl:my-0',
          verticalMarginTablet: 'md:my-0',
          verticalMarginMobile: 'my-0',
        },
      },
    ],
  };
};

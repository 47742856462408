import { useEffect } from 'react';
import {
  useCartAddDiscountUrl,
  useSettings,
  useStorefrontInit,
} from '@backpackjs/storefront';
import { useRouter } from 'next/router';

import {
  // DataLayer,
  Footer,
  Header,
  LoopReturns,
  Modal,
  Overlay,
  Seo,
  SideDrawer,
  StorefrontHead,
  VisuallyIo,
} from '@snippets';
import { GlobalContextProvider, WishlistContextProvider } from '@contexts';
import {
  useLoopParams,
  useScrollRestoration,
  useSetViewportHeightCssVar,
  useShopifyAnalytics,
  useThemeColors,
} from '@hooks';
import { userGlobalGtm } from '@utils/dataLayer';

function StorefrontLayout({ ContentForLayout, ...props }) {
  const { pathname } = useRouter();
  const settings = useSettings();
  const promobar = settings?.header?.promobar;
  const promobarDisabled =
    promobar && (!promobar.enabled || !promobar.messages?.length);

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // set css var for viewport height
  useSetViewportHeightCssVar();

  // set css vars for theme colors
  useThemeColors();

  useShopifyAnalytics({ collection: props.collection, product: props.product });

  useScrollRestoration();

  useEffect(() => {
    userGlobalGtm(pathname);
  }, [pathname]);

  return (
    <GlobalContextProvider>
      <WishlistContextProvider>
        <StorefrontHead {...props} />
        <VisuallyIo {...props} />
        <LoopReturns {...props} />

        {/* Using old way instead, see dataLayer.js in /utils */}
        {/* <DataLayer {...props} /> */}

        <Seo {...props} />

        <Header promobarDisabled={promobarDisabled} />

        <main
          className={`${
            promobarDisabled
              ? 'pt-[var(--header-height)] lg:pt-[var(--header-height-desktop)]'
              : 'pt-[calc(var(--header-height)+var(--promobar-height))] lg:pt-[calc(var(--header-height-desktop)+var(--promobar-height-desktop))]'
          }`}
        >
          <ContentForLayout {...props} />
        </main>

        <Footer />

        <Overlay />

        <SideDrawer />

        <Modal />
      </WishlistContextProvider>
    </GlobalContextProvider>
  );
}

export default StorefrontLayout;

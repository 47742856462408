import { useMemo } from 'react';

export function CollectionFilterOption({
  activeFilters,
  addFilter,
  filterOption,
  isColor,
  name,
  removeFilter,
  value,
}) {
  const isActive = useMemo(() => {
    return Object.entries(activeFilters).some(([key, values]) => {
      return key === name && values.some((v) => v === value);
    });
  }, [activeFilters, value]);

  let swatch = null;

  if (isColor) {
    swatch = filterOption?.swatchColor;
  }

  const colorBackground =
    isActive && swatch === '#FFFFFF'
      ? 'var(--off-white)'
      : swatch || 'var(--off-white)';
  const nonColorBackground = isActive ? 'var(--black)' : '#F0F0F0';

  return (
    <button
      aria-label={`Add ${value} to filters`}
      className="flex items-center gap-3 text-left text-sm font-medium max-md:w-full max-md:items-center max-md:px-4 md:gap-2 md:text-base"
      onClick={() => {
        if (isActive) {
          removeFilter({ key: name, value });
        } else {
          addFilter({ key: name, value });
        }
      }}
      type="button"
    >
      <div
        className={`relative flex h-4 w-4 items-center justify-center overflow-hidden rounded-[50%] border border-white ${
          isActive ? '' : ''
        }`}
        style={{
          backgroundColor: nonColorBackground,
        }}
      >
        <div
          className={`absolute top-1/2 left-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 rounded-[50%] border-white ${
            isActive ? 'border-[2px]' : 'border-[0px]'
          }`}
          style={{
            backgroundColor: isColor ? colorBackground : nonColorBackground,
          }}
        />
      </div>

      <p className="flex-1 font-medium capitalize">{value} </p>
    </button>
  );
}

CollectionFilterOption.displayName = 'CollectionFilterOption';

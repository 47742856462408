import { useEffect, useState } from 'react';

import { usePredictiveSearch, useSearchProductResults } from '@hooks';
import { useGlobalContext } from '@contexts';
import { Link } from '../Link';
import { SearchInput } from './SearchInput';
import { SearchResults } from './SearchResults';
import { SearchSuggestions } from './SearchSuggestions';

export function Search() {
  const {
    state: { searchOpen },
    actions: { closeSearch },
  } = useGlobalContext();

  const [rawTerm, setRawTerm] = useState('');
  const [term, setTerm] = useState(rawTerm);

  const { productResults } = useSearchProductResults({
    term,
    mounted: searchOpen,
  });
  const { collectionResults } = usePredictiveSearch({
    term,
    mounted: searchOpen,
  });

  const hasProductResults = productResults?.length > 0;
  const hasCollectionResults = collectionResults?.length > 0;
  const hasResults = hasProductResults || hasCollectionResults;

  useEffect(() => {
    // debounce raw term
    const handler = window.requestTimeout(() => {
      setTerm(rawTerm);
    }, 300);
    return () => {
      window.clearRequestTimeout(handler);
    };
  }, [rawTerm]);

  return (
    <>
      <SearchInput
        closeSearch={closeSearch}
        rawTerm={rawTerm}
        searchOpen={searchOpen}
        setRawTerm={setRawTerm}
      />

      {hasResults ? (
        <SearchResults
          closeSearch={closeSearch}
          collectionResults={collectionResults}
          productResults={productResults}
        />
      ) : (
        <SearchSuggestions term={term} setRawTerm={setRawTerm} />
      )}

      {hasProductResults && (
        <div className="border-t border-t-border p-4">
          <Link
            aria-label="See all search results"
            className="btn-primary w-full"
            href="/pages/search"
            onClick={closeSearch}
            query={{ term }}
          >
            See All {productResults.length} Results
          </Link>
        </div>
      )}
    </>
  );
}

Search.displayName = 'Search';

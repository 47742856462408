import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';

import {
  productTypeKey,
  sortAlphabetically,
  sortCustom,
  sortNumerically,
  updateFilterUrlParams,
} from './utils';

export function useCollectionFilters({
  enabledFilters = true,
  products = [],
  productsReady = true,
  isSearchPage = false,
}) {
  const { isReady, query, asPath } = useRouter();
  const settings = useSettings();
  const filtersOnly = settings?.collection?.filters?.filters?.filter(
    (filter) => {
      if (filter?.searchPageOnly && isSearchPage) {
        return filter;
      }
      return filter;
    }
  );

  const filtersSettings = {
    ...settings?.collection?.filters,
    filtersOnly,
  };

  const [isOpen, setIsOpen] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [filters, setFilters] = useState([]);
  const [filtersMap, setFiltersMap] = useState({});
  const [currentCollection, setCurrentCollection] = useState('');

  const addFilter = useCallback(
    ({ key, value }) => {
      const updatedActiveFilters = { ...activeFilters };
      updatedActiveFilters[key] = updatedActiveFilters[key]
        ? [...updatedActiveFilters[key], value]
        : [value];
      setActiveFilters(updatedActiveFilters);
      updateFilterUrlParams({
        entriesToAdd: Object.entries(updatedActiveFilters),
      });
      localStorage.setItem(
        'activeFilters',
        JSON.stringify(updatedActiveFilters)
      );
      localStorage.setItem('currentCollection', currentCollection);
    },
    [activeFilters, currentCollection]
  );

  const removeFilter = useCallback(
    ({ key, value }) => {
      const updatedActiveFilters = { ...activeFilters };
      updatedActiveFilters[key] = updatedActiveFilters[key].filter(
        (item) => item !== value
      );
      if (updatedActiveFilters[key]?.length === 0)
        delete updatedActiveFilters[key];
      setActiveFilters(updatedActiveFilters);

      if (activeFilters[key]?.length === 1) {
        updateFilterUrlParams({
          keysToRemove: [key],
        });
      } else {
        updateFilterUrlParams({
          entriesToAdd: Object.entries(updatedActiveFilters),
        });
      }

      localStorage.setItem(
        'activeFilters',
        JSON.stringify(updatedActiveFilters)
      );
      localStorage.setItem('currentCollection', currentCollection);
    },
    [activeFilters, currentCollection]
  );

  const clearFilters = useCallback(() => {
    console.log('removing filters');
    setActiveFilters({});
    updateFilterUrlParams({ keysToRemove: Object.keys(activeFilters) });
    localStorage.removeItem('activeFilters');
    localStorage.removeItem('currentCollection');
  }, [activeFilters, currentCollection]);

  const initialFiltersData = useMemo(() => {
    if (!enabledFilters || !filtersSettings.filters?.length) return null;

    const tagFilters = [];
    const optionFilters = [];

    // set up initial filters map
    const _filtersMap = filtersSettings.filters.reduce(
      (
        acc,
        { customOrder, defaultOpen, label, name, orderValuesBy, source }
      ) => {
        if (source !== 'productType' && !name) return acc;
        const _name = name?.trim();

        if (source === 'tag') {
          tagFilters.push(_name);
        } else if (source === 'option') {
          optionFilters.push(_name);
        }
        const filter = {
          name:
            source === 'productType' ? productTypeKey : `${source}.${_name}`,
          label,
          isColor: label === 'Color',
          defaultOpen,
          orderValuesBy,
          customOrder,
          source,
          values: [],
          valuesMap: {},
        };
        return { ...acc, [filter.name]: filter };
      },
      {}
    );

    return {
      tagFilters,
      optionFilters,
      filtersMap: _filtersMap,
    };
  }, [enabledFilters, filtersSettings.filters]);

  // sets up filters and options on collection load
  useEffect(() => {
    if (
      !enabledFilters ||
      !initialFiltersData ||
      !productsReady ||
      !products.length
    )
      return;

    const {
      tagFilters,
      optionFilters,
      filtersMap: _filtersMap,
    } = initialFiltersData;
    const colorGroups = filtersSettings.colorGroups || [];

    products.forEach(({ optionsMap, productType, tags }) => {
      // product type options
      if (_filtersMap[productTypeKey] && productType) {
        _filtersMap[productTypeKey].valuesMap = {
          ..._filtersMap[productTypeKey].valuesMap,
          [productType]: {
            value: productType,
            count:
              (_filtersMap[productTypeKey].valuesMap[productType]?.count || 0) +
              1,
          },
        };
      }
      // tag filter options
      if (tagFilters?.length && tags?.length) {
        tags.forEach((tag) => {
          const [_key, _value] = tag.split('::');
          const key = _key.trim();
          const value = _value?.trim();
          if (value && tagFilters.includes(key)) {
            _filtersMap[`tag.${key}`].valuesMap = {
              ..._filtersMap[`tag.${key}`].valuesMap,
              [value]: {
                value,
                count:
                  (_filtersMap[`tag.${key}`].valuesMap[value]?.count || 0) + 1,
              },
            };
          }
        });
      }
      // option filter options
      Object.entries(optionsMap || {}).forEach(([_key, values]) => {
        const key = _key.trim();

        if (optionFilters.includes(key)) {
          _filtersMap[`option.${key}`].valuesMap = {
            ..._filtersMap[`option.${key}`].valuesMap,
            ...values.reduce((acc, value) => {
              if (key === 'Color') {
                const colorGroup = colorGroups.find((group) =>
                  group?.colors?.some((color) => value.indexOf(color) > -1)
                );

                if (colorGroup) {
                  const { color, group } = colorGroup;
                  return {
                    ...acc,
                    [group]: {
                      swatchColor: color,
                      value: group,
                      colors: acc?.colorGroup?.values
                        ? [...acc?.colorGroup.values, value]
                        : [value],
                      count:
                        (_filtersMap[`option.${key}`].valuesMap[group]?.count ||
                          0) + 1,
                    },
                  };
                }

                return acc;
              }
              return {
                ...acc,
                [value]: {
                  value,
                  count:
                    (_filtersMap[`option.${key}`].valuesMap[value]?.count ||
                      0) + 1,
                },
              };
            }, {}),
          };
        }
      });
    });
    // sort options

    Object.values(_filtersMap).forEach((filter) => {
      const values = Object.values(filter.valuesMap);
      if (filter.orderValuesBy === 'alphabet') {
        _filtersMap[filter.name].values = sortAlphabetically({ values });
      } else if (filter.orderValuesBy === 'number') {
        _filtersMap[filter.name].values = sortNumerically({ values });
      } else if (filter.orderValuesBy === 'custom') {
        _filtersMap[filter.name].values = sortCustom({
          values,
          sortOrder: filter.sortOrder,
        });
      } else {
        _filtersMap[filter.name].values = values;
      }
    });

    setFilters(Object.values(_filtersMap));
    setFiltersMap(_filtersMap);
  }, [
    enabledFilters,
    initialFiltersData?._filtersMap,
    products,
    productsReady,
    filtersSettings.colorGroups,
  ]);

  // sets filters on page load
  useEffect(() => {
    if (!isReady || !Object.keys({ ...filtersMap }).length) return;

    const savedFilters = localStorage.getItem('activeFilters');
    const savedCollection = localStorage.getItem('currentCollection');
    const initialFilters = savedFilters ? JSON.parse(savedFilters) : {};
    const currentCollectionPath = asPath.split('?')[0];

    if (savedCollection !== currentCollectionPath) {
      clearFilters();
      localStorage.removeItem('currentCollection');
    } else {
      setCurrentCollection(savedCollection || '');
      setActiveFilters(initialFilters);
      updateFilterUrlParams({ entriesToAdd: Object.entries(initialFilters) });
    }

    const filtersFromParams = Object.entries({ ...query }).reduce(
      (acc, [key, value]) => {
        if (key === 'handle') return acc;
        const values = value.split(',');
        const valuesMap = filtersMap[key]?.valuesMap;
        values.forEach((valuesItem) => {
          if (!valuesMap?.[valuesItem]) return;
          if (!acc[key]) acc[key] = [];
          acc[key] = [...acc[key], valuesItem];
        });
        return acc;
      },
      initialFilters
    );
    setActiveFilters(filtersFromParams);
  }, [isReady, filtersMap]);

  // Monitor URL changes to reset filters when the collection changes
  useEffect(() => {
    const currentCollectionPath = asPath.split('?')[0];
    const savedCollection = localStorage.getItem('currentCollection');

    if (savedCollection && savedCollection !== currentCollectionPath) {
      clearFilters();
      localStorage.removeItem('currentCollection');
    }
    setCurrentCollection(currentCollectionPath);
  }, [asPath, clearFilters, currentCollection]);

  return {
    state: {
      activeFilters,
      filters,
      filtersMap,
      isOpen,
    },
    actions: {
      addFilter,
      removeFilter,
      clearFilters,
      setIsOpen,
    },
  };
}

import { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { useSettings } from '@backpackjs/storefront';

export function useSearchAutocomplete({ term, mounted = true }) {
  const settings = useSettings();

  const [autocompleteFuse, setAutocompleteFuse] = useState(null);
  const [autocompleteResults, setAutocompleteResults] = useState([]);

  const { enabled } = { ...settings?.search?.autocomplete };
  const propertyKeys = ['title'];

  const setAutocompleteFuseOnMount = useCallback(async () => {
    try {
      if (autocompleteFuse) return;

      const productsRes = await fetch('/json/products-list.json');
      const productsList = await productsRes.json();
      const termsTable = productsList.reduce((table, product) => {
        if (!product?.publishedAt || product?.tags?.includes('hide-search'))
          return table;
        propertyKeys.forEach((key) => {
          const property = product[key];
          if (!property) return;

          property.split(' ').forEach((item, index, arr) => {
            const word = item.toLowerCase();

            if (!/[a-z0-9]/.test(word)) return;

            if (table[word]) {
              table[word] += 1;
            } else {
              table[word] = 1;
            }
            const nextWord = arr[index + 1]?.toLowerCase();
            if (nextWord && /[a-z0-9]/.test(nextWord)) {
              const twoWords = `${word} ${nextWord}`;
              if (table[twoWords]) {
                table[twoWords] += 1;
              } else {
                table[twoWords] = 1;
              }
            }
          });
        });
        return table;
      }, {});

      const sortedList = [...Object.keys(termsTable)].sort((a, b) => {
        return termsTable[a] - termsTable[b];
      });
      const formattedList = sortedList.map((suggestion) => ({
        suggestion,
      }));

      setAutocompleteFuse(
        new Fuse(formattedList, {
          keys: ['suggestion'],
          ignoreLocation: true,
          minMatchCharLength: 3,
          threshold: 0.3,
        })
      );
    } catch (error) {
      console.error(error.message);
    }
  }, [autocompleteFuse, propertyKeys]);

  useEffect(() => {
    if (!enabled || !mounted) return;
    setAutocompleteFuseOnMount();
  }, [enabled, mounted, propertyKeys]);

  useEffect(() => {
    if (!enabled || !autocompleteFuse) return;
    if (!term) {
      setAutocompleteResults([]);
      return;
    }
    const results = autocompleteFuse.search(term);
    setAutocompleteResults(results);
  }, [autocompleteFuse, enabled, term]);

  return { autocompleteResults };
}

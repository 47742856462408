import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
import {
  useCustomer,
  useCart,
  useCartAddItem,
  useProductInventoryByHandle,
  useSettings,
} from '@backpackjs/storefront';

import { useGlobalContext } from '@contexts';
import { addToCartGtm, cartViewGtm } from '@utils/dataLayer';

export function useAddToCart(
  { addToCartText: addToCartTextOverride, selectedVariant } = {
    addToCartTextOverride: '',
    selectedVariant: null,
  }
) {
  const {
    actions: { openCart },
  } = useGlobalContext();
  const router = useRouter();
  const customer = useCustomer();
  const cart = useCart();
  const { cartAddItem } = useCartAddItem();
  const { inventory } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });
  const settings = useSettings();

  const [isAdding, setIsAdding] = useState(false);

  const isLoading = !inventory;
  const variantInventory = inventory?.variants?.[selectedVariant?.id];
  const variantIsSoldOut = !isLoading && !variantInventory?.availableForSale;
  const variantIsPreorder =
    !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const enabledNotifyMe = settings?.product?.backInStock?.enabled;
  const isOverselling = selectedVariant?.inventoryPolicy === 'CONTINUE';

  let buttonText = '';
  if (variantIsPreorder) {
    buttonText = settings?.product?.addToCart?.preorderText || 'Preorder';
  } else if (variantIsSoldOut) {
    buttonText = enabledNotifyMe
      ? settings?.product?.backInStock?.notifyMeText || 'Notify Me'
      : settings?.product?.addToCart?.soldOutText || 'Sold Out';
  } else {
    buttonText =
      addToCartTextOverride ||
      settings?.product?.addToCart?.addToCartText ||
      'Add To Cart';
  }

  const handleAddToCart = useCallback(
    async ({ attributes } = {}) => {
      if (!selectedVariant?.id || isLoading || isAdding) return;
      setIsAdding(true);
      await cartAddItem({
        merchandiseId: selectedVariant.id,
        quantity: 1,
        attributes,
      });
      addToCartGtm({ selectedVariant, customer });
      setIsAdding(false);
      if (router?.pathname !== '/cart') openCart();
      cartViewGtm({ lineItems: cart?.lines });
    },
    [
      cart?.updatedAt,
      isAdding,
      isLoading,
      router?.pathname,
      selectedVariant?.id,
    ]
  );

  return {
    state: {
      buttonText,
      isAdding,
      isLoading,
      isNotifyMe: variantIsSoldOut && enabledNotifyMe && !isOverselling,
      isSoldOut: variantIsSoldOut,
      subtext: settings?.product?.addToCart?.subtext,
    },
    actions: {
      handleAddToCart,
    },
  };
}

import { CartGWPItem } from './CartGWPItem';

export function CartGWP({ closeCart, gwpBuyXGetY, gwpSpendXGetY, isCartPage }) {
  if (!gwpSpendXGetY?.isActive && !gwpBuyXGetY?.isActive) return null;

  return (
    <div className="relative flex-1 overflow-y-auto">
      {gwpBuyXGetY?.isActive && (
        <div
          className={`${
            isCartPage
              ? 'pt-5 md:border-t md:border-t-border'
              : 'border-t border-t-border p-5'
          }`}
        >
          <p className="mb-4 text-center text-sm font-medium">
            {gwpBuyXGetY?.isEligible
              ? `Congrats! Your cart qualifies for a free gift!`
              : gwpBuyXGetY?.lockedMessage ||
                `Buy more items to qualify for a free gift!`}
          </p>
          <ul>
            <li className="border-b border-b-border first:-mt-5 last:border-none">
              <CartGWPItem
                closeCart={closeCart}
                gwpProduct={gwpBuyXGetY?.gwpProduct}
                qualifies={gwpBuyXGetY?.isEligible}
              />
            </li>
          </ul>
        </div>
      )}

      {gwpSpendXGetY?.isActive && (
        <div
          className={`${
            isCartPage
              ? 'pt-5 md:border-t md:border-t-border'
              : 'border-t border-t-border p-5'
          }`}
        >
          <p className="mb-4 text-center text-sm font-medium">
            {gwpSpendXGetY?.priceDifference === 0
              ? `Congrats! Your cart qualifies for a free gift!`
              : `Add $${parseFloat(gwpSpendXGetY?.priceDifference).toFixed(
                  2
                )} more to qualify for a free gift!`}
          </p>
          <ul>
            <li className="border-b border-b-border first:-mt-5 last:border-none">
              <CartGWPItem
                closeCart={closeCart}
                gwpProduct={gwpSpendXGetY?.gwpProduct}
                qualifies={gwpSpendXGetY?.priceDifference === 0}
              />
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

CartGWP.displayName = 'CartGWP';

import { useCallback } from 'react';
import { transformItemsToCart, processCartObject } from './utils';

export const useLoopCart = () => {
  const createLoopCart = useCallback(async ({ cartItems }) => {
    const loopCart = transformItemsToCart(cartItems);
    const encoded = processCartObject(loopCart);

    const endpoint = '/api/loopCart';
    try {
      const response = await fetch(endpoint, {
        method: 'post',
        body: JSON.stringify({
          action: 'createCart',
          cartItems: encoded,
        }),
      });

      if (response.ok) {
        const { data } = await response.json();
        return { cartToken: data?.token };
      }
      return null;
    } catch (error) {
      console.error('Creating Loop Cart:', error.message);
      return null;
    }
  }, []);

  const getLoopCart = useCallback(async ({ cartToken }) => {
    const endpoint = '/api/loopCart';
    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
          action: 'getCart',
          cartToken,
        }),
      });

      if (response.ok) {
        const { data } = await response.json();
        return { cart: data?.cart };
      }
      return null;
    } catch (error) {
      console.error('Creating Loop Cart:', error.message);
      return null;
    }
  }, []);

  const updateLoopCart = useCallback(async ({ cartToken, cartItems }) => {
    const endpoint = '/api/loopCart';
    const loopCart = transformItemsToCart(cartItems);
    const encoded = processCartObject(loopCart);

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
          action: 'updateCart',
          cartToken,
          cartItems: encoded,
        }),
      });

      if (response.ok) {
        const { data } = await response.json();
        return data?.data?.cart;
      }
      return null;
    } catch (error) {
      console.error('Creating Loop Cart:', error.message);
      return null;
    }
  }, []);

  return {
    createLoopCart,
    getLoopCart,
    updateLoopCart,
  };
};

import { trackKlaviyoEvent } from '@snippets/DataLayer/utils';

export const userGlobalGtm = (pathname) => {
  const pageType = pathname ? pathname.split('/')[1] : '';

  if (window._rsq && pageType !== 'products') {
    window._rsq.push(['_track']);
  }

  if (!window.dataLayer) return;

  if (window.fbq) {
    window.fbq('track', 'PageView');
  }

  window.dataLayer.push({
    event: 'routeUpdate',
    pageType: pageType || 'home',
  });
};

export const addToCartGtm = ({ selectedVariant, customer }) => {
  console.log('selectedVariant:', selectedVariant);
  const {
    displayName,
    currencyCode,
    title,
    sku,
    legacyResourceId,
    price,
    selectedOptionsMap,
    image,
  } = selectedVariant || {};

  const pageUrl = window.location.href;

  if (window._rsq) {
    window._rsq.push([
      '_addItem',
      {
        id: legacyResourceId,
        name: displayName,
        price,
      },
    ]);
    window._rsq.push(['_setAction', 'shopping_cart']);
    window._rsq.push(['_track']);
  }

  if (!window.dataLayer) return;

  if (window.fbq) {
    window.fbq('track', 'AddToCart', {
      content_name: displayName,
      content_category: selectedVariant?.product?.productType,
      content_ids: sku,
      content_type: 'product',
      value: price,
      currency: currencyCode || 'US',
    });
  }

  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'USD',
      add: {
        products: [
          {
            name: displayName,
            variant: title,
            id: sku || '',
            productId: legacyResourceId || '',
            price,
            quantity: 1,
            image: image?.src,
            pageUrl: pageUrl,
            ...selectedOptionsMap,
          },
        ],
      },
    },
  });

  trackKlaviyoEvent({
    email: customer?.email,
    event: 'Add To Cart',
    properties: {
      name: displayName,
      variant: title,
      id: sku || '',
      productId: legacyResourceId || '',
      price,
      quantity: 1,
      image: image?.src,
      pageUrl: pageUrl,
      ...selectedOptionsMap,
    },
  });
};

export const cartViewGtm = ({ lineItems }) => {
  if (!window.dataLayer) return;

  if (lineItems?.length > 0) {
    const validItems = lineItems
      .filter((item) => item.variant)
      .map((item, idx) => {
        const { variant } = item;

        return {
          position: idx,
          id: variant.sku,
          productId: variant.product.id.split('/').pop(),
          variantId: item.variant.id.split('/').pop(),
          name: variant.title,
          price: variant.price,
        };
      });

    window.dataLayer.push({
      event: 'cartView',
      ecommerce: {
        currencyCode: 'USD',
        actionField: {
          list: 'Inline Cart',
        },
        impressions: validItems || [],
      },
    });
  } else {
    window.dataLayer.push({
      event: 'cartView',
      ecommerce: {
        currencyCode: 'USD',
        actionField: {
          list: 'Empty Cart',
        },
        impressions: [],
      },
    });
  }
};

export const collectionViewGtm = (collection) => {
  if (!collection) return;

  window.dataLayer.push({
    event: 'collectionView',
    id: collection.id,
    handle: collection.handle,
    title: collection.title,
    ecommerce: {
      currencyCode: 'USD',
      actionField: {
        list: 'Collection Page',
      },
      impressions: collection.products
        ? collection.products
            .filter((product) => product)
            .slice(0, 100)
            .map((product, index) => {
              return {
                position: index,
                name: product?.title,
                handle: product?.handle,
              };
            })
        : [],
    },
  });
};

export const productView = ({ activeVariant, customer }) => {
  const { displayName, currencyCode, sku, price } = activeVariant || {};

  if (activeVariant && window._rsq) {
    window._rsq.push(['_addItem', { id: activeVariant.legacyResourceId }]);
    window._rsq.push(['_track']);
  }

  if (!activeVariant || !window.dataLayer) return;

  if (window.fbq) {
    window.fbq('track', 'ViewContent', {
      content_name: displayName,
      content_category: activeVariant?.product?.productType,
      content_ids: sku,
      content_type: 'product',
      value: price,
      currency: currencyCode || 'US',
    });
  }

  window.dataLayer.push({
    event: 'productDetailView',
    ecommerce: {
      currencyCode: 'USD',
      detail: {
        products:
          [
            {
              handle: activeVariant?.product?.handle,
              featImg: activeVariant?.image?.src,
              name: activeVariant?.displayName,
              id: activeVariant?.legacyResourceId,
              price: activeVariant?.price,
              ...(activeVariant?.selectedOptionsMap || {}),
            },
          ] || [],
      },
    },
  });

  trackKlaviyoEvent({
    email: customer?.email,
    event: 'Viewed Product',
    properties: {
      handle: activeVariant?.product?.handle,
      featImg: activeVariant?.image?.src,
      name: activeVariant?.displayName,
      id: activeVariant?.legacyResourceId,
      price: activeVariant?.price,
      ...(activeVariant?.selectedOptionsMap || {}),
    },
  });
};

export const initiateCheckout = ({ cart, cartTotals }) => {
  const currencyCode =
    cart?.estimatedCost?.subtotalAmount?.currencyCode || 'US';

  const cartTotal = parseFloat(cartTotals?.total || '0').toFixed(2);

  const cartItems = cart?.lines?.map((line) => {
    return {
      id: line?.variant?.sku,
      name: line?.variant?.product?.title,
      content_category: line?.product?.productType,
      item_price: parseFloat(
        line?.estimatedCost?.totalAmount?.amount || 0
      ).toFixed(2),
      quantity: line?.quantity,
    };
  });

  if (window.fbq) {
    window.fbq('track', 'InitiateCheckout', {
      contents: cartItems,
      content_type: 'product',
      currency: currencyCode,
      value: cartTotal,
    });
  }
};

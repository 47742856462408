import { useMemo, useCallback } from 'react';

import { AddToCart, Favorite, ProductStars } from '@snippets';
import { useGlobalContext } from '@contexts';
import { useSettings } from '@backpackjs/storefront';
import { ProductOptions } from './ProductOptions';
import { SizeGuideModal } from './SizeGuideModal';
import { ProductDetailAccordions } from './ProductDetailAccordions';

export function ProductDetails({
  isModal,
  product,
  productDetailAccordionsSection,
  productFeaturesSection,
  selectedOptions,
  selectedVariant,
  setSelectedOptions,
}) {
  const settings = useSettings();
  const hideProductTypes = settings?.product?.sizeGuide?.hideProductTypes;
  const productType = product?.productType;
  const hideSizeGuide = hideProductTypes?.some((type) => type === productType);

  const hideOptions =
    product.variants?.length === 1 &&
    product.variants[0].title === 'Default Title';

  const {
    actions: { openModal },
  } = useGlobalContext();

  const sku = useMemo(() => {
    return (
      selectedVariant?.sku
        ?.replace('_', '-')
        .split('-')
        .slice(0, 2)
        .join('-') || ''
    );
  }, [selectedVariant?.sku]);

  const handleSizeGuideClick = useCallback(() => {
    openModal(<SizeGuideModal />);
  }, []);

  return (
    <div className="flex flex-col gap-x-5 gap-y-7">
      <div className="mt-0 flex justify-start md:mt-0 md:items-center">
        <ProductStars isPdp product={product} />
      </div>

      {!hideOptions && (
        <ProductOptions
          isModal={isModal}
          product={product}
          selectedOptions={selectedOptions}
          selectedVariant={selectedVariant}
          setSelectedOptions={setSelectedOptions}
        />
      )}

      {!hideSizeGuide && (
        <div>
          <button
            className="text-underline capitalize"
            onClick={handleSizeGuideClick}
            type="button"
          >
            Size Guide
          </button>
        </div>
      )}

      <div>
        <AddToCart isPdp selectedVariant={selectedVariant} />
      </div>

      <div className="relative">
        <Favorite isPdp selectedVariant={selectedVariant} />
      </div>

      {productDetailAccordionsSection || (
        <ProductDetailAccordions
          product={product}
          sku={sku}
          productFeaturesSection={productFeaturesSection}
        />
      )}
    </div>
  );
}

ProductDetails.displayName = 'ProductDetails';

import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useScrollRestoration = () => {
  const { pathname } = useRouter();
  useEffect(() => {
    const hasScrollHistory = sessionStorage.getItem(
      `__next_scroll_${window.history?.state?.key}`
    );
    if (hasScrollHistory && process.env.NODE_ENV !== 'development') {
      const coordinates = JSON.parse(hasScrollHistory);
      if (coordinates?.y) {
        window.scrollTo(0, coordinates?.y);
      }
    }
  }, [pathname]);
};

import { useMemo, useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';
import { Select } from '../Select';
import { sortKeys } from './utils';

export function CollectionSort({
  collectionFiltersData,
  isOpen,
  setIsOpen,
  selectedSort,
  selectSort,
}) {
  const settings = useSettings();
  const sortOptions = { ...settings?.collection?.sort };
  const { asPath } = useRouter();
  const currentCollectionPath = asPath.split('?')[0];

  const options = useMemo(() => {
    return sortKeys.map((key) => ({
      value: key,
      label: sortOptions[`${key}Label`],
    }));
  }, [sortOptions]);

  useEffect(() => {
    const savedSort = localStorage.getItem('sortSelected');
    if (savedSort) {
      selectSort(JSON.parse(savedSort));
    }
  }, [selectSort]);

  useEffect(() => {
    if (selectedSort) {
      localStorage.setItem('sortSelected', JSON.stringify(selectedSort));
    }
  }, [selectedSort]);

  useEffect(() => {
    const savedCollectionPath = localStorage.getItem('collectionPath');
    if (savedCollectionPath !== currentCollectionPath) {
      localStorage.removeItem('sortSelected');
      selectSort(null);
    }
  }, [asPath]);

  return (
    <div className="ml-auto min-w-[9rem] md:min-w-[10rem]">
      <Select
        collectionFiltersData={collectionFiltersData}
        isSortButton
        onSelect={(option) => selectSort(option)}
        options={options}
        placeholder="Sort By"
        selectedOption={selectedSort}
        sortIsOpen={isOpen}
        setSortIsOpen={setIsOpen}
      />
    </div>
  );
}

CollectionSort.displayName = 'CollectionSort';

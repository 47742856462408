import { useState } from 'react';

import { Markdown, Svg } from '@snippets';

export function ProductDetailAccordion({
  accordion,
  headerBgColor,
  headerTextColor,
}) {
  const { body, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div>
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="flex h-14 w-full items-center justify-between gap-x-4 p-4"
        onClick={() => setIsOpen(!isOpen)}
        style={{ backgroundColor: headerBgColor, color: headerTextColor }}
        type="button"
      >
        <h5 className="text-sm font-bold">{header}</h5>

        {isOpen ? (
          <Svg
            className="w-[0.875rem] text-current"
            src="/svgs/minus.svg#minus"
            title="Minus"
            viewBox="0 0 12 13"
          />
        ) : (
          <Svg
            className="w-[0.875rem] text-current"
            src="/svgs/plus.svg#plus"
            title="Plus"
            viewBox="0 0 12 13"
          />
        )}
      </button>

      <div
        className={`px-4 pt-4 [&_p]:mb-3 [&_p]:text-sm [&_h2]:mb-3 [&_h2]:text-sm [&_h1]:mb-3 [&_h1]:text-sm [&_h3]:mb-3 [&_h3]:text-sm [&_h4]:mb-3 [&_h4]:text-sm [&_h5]:mb-3 [&_h5]:text-sm [&_h6]:mb-3 [&_h6]:text-sm [&_ul]:!pl-4 [&_ul]:text-sm [&_ol]:!pl-4 [&_ol]:text-sm ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <Markdown>{body}</Markdown>
      </div>
    </div>
  );
}

ProductDetailAccordion.displayName = 'ProductDetailAccordion';

import { Image, ProductItem } from '@snippets';
import { Schema } from './ProductsGrid.schema';

export function ProductsGrid({ cms }) {
  const { products, section, productItem } = cms;
  const {
    images = [],
    productsList = [],
    showProduct = false,
    productsPerRow,
    productsPerRowMb,
    gap,
  } = products || {};

  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div className="lg-px-contained py-4 md:py-6">
      <div className={`mx-auto ${maxWidthClass}`}>
        <div className="p-2">
          <div
            className={`relative grid grid-cols-${
              productsPerRowMb || productsPerRow
            } lg:grid-cols-${productsPerRow || 3} gap-${gap} p-4 `}
          >
            {showProduct
              ? productsList.map(({ product }, index) => (
                  <ProductItem
                    key={`${product?.handle}-${index}`}
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledStarRating={productItem?.enabledStarRating}
                    enableNamePrice={productItem?.enableNamePrice}
                    handle={product?.handle}
                    index={index}
                  />
                ))
              : images?.map(({ image, alt }, index) => {
                  return (
                    <div
                      key={`${image?.src}-${index}`}
                      className="group/media relative aspect-[var(--product-image-aspect-ratio)] bg-offWhite"
                    >
                      <Image
                        alt={alt}
                        className="w-full"
                        fill
                        sizes="(min-width: 1440px) 1200px, (min-width: 768px) 50vw, 100vw"
                        src={image?.src}
                      />
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </div>
  );
}

ProductsGrid.displayName = 'ProductsSlider';
ProductsGrid.Schema = Schema;

import { Fragment, useEffect, useRef, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { CollectionHero } from '@sections/CollectionHero/CollectionHero';
import { useRouter } from 'next/router';
import { CollectionPromoTile } from './CollectionPromoTile';
import { ProductItem } from '../ProductItem';

export function CollectionGrid({
  activeFilters,
  collectionHero,
  collectionProductsData,
  isCollectionPage,
  isSearchPage,
  promoTiles,
}) {
  const settings = useSettings();
  const { pagination, productItem } = { ...settings?.collection };

  const {
    state: { filteredProducts, productsLimit },
    actions: { loadMoreProducts },
    refs: { loadMoreRef: loadMore },
  } = collectionProductsData;
  const loadMoreRef = useRef(null);
  const [productsPerPage, setProductsPerPage] = useState(productsLimit);
  const [scrollY, setScrollY] = useState(null);
  const firstProductItemImage = useRef();
  const { asPath } = useRouter();
  const currentCollectionPath = asPath.split('?')[0];

  const hasActiveFilters = Object.keys(activeFilters).length > 0;
  const hasMoreProducts = filteredProducts?.length > productsPerPage;

  useEffect(() => {
    const savedScrollY = localStorage.getItem('scrollY');
    const savedProductsPerPage = localStorage.getItem('productsPerPage');
    const savedCollectionPath = localStorage.getItem('collectionPath');

    if (
      savedScrollY !== null &&
      savedCollectionPath === currentCollectionPath
    ) {
      setScrollY(parseInt(savedScrollY, 10));
    }
    if (savedProductsPerPage !== null) {
      setProductsPerPage(parseInt(savedProductsPerPage, 10));
    }
  }, []);

  useEffect(() => {
    if (scrollY !== null) {
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 1000);
    }
  }, [scrollY]);

  useEffect(() => {
    const handleScroll = () => {
      localStorage.setItem('scrollY', window.scrollY);
      localStorage.setItem('productsPerPage', filteredProducts.length);
    };
    localStorage.setItem('collectionPath', currentCollectionPath);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [filteredProducts.length]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMoreProducts) {
          loadMoreProducts();
          setProductsPerPage((prev) => prev + productsLimit);
        }
      },
      {
        rootMargin: '200px',
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasMoreProducts, loadMoreRef, loadMoreProducts]);

  useEffect(() => {
    if (filteredProducts.length < productsPerPage) {
      loadMoreProducts();
    }
  }, [filteredProducts.length, productsPerPage, loadMoreProducts]);

  return (
    <>
      {filteredProducts?.length > 0 && (
        <ul className="grid grid-cols-2 mx-auto gap-x-3 gap-y-5 xs:gap-x-3 xs:gap-y-8 md:grid-cols-2 lg:grid-cols-3">
          {filteredProducts.slice(0, productsPerPage).map((product, index) => {
            const promoTile = promoTiles?.find(
              ({ position }) => position === index + 1
            );
            const key = product.id || product.handle || index;
            return (
              <Fragment key={key}>
                {collectionHero && index === 0 && (
                  <li className="col-span-2" key="collection-hero">
                    <CollectionHero
                      cms={
                        collectionHero?.props?.children?.props?.children?.props
                          ?.cms
                      }
                      firstProductItemImage={firstProductItemImage}
                    />
                  </li>
                )}
                {promoTile && (
                  <li key={`promo-tile-${key}`}>
                    <CollectionPromoTile tile={promoTile} />
                  </li>
                )}
                <li key={`collection-tile-${key}`}>
                  <ProductItem
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledStarRating={productItem?.enabledStarRating}
                    handle={product.handle}
                    index={index}
                    isCollectionPage={isCollectionPage}
                    isSearchPage={isSearchPage}
                    ref={index === 0 ? firstProductItemImage : null}
                    product={product}
                  />
                </li>
              </Fragment>
            );
          })}
        </ul>
      )}

      {hasMoreProducts && (
        <div ref={loadMoreRef} className="flex justify-center mt-10">
          <button
            className={`${pagination?.buttonStyle}`}
            onClick={loadMoreProducts}
            type="button"
          >
            {pagination?.loadText}
          </button>
        </div>
      )}

      {!filteredProducts?.length && hasActiveFilters && (
        <div className="flex min-h-[12rem] items-center justify-center text-center">
          <p>No products found matching these filters.</p>
        </div>
      )}
    </>
  );
}

CollectionGrid.displayName = 'CollectionGrid';

import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Pagination } from 'swiper';

import { Badges } from '@snippets';
import { ProductMobileImage } from './ProductMobileImage';
import { ProductVideo } from './ProductVideo';

export function ProductMediaMobile({
  product,
  selectedMedia,
  selectedVariant,
  handleMediaClick,
}) {
  const [swiper, setSwiper] = useState(null);

  // Reset the active index when the selected color changes
  useEffect(() => {
    if (!swiper) return;
    swiper.slideTo(0);
  }, [selectedVariant?.selectedOptionsMap?.Color, swiper]);

  return (
    <Swiper
      onSwiper={setSwiper}
      modules={[A11y, Pagination]}
      slidesPerView={1}
      grabCursor
      pagination={{
        el: '.swiper-pagination',
        clickable: true,
      }}
      className=""
    >
      {selectedMedia?.map((media, index) => {
        return (
          <SwiperSlide key={media.id}>
            {media.mediaContentType === 'VIDEO' ? (
              <ProductVideo
                media={media}
                handleMediaClick={() => handleMediaClick(index, true)}
              />
            ) : (
              <ProductMobileImage
                media={media}
                title={selectedVariant?.product?.title}
                priority={index === 0}
                handleMediaClick={() => handleMediaClick(index, true)}
              />
            )}
          </SwiperSlide>
        );
      })}

      <div className="active-bullet-white swiper-pagination bottom-[20px] flex w-full justify-center gap-2 md:hidden" />
      <div className="pointer-events-none absolute left-0 top-0 z-[1] p-2.5 xs:p-4 md:p-3 xl:p-4">
        <Badges
          isDraft={product?.status === 'DRAFT'}
          tags={selectedVariant?.product?.tags}
        />
      </div>
    </Swiper>
  );
}

ProductMediaMobile.displayName = 'ProductMediaMobile';

import React from 'react';
import Script from 'next/script';

import { useYotpoRefreshOnMount } from '../ProductReviews/useYotpoRefreshOnMount';
import { Schema } from './StoreReviews.schema';

export function StoreReviews({ cms }) {
  const { heading, description } = cms;
  useYotpoRefreshOnMount({ delay: 50 });
  return (
    <div className="py-contained-2x">
      <Script
        type="text/javascript"
        id="yotpo-script"
        strategy="afterInteractive"
        src={`https://staticw2.yotpo.com/${process.env.NEXT_PUBLIC_YOTPO_KEY}/widget.js`}
      />
      <div className="mb-10 text-center md:px-6 lg:px-10">
        <h1>{heading}</h1>
        {description && <p className="mt-5">{description}</p>}
      </div>
      <div
        id="yotpo-testimonials-custom-tab"
        className="md:!px-6 lg:!px-10 xl:!px-[100px] [&_.yotpo-label-container]:!border-none"
      />
    </div>
  );
}

StoreReviews.displayName = 'StoreReviews';
StoreReviews.Schema = Schema;

export const productTypeKey = 'product_type';
export const sortKeys = ['default', 'recentlyAdded', 'highToLow', 'lowToHigh'];

export const getFilteredProducts = ({
  activeFilters = {},
  products = [],
  colorGroups,
}) => {
  let filteredProducts = products.reduce((acc, product) => {
    if (!product.optionsMap) return acc;

    const minReqLength = Object.keys(activeFilters).filter(
      (key) => key.startsWith('tag') || key.startsWith('option')
    ).length;

    // filter by product option
    const optionMatches = [];
    const optionFilters = Object.keys(activeFilters).reduce(
      (optionsAcc, key) => {
        if (key.startsWith('option.')) {
          return [...optionsAcc, key.replace('option.', '')];
        }
        return optionsAcc;
      },
      []
    );

    if (optionFilters.length) {
      optionFilters.forEach((filter) => {
        if (product.optionsMap[filter]) {
          const hasMatch = product.optionsMap[filter].some((value) => {
            if (filter === 'Color') {
              return activeFilters[`option.${filter}`].some((activeFilter) => {
                const colorGroup = colorGroups.find(
                  ({ group }) => group === activeFilter
                );
                if (colorGroup) {
                  return colorGroup.colors.some((color) =>
                    value.includes(color)
                  );
                }
                return value === activeFilter;
              });
            }
            return activeFilters[`option.${filter}`].includes(value);
          });
          if (hasMatch) {
            optionMatches.push(filter);
          }
        }
      });
    }

    // filter by product tag
    const tagMatches = [];
    const tagFilters = Object.keys(activeFilters).reduce((tagsAcc, key) => {
      if (key.startsWith('tag.')) {
        return [...tagsAcc, key.replace('tag.', '')];
      }
      return tagsAcc;
    }, []);
    if (tagFilters.length) {
      tagFilters.forEach((filter) => {
        if (product.tags) {
          const hasMatch = product.tags.some((tag) => {
            const [_key, _value] = tag.split('::');
            const key = _key.trim();
            const value = _value?.trim();
            if (value && filter === key) {
              return activeFilters[`tag.${filter}`].includes(value);
            }
            return false;
          });
          if (hasMatch) {
            tagMatches.push(filter);
          }
        }
      });
    }
    const matches = [...optionMatches, ...tagMatches];
    if (matches.length >= minReqLength) {
      return [...acc, product];
    }
    return acc;
  }, []);

  // filter by product type
  const productTypeKey = 'productType';
  if (activeFilters[productTypeKey]?.length > 0) {
    filteredProducts = filteredProducts.filter(({ productType }) => {
      return activeFilters[productTypeKey].includes(productType);
    });
  }
  return filteredProducts;
};

export const getSortedProducts = ({ products = [], sortBy = '' }) => {
  switch (sortBy) {
    case 'default':
      return products;
    case 'recentlyAdded':
      return products.sort((prevProduct, nextProduct) => {
        return (
          new Date(nextProduct.publishedAt) - new Date(prevProduct.publishedAt)
        );
      });
    case 'highToLow':
      return products.sort((a, b) => {
        const aPrice = parseInt(a.priceRange?.max, 10);
        const bPrice = parseInt(b.priceRange?.max, 10);
        if (aPrice > bPrice) return -1;
        if (aPrice < bPrice) return 1;
        return 0;
      });
    case 'lowToHigh':
      return products.sort((a, b) => {
        const aPrice = parseInt(a.priceRange?.max, 10);
        const bPrice = parseInt(b.priceRange?.max, 10);
        if (aPrice > bPrice) return 1;
        if (aPrice < bPrice) return -1;
        return 0;
      });
    case 'aToZ':
      return products.sort((a, b) => a.title.localeCompare(b.title));
    case 'zToA':
      return products.sort((a, b) => b.title.localeCompare(a.title));
    default:
      return products;
  }
};

export const updateFilterUrlParams = ({
  entriesToAdd = [],
  keysToRemove = [],
}) => {
  const { origin, search, pathname } = window.location;
  const params = new URLSearchParams(search);
  entriesToAdd.forEach(([key, value]) => {
    params.set(key, value.join(','));
  });
  keysToRemove.forEach((key) => {
    params.delete(key);
  });
  const updatedUrl = `${origin}${pathname}?${params}`;
  window.history.replaceState(window.history.state, '', updatedUrl);
};

export const sortAlphabetically = ({ values = [] }) => {
  return values?.slice().sort((a, b) => {
    if (a.value < b.value) return -1;
    if (a.value > b.value) return 1;
    return 0;
  });
};

export const sortNumerically = ({ values = [] }) => {
  return values?.slice().sort((a, b) => {
    const aNum = parseFloat(a.value);
    const bNum = parseFloat(b.value);
    if (aNum < bNum) return -1;
    if (aNum > bNum) return 1;
    return 0;
  });
};

export const sortCustom = ({ values = [], sortOrder = [] }) => {
  if (!sortOrder?.length) return values;
  return values?.slice().sort((a, b) => {
    const _sortOrder = sortOrder.map((v) => v.trim());
    const aIndex = _sortOrder.indexOf(a.value);
    const bIndex = _sortOrder.indexOf(b.value);
    if (aIndex < bIndex) return -1;
    if (aIndex > bIndex) return 1;
    return 0;
  });
};

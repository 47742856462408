import { useSettings } from '@backpackjs/storefront';

export function SearchSuggestions({ setRawTerm, term }) {
  const settings = useSettings();
  const { noResultsText } = { ...settings?.search?.results };
  const { enabled, heading, terms } = { ...settings?.search?.suggestions };

  return (
    <div className="scrollbar-hide flex flex-1 flex-col gap-8 overflow-y-auto p-5 pt-0">
      {term && <h4 className="text-base font-normal">{noResultsText}</h4>}

      {enabled && terms?.length > 0 && (
        <div>
          <h4 className="text-title-h5 mb-3">{heading}</h4>

          <ul className="flex flex-col items-start gap-3">
            {terms.map((suggestion) => {
              return (
                <li key={suggestion}>
                  <button
                    aria-label={suggestion}
                    onClick={() => setRawTerm(suggestion)}
                    type="button"
                  >
                    <p className="hover-text-underline">{suggestion}</p>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

SearchSuggestions.displayName = 'SearchSuggestions';

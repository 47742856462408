import { useMemo } from 'react';

import { Image } from '../Image';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
import { QuantitySelector } from './QuantitySelector';
import { useCartItem } from './useCartItem';
import { useCartItemPrices } from './useCartItemPrices';

export function CartItem({ closeCart, isGiftWrap, isGWP, item }) {
  const { id, variant, quantity, discountAllocations } = item;

  const {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  } = useCartItem({ id, quantity });

  const { price, compareAtPrice } = useCartItemPrices({ item });

  const url = `/products/${variant.product.handle}`;

  const variantTitle = useMemo(() => {
    const options = variant?.selectedOptions?.reduce((obj, option) => {
      const name = option?.name?.toLowerCase();

      if (name === 'color') {
        const color = option.value?.split('-').map((word) => {
          return isNaN(parseFloat(word))
            ? word.slice(0, 1).toUpperCase() + word.slice(1)
            : '';
        });
        obj[name] = color.join(' ').trim();
      } else {
        obj[name] = option.value;
      }
      return obj;
    }, {});

    return options ? Object.values(options).join(' / ') : '';
  }, [variant]);

  return (
    <div className="relative grid grid-cols-[96px_1fr] items-center gap-5 py-5">
      <Link
        aria-label={`View ${variant.product.title}`}
        href={url}
        onClick={closeCart}
        tabIndex="-1"
      >
        <Image
          alt={variant.product.title}
          className="max-h-[120px] bg-offWhite object-cover"
          height="120"
          src={variant.image.src}
          width="96"
        />
      </Link>

      <div className="flex min-h-[6.25em] flex-col justify-between gap-4">
        <div className="flex justify-between">
          <div className="relative flex-1 pr-2">
            <h5 className="text-base tracking-normal">
              <Link
                aria-label={`View ${variant.product.title}`}
                href={url}
                onClick={closeCart}
              >
                {variant.product.title}
              </Link>
            </h5>

            {variantTitle !== 'Default Title' && (
              <p className="text-[13px]">{variantTitle}</p>
            )}
          </div>

          <div className="mb-6 flex flex-wrap justify-end gap-x-2">
            {compareAtPrice && (
              <p className="text-base line-through">{compareAtPrice}</p>
            )}
            <p className="text-base">{price}</p>
          </div>
        </div>

        <div className="flex items-center justify-between gap-3">
          <QuantitySelector
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            isUpdatingItem={isUpdatingItem}
            isGiftWrap={isGiftWrap}
            isGWP={isGWP}
            item={item}
          />

          <button
            aria-label={`Remove ${variant.product.title} from cart`}
            className="text-link-normal-sm"
            onClick={handleRemove}
            type="button"
          >
            Remove
          </button>
        </div>
      </div>

      {isRemovingItem && (
        <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)] text-mediumGray">
          <Spinner />
        </div>
      )}
    </div>
  );
}

CartItem.displayName = 'CartItem';

import { useEffect } from 'react';

// Custom hook
export const useLoopOnStore = () => {
  useEffect(() => {
    const initializeLoopOnStore = async () => {
      console.log('initializing loop on store');
      console.log(window?.LoopOnstore);
      console.log(window.LoopOnstore?.isActive);
      try {
        // Check if LoopOnstore exists and is not active
        if (window.LoopOnstore && !window.LoopOnstore.isActive) {
          await window.LoopOnstore.init({
            key: '257323e69d781eec726a40be0b4339d8c313c58e',
            attach: '#checkout-button', // checkout-button id
          });
          console.log('loop on store initialized');
        } else {
          console.log('loop on store already initialized');
          // window.LoopOnstore.testMode();
        }
      } catch (err) {
        console.log('error initializing loop on store', err);
      }
    };

    initializeLoopOnStore();
  }, []);
};

import { Spinner } from '../Spinner';
import { Svg } from '../Svg';

export function QuantitySelector({
  handleDecrement,
  handleIncrement,
  isUpdatingItem,
  isGiftWrap,
  isGWP,
  item,
}) {
  const { variant, quantity } = item;

  return (
    <div className="flex w-[88px] items-center justify-between border border-[var(--light-gray2)]">
      <button
        aria-label={`Reduce quantity of ${variant.product.title} by 1 to ${
          quantity - 1
        }`}
        className={`relative h-[32px] w-[34px] transition disabled:opacity-50 ${
          isGiftWrap || isGWP ? '!opacity-0' : ''
        }`}
        disabled={isUpdatingItem || isGiftWrap || isGWP}
        onClick={handleDecrement}
        type="button"
      >
        <Svg
          className="absolute top-1/2 left-1/2 w-3 -translate-x-1/2 -translate-y-1/2 text-text"
          src="/svgs/minus.svg#minus"
          title="Minus"
          viewBox="0 0 12 13"
        />
      </button>

      <div className="relative flex flex-1 items-center justify-center">
        {isUpdatingItem ? (
          <Spinner stroke="var(--gray)" />
        ) : (
          <p className="w-full text-center text-base outline-none">
            {quantity}
          </p>
        )}
      </div>

      <button
        aria-label={`Increase quantity of ${variant.product.title} by 1 to ${
          quantity + 1
        }`}
        className={`relative h-[32px] w-[34px] transition disabled:opacity-50 ${
          isGiftWrap || isGWP ? '!opacity-0' : ''
        }`}
        disabled={isUpdatingItem || isGiftWrap || isGWP}
        onClick={handleIncrement}
        type="button"
      >
        <Svg
          className="absolute top-1/2 left-1/2 w-3 -translate-x-1/2 -translate-y-1/2 text-text"
          src="/svgs/plus.svg#plus"
          title="Plus"
          viewBox="0 0 12 13"
        />
      </button>
    </div>
  );
}

QuantitySelector.displayName = 'QuantitySelector';

import React from 'react';

export function CartHelp() {
  return (
    <div className="flex flex-col">
      <h5 className="text-label mb-1.5">Easy To Return</h5>
      <p className="mb-2 text-sm text-mediumGray">
        7Diamonds offers complimentary returns and exchanges on all U.S. orders. To
        initiate a return, please visit <a href='https://7diamonds.loopreturns.com/#/'>7diamonds.com/returns</a>.
        We will gladly accept returns and exchanges within 30 days of receiving your order. 
        You can also initiate your return in the account dashboard under Order History,
        you will receive a pre-paid label via email.
      </p>
      <h5 className="text-label mb-1.5">Need Help?</h5>
      <p className="mb-2 text-sm text-mediumGray">
        Visit our FAQ page or call us at 877-577-1693 Monday through Friday from
        9:00am-5:00pm PT. Email us anytime at customerservice@7diamonds.com. If
        you contact us after business hours, we will get back to you the
        following business day.
      </p>
    </div>
  );
}

CartHelp.displayName = 'CartHelp';

import { BUTTON_STYLES, COLORS } from '@settings/common';

export function Schema() {
  return {
    category: 'Slider',
    label: 'Products Slider',
    key: 'products-slider',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/products-slider-preview.jpg?v=1675730335',
    fields: [
      {
        label: 'Text Settings',
        name: 'text',
        description: 'Heading, superheading, subheading, color, text alignment',
        component: 'group',
        fields: [
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
            defaultValue: 'Products Slider Heading',
          },
          {
            label: 'Enable Heading Divider',
            name: 'enableHeadingDivider',
            component: 'toggle',
            description: 'Displays divider below Heading',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Text Color',
            name: 'color',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Text Alignment (tablet/desktop)',
            name: 'textAlignmentDesktop',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'md:text-left md:items-start' },
              { label: 'Center', value: 'md:text-center md:items-center' },
              { label: 'Right', value: 'md:text-right md:items-end' },
            ],
            defaultValue: 'md:text-center md:items-center',
          },
          {
            label: 'Text Alignment (mobile)',
            name: 'textAlignmentMobile',
            component: 'radio-group',
            direction: 'horizontal',
            variant: 'radio',
            options: [
              { label: 'Left', value: 'text-left items-start' },
              { label: 'Center', value: 'text-center items-center' },
              { label: 'Right', value: 'text-right items-end' },
            ],
            defaultValue: 'text-center items-center',
          },
        ],
      },
      {
        label: 'Products',
        name: 'products',
        component: 'group-list',
        itemProps: {
          label: '{{item.product.handle}}',
        },
        fields: [
          {
            name: 'product',
            component: 'productSearch',
            label: 'Product',
          },
        ],
        defaultValue: [
          { handle: '' },
          { handle: '' },
          { handle: '' },
          { handle: '' },
        ],
      },
      {
        label: 'Button Settings',
        name: 'button',
        component: 'group',
        description: 'Button link, button styles',
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Button Style',
            name: 'style',
            component: 'select',
            options: BUTTON_STYLES,
          },
        ],
        defaultValue: {
          style: 'btn-primary',
        },
      },
      {
        label: 'Product Item Settings',
        name: 'productItem',
        component: 'group',
        description: 'Star rating, color variant selector, quick shop',
        fields: [
          {
            label: 'Enable Star Rating',
            name: 'enabledStarRating',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Color Variant Selector',
            name: 'enabledColorSelector',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Quick Shop',
            name: 'enabledQuickShop',
            component: 'toggle',
            description:
              'Note: Quick shop is hidden on mobile and will only show if the product item has only one variant or multiple variants through a single option, e.g. "Size',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          enabledStarRating: false,
          enabledColorSelector: false,
          enabledQuickShop: false,
        },
      },
      {
        label: 'Slider Settings',
        name: 'slider',
        component: 'group',
        description: 'Slider style, slides per view',
        fields: [
          {
            label: 'Slider Style',
            name: 'sliderStyle',
            component: 'select',
            description: 'Note: Full bleed and centered slides will loop',
            options: [
              { label: 'Contained', value: 'contained' },
              { label: 'Contained w/ Loop', value: 'containedWithLoop' },
              { label: 'Full Bleed and Centered', value: 'fullBleed' },
              {
                label: 'Full Bleed and Centered w/ Gradient',
                value: 'fullBleedWithGradient',
              },
            ],
          },
          {
            label: 'Slides Per View (desktop)',
            name: 'slidesPerViewDesktop',
            component: 'number',
          },
          {
            label: 'Slides Per View (tablet)',
            name: 'slidesPerViewTablet',
            component: 'number',
          },
          {
            label: 'Slides Per View (mobile)',
            name: 'slidesPerViewMobile',
            component: 'number',
          },
        ],
        defaultValue: {
          sliderStyle: 'contained',
          slidesPerViewDesktop: 4,
          slidesPerViewTablet: 3,
          slidesPerViewMobile: 2,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Full width',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width from contained styles',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          fullWidth: false,
        },
      },
    ],
  };
}

import { useEffect, useState } from 'react';
import { useLoopOnStore } from './useLoopOnStore';

export const useLoopParams = () => {
  const [loopOnStoreParams, setLoopOnStoreParams] = useState({});
  useLoopOnStore();
  useEffect(() => {
    let paramsObject = null;

    // Check if we already have saved params in localStorage
    const savedParams = localStorage.getItem('loopOnStoreParams');
    paramsObject = savedParams ? JSON.parse(savedParams) : null;

    // If URL has 'loop_total', handle URLSearchParams
    if (window.location.search.includes('loop_total')) {
      const params = new URLSearchParams(window.location.search);

      // Remove any non-loop related query params, then convert the query string
      // to an object of key/value pairs to make it easier to work with
      paramsObject = [...params.entries()]
        .filter(([key]) => key.startsWith('loop'))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    }

    // Save the paramsObject to localStorage
    localStorage.setItem('loopOnStoreParams', JSON.stringify(paramsObject));

    setLoopOnStoreParams(paramsObject);
    // Remove the loop params from the URL TODO: uncomment this
    // window.history.replaceState(
    //   {},
    //   '',
    //   `${window.location.origin}${window.location.pathname}`
    // );
  }, []); // Empty array ensures this effect runs only once after the initial render

  return { loopOnStoreParams };
};

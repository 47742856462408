import React, { useEffect } from 'react';
import Script from 'next/script';
import {
  useCart,
  useCartAddAttributes,
  useCartAddItem,
  useCartClear,
  useCurrency,
} from '@backpackjs/storefront';
import { useGlobalContext } from '@contexts';

function getPageType(resourceType) {
  const pageType = 'other';
  if (resourceType === 'home_page') {
    return 'home';
  }
  if (resourceType === 'product_page') {
    return 'product';
  }
  if (resourceType === 'collection_page') {
    return 'collection';
  }
  return pageType;
}

function maybe(f, def = undefined) {
  try {
    return f();
  } catch {
    return def;
  }
}

function transformCart(cart) {
  return cart
    ? {
        item_count: maybe(() => cart.lines.reduce((p, c) => p + c.quantity, 0)),
        attributes: cart?.attributes || [],
        items: cart.lines.map((l) => ({
          handle: l.variant.product.handle,
          price: maybe(() => parseInt(l.variant.priceV2.amount, 10)),
          product_id: maybe(() =>
            parseInt(
              l.variant.product.id.replace('gid://shopify/Product/', ''),
              10
            )
          ),
          quantity: l.quantity,
          variant_id: maybe(() =>
            parseInt(
              l.variant.id.replace('gid://shopify/ProductVariant/', ''),
              10
            )
          ),
        })),
        currency: maybe(
          () => parseInt(cart.estimatedCost.totalAmount.currencyCode, 10),
          0
        ),
        total_price: maybe(
          () => parseInt(cart.estimatedCost.totalAmount.amount, 10) * 100, // price in cents
          0
        ),
        token: maybe(() => cart.id.replace('gid://shopify/Cart/', ''), ''),
      }
    : undefined;
}

function useVisuallyIo({ product, resourceType }) {
  const currency = useCurrency();
  const cart = useCart();
  const { cartClear } = useCartClear();
  const { cartAddItem } = useCartAddItem();
  const { cartAddAttributes } = useCartAddAttributes();
  const pageType = getPageType(resourceType);
  const {
    actions: { openCart },
  } = useGlobalContext();

  useEffect(() => {
    maybe(() => window.visually.onCartChanged(transformCart(cart)));
  }, [cart]);

  useEffect(() => {
    maybe(() =>
      window.visually.visuallyConnect({
        cartClear,
        customerTags: [],
        initialProductId: maybe(() =>
          product.id.replace('gid://shopify/Product/', '')
        ),
        initialVariantPrice: maybe(() =>
          parseInt(product.variants[0].priceV2.amount, 10)
        ),
        initialVariantId: maybe(() =>
          product.variants[0].replace('gid://shopify/ProductVariant/', '')
        ),
        addToCart: (variantId, quantity) =>
          cartAddItem({
            merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
            quantity,
          }),
        cartAddAttributes,
        openCartDrawer: openCart,
        pageType,
        initialCurrency: currency,
        initialLocale: 'en-US',
      })
    );
  }, [pageType, currency, product]);
}

export function VisuallyIo({ page, product }) {
  useVisuallyIo({
    product,
    resourceType: page?.resourceType,
  });
  return (
    <>
      <Script
        id="visually-io"
        strategy="beforeInteractive"
        rel="preconnect prefetch"
        src={`https://sdk.loomi-prod.xyz/widgets/vsly-preact.min.js?k=${
          process.env.NEXT_PUBLIC_VISUALLY_API_KEY
        }&e=2&s=${process.env.NEXT_PUBLIC_SITE_TITLE.toUpperCase()}`}
      />
      <Script
        id="visually-io-spa"
        strategy="beforeInteractive"
        rel="preconnect prefetch"
        src="https://sdk.loomi-prod.xyz/v/visually-spa.js"
      />
      <Script
        strategy="afterInteractive"
        src="https://sdk.loomi-prod.xyz/v/visually-a-spa.js"
      />
      <span />
    </>
  );
}

import { ArticleHead } from './ArticleHead';
import { BlogHead } from './BlogHead';
import { CollectionHead } from './CollectionHead';
import { MainHead } from './MainHead';
import { ProductHead } from './ProductHead';

export function Seo(props) {
  const baseUrl = process.env.SITE_URL;
  const { article, blog, collection, page, product } = props;

  let resourceType = page?.resourceType;
  let pageUrl = '';
  let seo = page?.seo;

  if (page?.resourceType === 'home_page') {
    pageUrl = baseUrl;
  } else if (page?.resourceType === 'page') {
    pageUrl = `${baseUrl}/pages/${page.handle}`;
  } else if (page?.resourceType === 'product_page') {
    pageUrl = `${baseUrl}/products/${page.handle}`;
  } else if (page?.resourceType === 'collection_page') {
    pageUrl = `${baseUrl}/collections/${page.handle}`;
  } else if (article) {
    pageUrl = `${baseUrl}/articles/${article.handle}`;
    resourceType = 'article';
    seo = article.seo;
  } else if (blog) {
    pageUrl = `${baseUrl}/blogs/${blog.handle}`;
    resourceType = 'blog';
    seo = blog.seo;
  } else {
    pageUrl = baseUrl;
  }

  return (
    <>
      <MainHead pageUrl={pageUrl} seo={seo} />

      {resourceType === 'article' && (
        <ArticleHead article={article} pageUrl={pageUrl} />
      )}

      {resourceType === 'blog' && <BlogHead blog={blog} pageUrl={pageUrl} />}

      {resourceType === 'collection_page' && (
        <CollectionHead collection={collection} pageUrl={pageUrl} />
      )}

      {resourceType === 'product_page' && (
        <ProductHead product={product} pageUrl={pageUrl} />
      )}
    </>
  );
}

Seo.displayName = 'Seo';

import { useState, useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { Image } from '../Image';
import { Link } from '../Link';

export function MenuDrawer({
  handleMenuDrawerClose,
  handleMenuHoverIn,
  handleMenuHoverOut,
  menuDrawerContent,
}) {
  const { links } = { ...menuDrawerContent };
  const hasContent = links?.length > 0;
  const maxNumberOfImages = menuDrawerContent?.links?.reduce((carry, link) => {
    const numberOfImages = link?.images?.length || 0;
    if (carry > numberOfImages) return carry;
    return numberOfImages;
  }, 0);
  const menuItem = menuDrawerContent?.menuItem;
  const isWomens = menuItem?.text?.toLowerCase().startsWith('women');
  const isMens = menuItem?.text?.toLowerCase().startsWith('men');
  const isWomenOrMen = isWomens || isMens;
  const settings = useSettings();
  const { collectionLinksMen, collectionLinksWomen, hideCollectionLinks } = {
    ...settings?.header?.menu,
  };
  const containsNoSubLinks = links?.every(
    (menuLink) => !menuLink?.sublinks?.length
  );

  const [activeHoveredLink, setActiveHoveredLink] = useState(null);

  useEffect(() => {
    setActiveHoveredLink(menuDrawerContent?.links?.[0]);
  }, [menuDrawerContent?.links]);

  return (
    <div
      className={`absolute left-0 top-[100%] hidden w-full origin-top border-border bg-background transition duration-200 lg:block ${
        hasContent ? 'scale-y-100 border-b' : 'scale-y-0'
      }`}
      onMouseEnter={() => handleMenuHoverIn(menuDrawerContent)}
      onMouseLeave={handleMenuHoverOut}
    >
      {hasContent && (
        <div className="mx-[auto] flex max-w-[var(--content-max-width)] justify-center py-8 px-4 xl:p-12">
          <div
            className={`flex flex-col justify-start
             ${containsNoSubLinks ? 'max-w-[30%]' : 'max-w-[80%]'}`}
          >
            <ul
              className={`flex gap-x-[4.5rem] ${
                containsNoSubLinks ? 'flex-col' : 'gap-y-4'
              }`}
            >
              {links?.map((menuLink, index) => {
                const { link, sublinks } = menuLink;
                return (
                  <div
                    key={index}
                    className="flex flex-col"
                    onMouseEnter={() => setActiveHoveredLink(menuLink)}
                  >
                    <li className="mb-4" key={index}>
                      <Link
                        aria-hidden={!hasContent}
                        aria-label={link?.text}
                        className={`hover-text-underline ${
                          containsNoSubLinks ? 'text-nav-sm' : 'text-nav'
                        }`}
                        href={link?.url}
                        newTab={link?.newTab}
                        onClick={handleMenuDrawerClose}
                        tabIndex={hasContent ? '0' : '-1'}
                      >
                        {link?.text}
                      </Link>
                    </li>
                    {!!sublinks?.length && (
                      <ul className="flex flex-col gap-y-4 gap-x-[4.5rem]">
                        {sublinks.map(({ link }, subLinkIndex) => {
                          return (
                            <li key={subLinkIndex}>
                              <Link
                                aria-hidden={!hasContent}
                                aria-label={link?.text}
                                className="hover-text-underline text-nav-sm"
                                href={link?.url}
                                newTab={link?.newTab}
                                onClick={handleMenuDrawerClose}
                                tabIndex={hasContent ? '0' : '-1'}
                              >
                                {link?.text}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                );
              })}
            </ul>

            {!!(isWomenOrMen && !hideCollectionLinks) && (
              <div className="mt-5 flex items-center gap-x-[1.5rem]">
                <p className="text-nav">Shop By Collection</p>

                {!!isMens && (
                  <ul className="flex flex-wrap gap-y-4 gap-x-[1.5rem]">
                    {collectionLinksMen?.map(({ link }, index) => {
                      if (!link) return null;
                      return (
                        <li key={index}>
                          <Link
                            aria-hidden={!hasContent}
                            aria-label={link?.text}
                            className="hover-text-underline text-nav-sm"
                            href={link?.url}
                            newTab={link?.newTab}
                            onClick={handleMenuDrawerClose}
                            tabIndex={hasContent ? '0' : '-1'}
                          >
                            {link?.text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}

                {!!isWomens && (
                  <ul className="flex flex-wrap gap-y-4 gap-x-[1.5rem]">
                    {collectionLinksWomen?.map(({ link }, index) => {
                      if (!link) return null;
                      return (
                        <li key={index}>
                          <Link
                            aria-hidden={!hasContent}
                            aria-label={link?.text}
                            className="hover-text-underline text-nav-sm"
                            href={link?.url}
                            newTab={link?.newTab}
                            onClick={handleMenuDrawerClose}
                            tabIndex={hasContent ? '0' : '-1'}
                          >
                            {link?.text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            )}
          </div>

          <ul className="ml-[4.5rem] flex gap-5 border-l border-l-black pl-[4.5rem]">
            {activeHoveredLink?.images?.map(
              ({ alt, caption, image, link }, index) => {
                return (
                  <li className="w-[230px]" key={index}>
                    {image?.src && (
                      <Link
                        aria-hidden={!hasContent}
                        aria-label={caption}
                        href={link?.url}
                        newTab={link?.newTab}
                        onClick={handleMenuDrawerClose}
                        tabIndex={hasContent ? '0' : '-1'}
                      >
                        <div className="relative aspect-[3/3.25] w-full bg-offWhite">
                          <Image src={image.src} alt={alt} fill sizes="460px" />
                        </div>

                        <p className="text-nav-sm mt-3">{caption}</p>
                      </Link>
                    )}
                  </li>
                );
              }
            )}
            {/* print dummy items if hovered link has less than the max number of images per link to prevent awkward ui */}
            {maxNumberOfImages - (activeHoveredLink?.images?.length || 0) > 0 &&
              [
                ...Array(
                  maxNumberOfImages - (activeHoveredLink?.images?.length || 0)
                ).keys(),
              ].map((index) => {
                return <li className="w-[230px]" key={index} />;
              })}
          </ul>
        </div>
      )}
    </div>
  );
}

MenuDrawer.displayName = 'MenuDrawer';

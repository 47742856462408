import { useMemo, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useProductByHandle } from '@backpackjs/storefront';
import { Svg } from '@snippets';
import { useMediaPredicate } from 'react-media-hook';

import { features } from './ProductFeatures.features';
import { Schema } from './ProductFeatures.schema';

export function ProductFeatures({ cms }) {
  const { productTypes } = cms;
  const [isOpen, setIsOpen] = useState(isDesktop);
  const router = useRouter();
  const handle = router?.query?.handle;
  const { product } = useProductByHandle({ handle });
  const isDesktop = useMediaPredicate('(min-width: 768px)');
  
  useEffect(() => {
    setIsOpen(isDesktop);
  }, [isDesktop]);


  const productFeature = useMemo(() => {
    if (!product || !productTypes) return null;
    return productTypes.find((item) => {
      if (!Array.isArray(item?.tags)) return false;
      return item?.tags?.some((tag) => product.tags.includes(tag.trim()));
    });
  }, [product, productTypes]);

  if (!productFeature) return null;

  const { headingOverride, productFeatures: _productFeatures } = productFeature;

  return (
    <div className="border-b border-[var(--border)]">
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for fabrication`}
        className="flex h-11 w-full items-center justify-between py-3 md:h-fit md:py-4"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <h5 className="text-nav md:text-xl">
          {headingOverride || 'fabrication'}
        </h5>

        {isOpen ? (
          <Svg
            className="w-[0.875rem] text-current"
            src="/svgs/minus.svg#minus"
            title="Minus"
            viewBox="0 0 12 13"
          />
        ) : (
          <Svg
            className="w-[0.875rem] text-current"
            src="/svgs/plus.svg#plus"
            title="Plus"
            viewBox="0 0 12 13"
          />
        )}
      </button>

      <div
        className={`product-detail-accordions pb-6 ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <ul>
          {_productFeatures?.map((item) => {
            const { poster, heading: itemHeading, description } = item;

            const content = {
              ...features[poster],
              heading: itemHeading || features[poster]?.heading,
              description: description || features[poster]?.description,
            };

            return (
              <li>
                {content?.description && (
                  <>
                    <strong>{content?.heading}:</strong> {content.description}
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

ProductFeatures.displayName = 'ProductFeatures';
ProductFeatures.Schema = Schema;

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useLoopCart } from './useLoopCart';
import { useLoopParams } from './useLoopParams';

export const useLoopShopNow = ({ cartItems, cartClear }) => {
  const router = useRouter();
  const { loopOnStoreParams } = useLoopParams();
  const { createLoopCart, getLoopCart, updateLoopCart } = useLoopCart();
  const [isLoopShopNow, setIsLoopShopNow] = useState(false);

  useEffect(() => {
    const loopCartValues = localStorage.getItem('loopCart');
    const loopCart = loopCartValues ? JSON.parse(loopCartValues) : null;

    const updateLocalStorage = ({ cart, cartToken }) => {
      const loopCartComplete = {
        cartToken: loopCart?.cartToken || cartToken,
        loop_return_id: loopOnStoreParams?.loop_return_id,
        cart,
      };
      localStorage.setItem('loopCart', JSON.stringify(loopCartComplete));
      setIsLoopShopNow(true);
    };

    const handleCartOperations = async () => {
      try {
        if (
          loopCart?.loop_return_id &&
          loopOnStoreParams?.loop_total &&
          loopCart?.cartToken
        ) {
          if (loopCart?.cart) {
            const cart = await updateLoopCart({
              cartToken: loopCart.cartToken,
              cartItems,
            });
            updateLocalStorage({ cart });
          } else {
            const { cart } = await getLoopCart({
              cartToken: loopCart.cartToken,
            });
            updateLocalStorage({ cart });
          }
        } else if (
          loopOnStoreParams?.loop_return_id &&
          loopOnStoreParams?.loop_total &&
          !loopCart?.cartToken
        ) {
          const { cartToken } = await createLoopCart({ cartItems });
          updateLocalStorage({ cartToken });
        }
      } catch (err) {
        console.error(err);
        setIsLoopShopNow(false);
      }
    };

    handleCartOperations();
  }, [
    cartItems,
    loopOnStoreParams,
    createLoopCart,
    getLoopCart,
    updateLoopCart,
  ]);

  const finishLoopShopNowTransaction = async () => {
    const loopCartValues = localStorage.getItem('loopCart');
    const loopCart = loopCartValues ? JSON.parse(loopCartValues) : null;
    localStorage.setItem('loopOnStoreParams', null);
    localStorage.setItem('loopCart', null);
    await cartClear();
    setIsLoopShopNow(false);
    router.push(
      `https://7diamonds.loopreturns.com/#/cart/v2/${loopCart.cartToken}`
    );
  };

  const returnToLoopReturns = async (url) => {
    // router.push(`https://${url}`); // TODO: Ask if they want to open return to loop returns in a new tab
  };

  const getCurrentCredit = (number, currency) => {
    if (!number || !currency) return null;
    const formattedPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    }).format(number / 100);
    return formattedPrice;
  };

  return {
    isLoopShopNow,
    totalCredit: `Total Credit: ${getCurrentCredit(
      Number(loopOnStoreParams?.loop_total).toFixed(2),
      loopOnStoreParams?.loop_currency
    )}`,
    finishLoopShopNowTransaction,
  };
};

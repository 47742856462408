import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { Svg } from '../Svg';
import { CollectionFilters } from './CollectionFilters';
import { CollectionGrid } from './CollectionGrid';
import { CollectionPairs } from './CollectionPairs';
import { CollectionSort } from './CollectionSort';
import { useCollectionFilters } from './useCollectionFilters';
import { useCollectionProducts } from './useCollectionProducts';

export function Collection({
  collectionHero,
  handle,
  products,
  productsReady,
  isSearch,
  title,
}) {
  const router = useRouter();
  const settings = useSettings();
  const {
    filters: filtersSettings,
    sort: sortSettings,
    pagination,
    promotion,
    collectionPairs: collectionPairsSettings,
  } = { ...settings?.collection };

  const enabledFilters = filtersSettings?.enabled;
  const enabledSort = sortSettings?.enabled;
  const isSearchPage = handle === 'search';
  const isCollectionPage = router.asPath.includes('collections');

  const promoTiles = useMemo(() => {
    if (!promotion?.campaigns?.length) return null;
    const campaign = promotion.campaigns.find(({ collections, enabled }) => {
      if (enabled) {
        return collections?.some((colHandle) => colHandle.trim() === handle);
      }
    });
    return campaign?.promoTiles || null;
  }, [handle, promotion?.campaigns]);

  const collectionFiltersData = useCollectionFilters({
    enabledFilters,
    products,
    isSearchPage,
  });

  const {
    state: { isOpen: filterIsOpen },
    actions: { setIsOpen: setFilterIsOpen },
  } = collectionFiltersData;

  const collectionProductsData = useCollectionProducts({
    activeFilters: collectionFiltersData.state.activeFilters,
    handle,
    pagination,
    products,
    productsReady,
    promoTiles,
    colorGroups: filtersSettings?.colorGroups,
  });

  const newTitle = useMemo(() => {
    if (!title) return;
    const splitArr = title?.split(' ');

    const collectionIndex = splitArr?.findIndex((str) =>
      str?.includes('\u2122')
    );

    if (collectionIndex === -1) return;

    const collectionWithTm = splitArr?.[collectionIndex];

    const collectionName = collectionWithTm?.slice(
      0,
      collectionWithTm.length - 1
    );

    const trademark = `${collectionWithTm?.slice(
      collectionWithTm.length - 1,
      collectionWithTm.length
    )} `;

    const titleBegin = [
      ...splitArr?.slice(0, collectionIndex),
      collectionName,
    ].join(' ');

    const titleEnd = [
      ...splitArr?.slice(collectionIndex + 1, splitArr?.length),
    ].join(' ');

    return { titleBegin, trademark, titleEnd };
  }, [title]);

  return (
    <div
      className={`mx-auto grid grid-cols-2 ${
        enabledFilters
          ? 'md:grid-cols-[13rem_1fr] md:gap-x-6'
          : 'md:grid-cols-1'
      }`}
    >
      {!isSearch && (
        <>
          {newTitle ? (
            <h1 className="col-span-2 uppercase text-title-h4 max-md:mb-4 max-md:mt-8 md:mb-6 md:whitespace-nowrap md:text-center">
              {newTitle?.titleBegin}
              <span className="text-base leading-8 align-super lg:leading-9">
                {newTitle?.trademark}
              </span>
              {newTitle?.titleEnd}
            </h1>
          ) : (
            <h1 className="col-span-2 uppercase text-title-h4 max-md:mb-4 max-md:mt-8 md:mb-6 md:whitespace-nowrap md:text-center">
              {title}
            </h1>
          )}
        </>
      )}

      <div
        className={`col-span-2 mx-3 md:-mx-5 ${
          filterIsOpen || collectionProductsData.state.isOpen
            ? 'max-md:border-b'
            : 'max-md:border-b max-md:border-white'
        }`}
      >
        <div className="relative flex flex-wrap justify-between -mx-3 md:mx-0">
          {enabledFilters && (
            <div>
              <button
                aria-label="Open Filter Dropdown"
                className="flex items-center w-full h-12 gap-3 p-3 text-base md:gap-4 md:px-5"
                onClick={() => setFilterIsOpen(!filterIsOpen)}
                type="button"
              >
                <p className="text-[0.8125rem] font-semibold uppercase">
                  Filter
                </p>

                <Svg
                  className={`w-3 text-text md:w-4 ${
                    filterIsOpen ? 'rotate-180' : ''
                  }`}
                  src="/svgs/chevron-down.svg#chevron-down"
                  title="Chevron"
                  viewBox="0 0 24 24"
                />
              </button>
            </div>
          )}

          <div className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 max-md:hidden">
            {!isSearch && (
              <CollectionPairs
                collectionPairsSettings={collectionPairsSettings}
              />
            )}
          </div>

          {enabledSort && (
            <div>
              <CollectionSort
                collectionFiltersData={collectionFiltersData}
                isOpen={collectionProductsData.state.isOpen}
                setIsOpen={collectionProductsData.actions.setIsOpen}
                selectedSort={collectionProductsData.state.selectedSort}
                selectSort={collectionProductsData.actions.selectSort}
              />
            </div>
          )}
        </div>
      </div>

      {enabledFilters && (
        <div className="col-span-2">
          <CollectionFilters
            collectionFiltersData={collectionFiltersData}
            collectionCount={products?.length}
          />
        </div>
      )}

      <div className="col-span-2 md:mt-4">
        <CollectionGrid
          activeFilters={collectionFiltersData.state.activeFilters}
          collectionHero={collectionHero}
          collectionProductsData={collectionProductsData}
          enabledFilters={enabledFilters}
          isCollectionPage={isCollectionPage}
          isSearchPage={isSearchPage}
          promoTiles={promoTiles}
        />
      </div>
    </div>
  );
}

Collection.displayName = 'Collection';
Collection.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

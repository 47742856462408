import PropTypes from 'prop-types';

import { BackInStockModal } from './BackInStockModal';
import { Spinner } from '../Spinner';
import { useAddToCart } from './useAddToCart';

export function AddToCart({
  addToCartText,
  className,
  isPdp,
  isGWP,
  selectedVariant,
}) {
  const {
    state: { buttonText, isAdding, isLoading, isNotifyMe, isSoldOut, subtext },
    actions: { handleAddToCart },
  } = useAddToCart({
    addToCartText,
    selectedVariant,
  });

  let cursorClass = '';
  if (isAdding) cursorClass = 'cursor-default';
  else if (isLoading) cursorClass = 'cursor-wait';

  if (isNotifyMe) {
    return <BackInStockModal selectedVariant={selectedVariant} />;
  }

  return (
    <div>
      <button
        aria-label={buttonText}
        className={`btn-addtocart btn-primary relative w-full ${cursorClass} ${className}`}
        disabled={isSoldOut && !isNotifyMe}
        onClick={() => {
          const attributes = [];
          if (isGWP) {
            attributes.push({ key: '_gwp', value: 'true' });
          }
          handleAddToCart({ attributes });
        }}
        type="button"
      >
        {isAdding ? (
          <Spinner />
        ) : (
          <p
            className={`font-semibold transition ${
              isLoading ? 'opacity-30' : ''
            }`}
          >
            {buttonText}
          </p>
        )}
      </button>

      {isPdp && subtext && (
        <p className="mt-1 text-center text-xs">{subtext}</p>
      )}
    </div>
  );
}

AddToCart.displayName = 'AddToCart';
AddToCart.propTypes = {
  addToCartText: PropTypes.string,
  className: PropTypes.string,
  isPdp: PropTypes.bool,
  selectedVariant: PropTypes.object,
};

import { useEffect, useState } from 'react';

import { useGlobalContext } from '@contexts';
import { useSelectedVariant } from '@hooks';

import { ProductDetails } from './ProductDetails';
import { ProductHeader } from './ProductHeader';
import { ProductMedia } from './ProductMedia';
import { ProductMediaMobile } from './ProductMedia/ProductMediaMobile';

export function QuickshopProductMain() {
  const {
    state: { modalProduct },
    actions: { closeOverlay, closeModal },
  } = useGlobalContext();

  const [product, setProduct] = useState(modalProduct);

  const {
    selectedVariant,
    setSelectedOptions,
    selectedOptions,
    selectedMedia,
  } = useSelectedVariant({ product, isModal: true });

  const onResize = () => {
    if (window && window.innerWidth < 1024) {
      closeModal();
      closeOverlay();
    }
  };

  useEffect(() => {
    setProduct(modalProduct);
  }, [modalProduct?.id]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  if (!product) return null;

  return (
    <div className="px-contained grid gap-y-10 py-6 md:py-10 lg:py-12">
      <div className="mx-auto grid grid-cols-1 gap-y-5 md:gap-y-4 lg:grid-cols-[1fr_1.2fr]">
        {/* Extra div needed for sticky effect */}
        <div>
          <div className="hidden md:sticky md:block">
            <ProductMedia
              product={product}
              selectedMedia={selectedMedia}
              selectedVariant={selectedVariant}
            />
          </div>
          <div className="max-md:-mx-3 md:hidden">
            <ProductMediaMobile
              selectedMedia={selectedMedia}
              selectedVariant={selectedVariant}
            />
          </div>
        </div>

        {/* Extra div needed for sticky effect */}
        <div>
          <div className="flex flex-col gap-y-4 md:sticky md:px-4 lg:px-10 xl:px-16">
            <ProductHeader
              product={product}
              selectedVariant={selectedVariant}
            />

            <div className="hidden md:block">
              <ProductDetails
                isModal
                product={product}
                selectedOptions={selectedOptions}
                selectedVariant={selectedVariant}
                setSelectedOptions={setSelectedOptions}
              />
            </div>
          </div>
        </div>

        <div className="md:hidden">
          <ProductDetails
            isModal
            product={product}
            selectedOptions={selectedOptions}
            selectedVariant={selectedVariant}
            setSelectedOptions={setSelectedOptions}
          />
        </div>
      </div>
    </div>
  );
}

QuickshopProductMain.displayName = 'QuickshopProductMain';

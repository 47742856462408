import { useMediaPredicate } from 'react-media-hook';
import { CollectionDropdownFilter } from './CollectionDropdownFilter';

export function CollectionFilters({ collectionCount, collectionFiltersData }) {
  const isDesktop = useMediaPredicate('(min-width: 768px)');
  const {
    state: { activeFilters, filters, isOpen },
    actions: { addFilter, removeFilter, clearFilters, setIsOpen },
  } = collectionFiltersData;

  return (
    <div>
      {isOpen && (
        <div
          className={`max-md:px-contained flex flex-col pb-8 ${
            isOpen ? 'border-t border-[var(--border-light)] md:py-10' : ''
          }`}
        >
          <ul className="flex flex-col justify-center border-b border-border md:flex-row md:gap-x-[94px] md:border-none">
            {filters.map((filter, index) => {
              if (
                !filter.values.length ||
                (filter.values.length === 1 &&
                  filter.values[0].count === collectionCount)
              )
                return null;

              return (
                <li key={index}>
                  <CollectionDropdownFilter
                    activeFilters={activeFilters}
                    addFilter={addFilter}
                    defaultOpen={filter.defaultOpen}
                    index={index}
                    filter={filter}
                    removeFilter={removeFilter}
                  />
                </li>
              );
            })}
          </ul>

          <div className="flex justify-center gap-x-2 border-t border-[var(--border-light)] pt-6 md:gap-x-6 md:pt-8">
            <button
              type="button"
              className="btn-primary h-[30px] w-full text-[0.8125rem] font-medium normal-case md:w-[80px]"
              onClick={() => setIsOpen(false)}
            >
              Done
            </button>
            <button
              type="button"
              className={`h-[30px] text-sm font-medium normal-case ${
                isDesktop ? 'w-auto underline' : 'btn-inverse-dark w-full'
              }`}
              onClick={() => clearFilters()}
            >
              Clear
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

CollectionFilters.displayName = 'CollectionFilters';

import { Image, Link, Svg } from '@snippets';
import { useSettings } from '@backpackjs/storefront';
import { SubmenuSidebar } from './SubmenuSidebar';

export function MenuNestedSidebar({
  handleCloseSidebar,
  nestedSidebarContent,
  handleNestedSidebar,
  handleSubmenuSidebar,
  submenuSidebarContent,
}) {
  const { links, menuItem } = { ...nestedSidebarContent };

  const settings = useSettings();
  const { collectionLinksMen, collectionLinksWomen, hideCollectionLinks } = {
    ...settings?.header?.menu,
  };
  const isWomens = menuItem?.text?.toLowerCase().startsWith('women');
  const isMens = menuItem?.text?.toLowerCase().startsWith('men');
  const isWomenOrMen = isWomens || isMens;
  const collectionLinks = isWomenOrMen
    ? isWomens
      ? collectionLinksWomen
      : collectionLinksMen
    : null;

  return (
    <div
      data-comp={MenuNestedSidebar.displayName}
      className={`scrollbar-hide absolute left-0 top-0 z-[1] h-full w-full bg-background ${
        nestedSidebarContent ? 'visible' : 'invisible'
      }`}
    >
      <div className="scrollbar-hide h-full w-full overflow-y-auto">
        <button
          aria-label="Go back to main menu"
          className="sticky top-0 z-[1] flex w-full items-center justify-between gap-3 border-b border-b-border bg-background pb-2"
          onClick={() => handleNestedSidebar(null)}
          type="button"
        >
          <Svg
            className="w-3 pb-[2px] text-[#979797]"
            src="/svgs/arrow-left.svg#arrow-left"
            title="Arrow Left"
            viewBox="0 0 24 24"
          />

          <h3 className="text-nav flex-1 text-left font-bold">Main Menu</h3>
        </button>

        <div className="px-0 pt-4">
          <ul className="mb-0 flex flex-col gap-3">
            {links?.map((item, index) => {
              const hasContent = item?.sublinks?.length > 0;
              return (
                <li key={index}>
                  {hasContent ? (
                    <button
                      aria-label={item.link.text}
                      className="flex w-full items-center justify-between gap-3"
                      onClick={() => handleSubmenuSidebar(index)}
                      type="button"
                    >
                      <p className="text-nav flex-1 text-left leading-[1.375rem]">
                        {item.link.text}
                      </p>
                    </button>
                  ) : (
                    <>
                      <Link
                        aria-label={item.link.text}
                        className="text-nav flex-1 text-left capitalize"
                        href={item.link.url}
                        onClick={handleCloseSidebar}
                        newTab={item.link.newTab}
                        type={item.link.type}
                      >
                        {item.link.text}
                      </Link>

                      {item?.imagesMobile?.length > 0 && (
                        <ul className="mt-3 grid grid-cols-2 gap-2">
                          {item?.imagesMobile?.map(
                            ({ alt, caption, image, link }, index) => {
                              return (
                                <li key={index} className="h-full w-full">
                                  <Link
                                    aria-label={caption}
                                    href={link?.url}
                                    newTab={link?.newTab}
                                    onClick={handleCloseSidebar}
                                    type={link?.type}
                                    className="h-full w-full"
                                  >
                                    <div className="relative aspect-[3/4] w-full overflow-hidden bg-offWhite">
                                      {image?.src && (
                                        <Image
                                          alt={alt}
                                          className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
                                          crop="center"
                                          height={Math.floor(768 / (3 / 4))}
                                          src={image.src}
                                          width="768"
                                        />
                                      )}
                                    </div>

                                    <p className="text-nav mt-1 capitalize leading-[1.375rem]">
                                      {caption}
                                    </p>
                                  </Link>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      )}
                    </>
                  )}
                </li>
              );
            })}
          </ul>

          {!!(isWomenOrMen && !hideCollectionLinks) && (
            <div className="mt-24 flex flex-col gap-3">
              <p className="text-nav font-bold leading-[1.375rem]">
                Shop By Collection
              </p>

              <ul className="flex flex-col gap-3">
                {collectionLinks?.map(({ link }, index) => {
                  if (!link) return null;
                  return (
                    <li key={index}>
                      <Link
                        aria-label={link?.text}
                        className="text-nav capitalize"
                        href={link?.url}
                        newTab={link?.newTab}
                        onClick={handleCloseSidebar}
                      >
                        {link?.text}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>

      <SubmenuSidebar
        handleCloseSidebar={handleCloseSidebar}
        handleSubmenuSidebar={handleSubmenuSidebar}
        submenuSidebarContent={submenuSidebarContent}
        nestedSidebarContent={nestedSidebarContent}
      />
    </div>
  );
}

MenuNestedSidebar.displayName = 'MenuNestedSidebar';

import Script from 'next/script';

import { useDataLayerAccount } from './useDataLayerAccount';
import { useDataLayerCart } from './useDataLayerCart';
import { useDataLayerCollection } from './useDataLayerCollection';
import { useDataLayerCustomer } from './useDataLayerCustomer';
import { useDataLayerInit } from './useDataLayerInit';
import { useDataLayerProduct } from './useDataLayerProduct';
import { useDataLayerSearch } from './useDataLayerSearch';

// Debug via Elevar's Data Layer Listener, add to the console:
// Turn on: window.ElevarGtmSuiteListener.utils.debug(true)
// Turn off: window.ElevarGtmSuiteListener.utils.debug(false)

// Important, make sure to either:
// * add env var NEXT_PUBLIC_SITE_TITLE;
// * or, harcode site title as the "brand" values in the "utils.js" file

export function DataLayer({ product }) {
  const { generateUserProperties, userProperties } = useDataLayerInit();

  const { userDataEvent, userDataEventTriggered } = useDataLayerCustomer({
    userProperties,
  });

  useDataLayerAccount({
    generateUserProperties,
    userDataEvent,
    userDataEventTriggered,
  });

  useDataLayerCart({
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerProduct({
    product,
    userDataEvent,
    userProperties,
  });

  useDataLayerCollection({
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  useDataLayerSearch({
    userDataEvent,
    userDataEventTriggered,
    userProperties,
  });

  return (
    <>
      {/* Comment back in once process.env.NEXT_PUBLIC_GTM_CONTAINER_ID is defined  */}
      {/* <Script
        id="gtm-script"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_CONTAINER_ID}');`,
        }}
      /> */}
      <Script
        id="elevar-dl-listener-config"
        type="application/json"
        dangerouslySetInnerHTML={{
          __html: `{"data_layer_listener_script": "https://shopify-gtm-suite.getelevar.com/shops/277a503835c54da52a2ee30c1cebe66252f0f692/3.1.9/events.js", "ss_url": "https://hits.getelevar.com", "signing_key": "${process.env.NEXT_PUBLIC_ELEVAR_SIGNING_KEY}", "myshopify_domain": "${process.env.SHOPIFY_DOMAIN}"}`,
        }}
      />
      <Script
        id="elevar-dl-listener"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (() => {
              const configElement = document.getElementById("elevar-dl-listener-config");

              if (!configElement) {
                console.error("Elevar: DLL Config element not found");
                return;
              }

              const config = JSON.parse(configElement.textContent);

              const script = document.createElement("script");
              script.type = "text/javascript";
              script.src = config.data_layer_listener_script;

              script.onerror = function () {
                console.error("Elevar: DLL JS script failed to load");
              };
              script.onload = function () {
                if (!window.ElevarGtmSuiteListener) {
                  console.error("Elevar: \`ElevarGtmSuiteListener\` is not defined");
                  return;
                }

                window.ElevarGtmSuiteListener.handlers.listen({
                  ssUrl: config.ss_url,
                  signingKey: config.signing_key,
                  myshopifyDomain: config.myshopify_domain
                });
              };

              document.head.appendChild(script, document.currentScript);
            })();
          `,
        }}
      />
      <Script
        id="elevar-dl-aat-config"
        type="application/json"
        dangerouslySetInnerHTML={{
          __html: `{"data_layer_aat_script": "https://shopify-gtm-suite.getelevar.com/shops/277a503835c54da52a2ee30c1cebe66252f0f692/3.1.9/aat.js", "apex_domain": "${process.env.SITE_URL?.split(
            '//'
          ).pop()}", "consent_enabled": false}`,
        }}
      />
      <Script
        id="elevar-dl-aat-listener"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (() => {
              const init = () => {
                window.__ElevarDataLayerQueue = [];
                window.__ElevarListenerLoadQueue = [];
                if (!window.dataLayer) window.dataLayer = [];
              };
              init();
              window.__ElevarTransformItem = (event) => {
                if (typeof window.ElevarTransformFn === "function") {
                  try {
                    const result = window.ElevarTransformFn(event);
                    if (
                      typeof result === "object" &&
                      !Array.isArray(result) &&
                      result !== null
                    ) {
                      return result;
                    } else {
                      console.error(
                        "Elevar Data Layer: \`window.ElevarTransformFn\` returned a value " +
                          "that wasn't an object, so we've treated things as if this " +
                          "function wasn't defined."
                      );
                      return event;
                    }
                  } catch (error) {
                    console.error(
                      "Elevar Data Layer: \`window.ElevarTransformFn\` threw an error, so " +
                        "we've treated things as if this function wasn't defined. The " +
                        "exact error is shown below."
                    );
                    console.error(error);
                    return event;
                  }
                } else {
                  return event;
                }
              };
              window.ElevarPushToDataLayer = (item) => {
                const enrichedItem = {
                  event_id: window.crypto.randomUUID(),
                  event_time: new Date().toISOString(),
                  ...item,
                };
                const transformedEnrichedItem = window.__ElevarTransformItem
                  ? window.__ElevarTransformItem(enrichedItem)
                  : enrichedItem;
                const listenerPayload = {
                  raw: enrichedItem,
                  transformed: transformedEnrichedItem,
                };
                const getListenerNotifyEvent = () => {
                  return new CustomEvent("elevar-listener-notify", {
                    detail: listenerPayload,
                  });
                };
                if (transformedEnrichedItem._elevar_internal?.isElevarContextPush) {
                  window.__ElevarIsContextSet = true;
                  window.__ElevarDataLayerQueue.unshift(transformedEnrichedItem);
                  if (window.__ElevarIsListenerListening) {
                    window.dispatchEvent(getListenerNotifyEvent());
                  } else {
                    window.__ElevarListenerLoadQueue.unshift(listenerPayload);
                  }
                } else {
                  window.__ElevarDataLayerQueue.push(transformedEnrichedItem);
                  if (window.__ElevarIsListenerListening) {
                    window.dispatchEvent(getListenerNotifyEvent());
                  } else {
                    window.__ElevarListenerLoadQueue.push(listenerPayload);
                  }
                }
                if (window.__ElevarIsContextSet) {
                  while (window.__ElevarDataLayerQueue.length > 0) {
                    window.dataLayer.push(window.__ElevarDataLayerQueue.shift());
                  }
                }
              };

              const configElement = document.getElementById("elevar-dl-aat-config");

              if (!configElement) {
                console.error("Elevar: AAT Config element not found");
                return;
              }

              const config = JSON.parse(configElement.textContent);

              const script = document.createElement("script");
              script.type = "text/javascript";
              script.src = config.data_layer_aat_script;

              script.onerror = () => {
                console.error("Elevar: AAT JS script failed to load");
              };
              script.onload = async () => {
                if (!window.ElevarGtmSuiteAAT) {
                  console.error("Elevar: \`ElevarGtmSuiteAAT\` is not defined");
                  return;
                }

                await window.ElevarGtmSuiteAAT.handlers.register({
                  apexDomain: config.apex_domain,
                  isConsentEnabled: config.consent_enabled,
                });
              };

              document.body.appendChild(script);
            })();
          `,
        }}
      />
    </>
  );
}

DataLayer.displayName = 'DataLayer';

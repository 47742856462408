import { useCallback, useMemo, useEffect } from 'react';
import {
  useCart,
  useCartAddItem,
  useCartRemoveItem,
  useCartRemoveItems,
  useProductByHandle,
  useSettings,
} from '@backpackjs/storefront';

import { CartDrawer } from './CartDrawer';
import { CartPage } from './CartPage';
import { useCartGWP } from './useCartGWP';

export function Cart({ isDrawer = false }) {
  const cart = useCart();
  const { cartAddItem } = useCartAddItem();
  const { cartRemoveItem } = useCartRemoveItem();
  const { cartRemoveItems } = useCartRemoveItems();
  const settings = useSettings();

  const enabledGiftNote = settings?.cart?.giftNote?.enabled;

  const { gwpSpendXGetY, gwpBuyXGetY } = useCartGWP();

  const { product: giftWrapProduct } = useProductByHandle({
    handle: settings?.cart?.giftWrap?.product?.handle || 'gift-wrapping',
  });

  const giftWrapLineItemId = useMemo(() => {
    return cart?.lines.find(
      (line) => line?.variant?.product?.handle === giftWrapProduct?.handle
    )?.id;
  }, [cart?.updatedAt]);

  const toggleGiftWrap = () => {
    if (!giftWrapProduct) return;
    if (giftWrapLineItemId) {
      cartRemoveItem({ lineId: giftWrapLineItemId });
    } else {
      cartAddItem({
        merchandiseId: giftWrapProduct.variants[0].id,
        quantity: 1,
      });
    }
  };

  const removeGWPProducts = useCallback(async (products) => {
    const removeItemIds = products.map(({ id }) => id);
    if (!removeItemIds.length) return;
    await cartRemoveItems({ lineIds: removeItemIds });
  }, []);

  useEffect(() => {
    if (!gwpSpendXGetY?.isActive || !gwpSpendXGetY?.isEligible) {
      const gwpProducts = cart?.lines?.filter(
        (item) =>
          item.attributes.some((attribute) => attribute.key === '_gwp') &&
          gwpSpendXGetY?.gwpProduct?.groupedProducts?.some(
            (productHandle) => productHandle === item.variant?.product?.handle
          )
      );
      if (gwpProducts) {
        removeGWPProducts(gwpProducts);
      }
    }
  }, [cart?.updatedAt, gwpSpendXGetY?.isActive, gwpSpendXGetY?.isEligible]);

  useEffect(() => {
    if (!gwpBuyXGetY?.isActive || !gwpBuyXGetY?.isEligible) {
      const gwpProducts = cart?.lines?.filter(
        (item) =>
          item.attributes.some((attribute) => attribute.key === '_gwp') &&
          gwpBuyXGetY?.gwpProduct?.groupedProducts?.some(
            (productHandle) => productHandle === item.variant?.product?.handle
          )
      );
      if (gwpProducts) {
        removeGWPProducts(gwpProducts);
      }
    }
  }, [cart?.updatedAt, gwpBuyXGetY?.isActive, gwpBuyXGetY?.isEligible]);

  return isDrawer ? (
    <CartDrawer
      enabledGiftNote={enabledGiftNote}
      giftWrapLineItemId={giftWrapLineItemId}
      giftWrapProduct={giftWrapProduct}
      gwpBuyXGetY={gwpBuyXGetY}
      gwpSpendXGetY={gwpSpendXGetY}
      toggleGiftWrap={toggleGiftWrap}
    />
  ) : (
    <CartPage
      enabledGiftNote={enabledGiftNote}
      giftWrapLineItemId={giftWrapLineItemId}
      giftWrapProduct={giftWrapProduct}
      gwpBuyXGetY={gwpBuyXGetY}
      gwpSpendXGetY={gwpSpendXGetY}
      toggleGiftWrap={toggleGiftWrap}
    />
  );
}

Cart.displayName = 'Cart';

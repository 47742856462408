import { useCustomer, useSettings } from '@backpackjs/storefront';

import { Link, Svg } from '@snippets';
import { MenuNestedSidebar } from './MenuNestedSidebar';

export function MenuSidebar({
  handleCloseSidebar,
  menuSidebarOpen,
  nestedSidebarContent,
  handleNestedSidebar,
  handleSubmenuSidebar,
  submenuSidebarContent,
}) {
  const customer = useCustomer();
  const settings = useSettings();
  const promobar = settings?.header?.promobar;
  const promobarDisabled =
    promobar && (!promobar.enabled || !promobar.messages?.length);

  const { links: additionalLinks, menuItems } = { ...settings?.header?.menu };
  const { links: socialLinks } = { ...settings?.footer?.social };

  return (
    <div
      className={`fixed left-0 top-0 z-[60] h-full w-full lg:hidden ${
        menuSidebarOpen ? 'pointer-events-auto' : 'pointer-events-none'
      }
      ${
        promobarDisabled
          ? 'top-[var(--header-height)] lg:top-[var(--header-height-desktop)]'
          : 'top-[calc(var(--header-height)+var(--promobar-height))] lg:top-[calc(var(--header-height-desktop)+var(--promobar-height))]'
      }`}
    >
      {/* Overlay */}
      <div
        role="button"
        aria-hidden="true"
        aria-label="Close menu sidebar"
        tabIndex="-1"
        className={`absolute top-0 left-0 h-full w-full bg-[rgba(0,0,0,0.3)] transition duration-200 md:block ${
          menuSidebarOpen ? 'opacity-100' : 'opacity-0'
        }`}
        onClick={handleCloseSidebar}
      />
      <aside
        className={`absolute right-full top-0 flex h-full w-[70%] max-w-sm flex-col overflow-hidden bg-background px-5 py-8 transition ${
          menuSidebarOpen ? 'translate-x-full' : '-translate-x-0'
        }
        ${
          promobarDisabled
            ? 'pb-[var(--header-height)] lg:pb-[var(--header-height-desktop)]'
            : 'pb-[calc(var(--header-height)+var(--promobar-height))] lg:pb-[calc(var(--header-height-desktop)+var(--promobar-height))]'
        }
        `}
      >
        {menuSidebarOpen && (
          <div className="relative w-full flex-1 overflow-x-hidden">
            <div
              className={`scrollbar-hide h-full w-full overflow-y-auto ${
                nestedSidebarContent ? 'invisible' : 'visible'
              }`}
            >
              <nav className="flex">
                <ul className="flex w-full flex-col gap-3">
                  {menuItems?.map((item, index) => {
                    const hasContent = item.links?.length > 0;
                    return (
                      <li key={index} className="flex w-full flex-col">
                        {hasContent ? (
                          <button
                            aria-label={item.menuItem.text}
                            className="flex w-full items-center justify-between gap-3"
                            onClick={() => handleNestedSidebar(index)}
                            type="button"
                          >
                            <p className="text-nav flex-1 text-left leading-[1.375rem]">
                              {item.menuItem.text}
                            </p>
                          </button>
                        ) : (
                          <Link
                            aria-label={item.menuItem.text}
                            className="text-nav flex-1 text-left leading-[1.375rem]"
                            href={item.menuItem.url}
                            onClick={handleCloseSidebar}
                            newTab={item.menuItem.newTab}
                            type={item.menuItem.type}
                          >
                            {item.menuItem.text}
                          </Link>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </nav>

              <ul className="mt-24 mb-8 flex flex-col gap-2">
                <li>
                  <Link
                    aria-label="Go to account page"
                    className="text-base font-medium normal-case"
                    href={customer ? '/account/orders' : '/account/login'}
                    onClick={handleCloseSidebar}
                  >
                    Account
                  </Link>
                </li>
                {additionalLinks?.map(({ link }, index) => {
                  return (
                    <li key={index}>
                      <Link
                        aria-label={link?.text}
                        className="text-base font-medium normal-case"
                        href={link?.url}
                        onClick={handleCloseSidebar}
                      >
                        {link?.text}
                      </Link>
                    </li>
                  );
                })}
              </ul>

              <ul className="flex gap-2 pb-8">
                {socialLinks?.map(({ platform, url }, index) => {
                  return (
                    <li key={index}>
                      <Link aria-label={platform} href={url} newTab>
                        <Svg
                          className="w-6 text-current transition md:hover:opacity-80"
                          src={`/svgs/noprecache/social/${platform}.svg#${platform}`}
                          title={platform}
                          viewBox="0 0 24 24"
                        />
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <MenuNestedSidebar
              handleCloseSidebar={handleCloseSidebar}
              handleNestedSidebar={handleNestedSidebar}
              nestedSidebarContent={nestedSidebarContent}
              handleSubmenuSidebar={handleSubmenuSidebar}
              submenuSidebarContent={submenuSidebarContent}
            />
          </div>
        )}
      </aside>
    </div>
  );
}

MenuSidebar.displayName = 'MenuSidebar';

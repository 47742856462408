export function Schema({ page }) {
  if (page?.handle !== 'returns') return null;
  return {
    label: 'Returns',
    key: 'returns',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/2597/4944/files/returns-preview.png?v=1680903526',
    fields: [],
  };
}

import { useState, useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';

export function SizeGuideModal() {
  const settings = useSettings();
  const mens = settings?.product?.sizeGuide?.men;
  const womens = settings?.product?.sizeGuide?.women;

  const tables = ['men', 'women'];
  const units = ['inches', 'centimeters'];

  const [activeTable, setActiveTable] = useState(tables[0]);
  const [activeUnits, setActiveUnits] = useState(units[0]);
  const [tableSrc, setTableSrc] = useState(mens?.rows);

  useEffect(() => {
    if (activeTable === 'men' && activeUnits === 'inches') {
      setTableSrc(mens?.rows);
    }
    if (activeTable === 'men' && activeUnits === 'centimeters') {
      setTableSrc(mens?.rowsCm);
    }
    if (activeTable === 'women' && activeUnits === 'inches') {
      setTableSrc(womens?.rows);
    }
    if (activeTable === 'women' && activeUnits === 'centimeters') {
      setTableSrc(womens?.rowsCm);
    }
  }, [activeTable, activeUnits]);

  return (
    <div>
      <h2 className="text-title-h3 mb-6 mt-8 text-center text-5xl uppercase">
        Size Guide
      </h2>

      <div className="mb-14 flex w-full">
        {tables?.map((value) => {
          const isSelected = activeTable === value;
          return (
            <button
              aria-label={value}
              className={`relative inline flex basis-1/2 justify-center bg-[linear-gradient(currentColor,currentColor)] bg-[length:100%_1px] bg-right-bottom bg-no-repeat pb-1 text-xl font-semibold capitalize tracking-[0.0313rem] transition-[background-size] duration-200 md:hover:bg-[length:0%_1px] ${
                isSelected
                  ? 'bg-[length:100%_4px]'
                  : 'bg-[length:100%_1px] text-mediumGray'
              }`}
              onClick={() => setActiveTable(value)}
              type="button"
            >
              {value}
            </button>
          );
        })}
      </div>

      <div className="mb-14 flex w-full items-center justify-center">
        {units?.map((value) => {
          const isSelected = activeUnits === value;
          return (
            <div className="flex basis-1/2 flex-row items-center justify-center md:basis-1/4">
              <button
                aria-label={value}
                className="relative h-7 w-7 rounded-[50%] border border-text text-xs text-text transition md:hover:border-text"
                onClick={() => setActiveUnits(value)}
                type="button"
              >
                <div
                  className={`absolute top-1/2 left-1/2 flex h-full w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-[50%] border-white transition-[border-width] duration-100
                  ${isSelected ? 'border-[3px] bg-black' : 'border-[0px]'}`}
                />
              </button>
              <p className="ml-3 flex text-sm font-semibold capitalize md:text-xl">
                {value}
              </p>
            </div>
          );
        })}
      </div>

      <table className="text-body mx-auto mb-10 h-[20rem] w-full max-w-5xl table-fixed border-collapse border-[1px] border-text text-[11px] uppercase md:text-base">
        {tableSrc?.map((row) => {
          return (
            <tr className="odd:bg-gray first-of-type:border-b-[1px] first-of-type:font-semibold">
              {row?.values?.map((value) => {
                return (
                  <td className="border-collapse overflow-hidden border-l-[1px] border-text py-3.5 px-0 text-center first-of-type:font-semibold">
                    {value}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>

      <div className="mx-auto mb-14 w-full max-w-5xl">
        <p className="mb-3 text-xl font-semibold">How To Measure</p>
        <div className="mb-3 h-[1px] w-16 bg-text after:content-['']" />
        <p className="mb-2">
          <strong>Chest:</strong> Wrap the measuring tape around the widest part
          of your chest.
        </p>
        <p className="mb-2">
          <strong>Waist:</strong> Measure around the slimmest part of your
          waistline.
        </p>
        <p className="mb-2">
          <strong>Hip:</strong> Measure around the widest part of your hips.
        </p>
      </div>
    </div>
  );
}

SizeGuideModal.displayName = 'SizeGuideModal';

import { useSettings } from '@backpackjs/storefront';

import { useSearchAutocomplete } from '@hooks';

export function SearchAutocomplete({ searchOpen, setRawTerm, term }) {
  const settings = useSettings();
  const { autocompleteResults } = useSearchAutocomplete({
    term,
    mounted: searchOpen,
  });

  const { enabled, heading, limit } = { ...settings?.search?.autocomplete };

  return enabled && autocompleteResults.length > 0 ? (
    <div className="mt-4">
      <h5 className="text-xs italic">{heading}</h5>

      <ul className="flex flex-wrap gap-x-2">
        {autocompleteResults.slice(0, limit || 5).map(({ item, refIndex }) => {
          return (
            <li key={refIndex}>
              <button
                aria-label={item.suggestion}
                onClick={() => setRawTerm(item.suggestion)}
                type="button"
              >
                <p className="text-underline text-xs">{item.suggestion}</p>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}

SearchAutocomplete.displayName = 'SearchAutocomplete';

import { useCallback, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { Svg } from '../Svg';

export function EmailSignup() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const settings = useSettings();
  const { enabled, heading, subtext, placeholder, buttonText, listId } = {
    ...settings?.footer?.marketing,
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!email || isLoading) return;
      setIsLoading(true);

      if (!listId) {
        setIsLoading(false);
        setError('List ID is required');
        return;
      }

      try {
        const response = await fetch('/api/klaviyo', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify({
            action: 'clientSubscribe',
            email,
            phone: null,
            source: window.location.origin + window.location.pathname,
            acceptsMarketing: false,
            listId,
          }),
        });
        if (response.ok) {
          setSubmitted('Email submitted!');
        }
        const data = await response.json();
        if (!response.ok && data.errors) {
          setError(data?.data || data?.errors);
        }
        setIsLoading(false);
        setEmail('');
      } catch (error) {
        setIsLoading(false);
        setError('Please try again later');
        console.error(error);
      }
    },
    [email, isLoading]
  );

  return enabled ? (
    <form className="mb-3 pt-8 md:mb-6 md:p-0" onSubmit={handleSubmit}>
      <h5 className="text-nav text-current">{heading}</h5>

      {subtext && <p className="mt-2 text-sm text-current">{subtext}</p>}
      <div className="group relative mt-4">
        <input
          className="w-full cursor-pointer border-b border-black py-1 pr-10 text-base placeholder-black md:py-2"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder={placeholder}
          required
          type="email"
          value={email}
          disabled={isLoading}
        />
        <button
          aria-label={buttonText}
          className="absolute -right-3 top-[calc(50%-18px)] p-3 transition-[right] group-hover:-right-3 md:right-0"
          type="submit"
          disabled={isLoading}
        >
          <Svg
            className="w-[1.375rem] text-black"
            src="/svgs/long-arrow-right.svg#long-arrow-right"
            title="Long Arrow Right"
            viewBox="0 0 23 8"
          />
        </button>
      </div>

      {submitted && (
        <p className="text-sm font-semibold text-[var(--green)]">
          Email has been submitted.
        </p>
      )}

      {error && (
        <p className="text-sm font-semibold text-[var(--red)]">
          Something went wrong. Please try again.
        </p>
      )}
    </form>
  ) : null;
}

EmailSignup.displayName = 'EmailSignup';

import { useCartItems } from '@backpackjs/storefront';

import { useGlobalContext } from '@contexts';
import { CartEmpty } from './CartEmpty';
import { CartGiftNote } from './CartGiftNote';
import { CartGWP } from './CartGWP';
import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';
import { FreeShippingMeter } from './FreeShippingMeter';

export function CartDrawer({
  enabledGiftNote,
  giftWrapLineItemId,
  giftWrapProduct,
  gwpBuyXGetY,
  gwpSpendXGetY,
  toggleGiftWrap,
}) {
  const cartItems = useCartItems();
  const {
    actions: { closeCart },
  } = useGlobalContext();

  return (
    <>
      <FreeShippingMeter />

      <ul className="scrollbar-hide relative flex-1 overflow-y-auto p-5">
        {cartItems?.length ? (
          cartItems.map((item) => {
            const isGiftWrap =
              giftWrapProduct?.handle === item?.variant?.product?.handle;

            const groupedProducts = [
              ...((gwpSpendXGetY?.gwpProduct?.groupedProducts &&
                gwpSpendXGetY?.gwpProduct?.groupedProducts) ||
                []),
              ...((gwpBuyXGetY?.gwpProduct?.groupedProducts &&
                gwpBuyXGetY?.gwpProduct?.groupedProducts) ||
                []),
            ];

            const isGWP = groupedProducts?.some(
              (product) => product === item?.variant?.product?.handle
            );

            return (
              <li
                key={item.id}
                className="border-b border-b-border first:-mt-5 last:border-none"
              >
                <CartItem
                  closeCart={closeCart}
                  isGiftWrap={isGiftWrap}
                  isGWP={isGWP}
                  item={item}
                />
              </li>
            );
          })
        ) : (
          <CartEmpty closeCart={closeCart} />
        )}
      </ul>

      <CartGWP
        closeCart={closeCart}
        gwpBuyXGetY={gwpBuyXGetY}
        gwpSpendXGetY={gwpSpendXGetY}
      />

      <div className="shadow-[0_-3px_30px_rgba(0,0,0,0.08)]">
        {enabledGiftNote && <CartGiftNote closeCart={closeCart} />}
        <CartTotals
          closeCart={closeCart}
          giftWrapLineItemId={giftWrapLineItemId}
          giftWrapProduct={giftWrapProduct}
          toggleGiftWrap={toggleGiftWrap}
        />
      </div>
    </>
  );
}

CartDrawer.displayName = 'CartDrawer';

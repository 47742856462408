import React from 'react';
import Script from 'next/script';
import { Schema } from './InstagramFeed.schema';

import { useYotpoRefreshOnMount } from '../ProductReviews/useYotpoRefreshOnMount';

export function InstagramFeed({ cms }) {
  const { heading, section } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  useYotpoRefreshOnMount({ delay: 50 });

  return (
    <div className={`py-px ${section?.fullBleed ? '' : 'px-contained'}`}>
      <Script
        type="text/javascript"
        id="yotpo-script"
        strategy="afterInteractive"
        src={`https://staticw2.yotpo.com/${process.env.NEXT_PUBLIC_YOTPO_KEY}/widget.js`}
      />
      <div className={`mx-auto ${maxWidthClass}`}>
        {heading && (
          <div className="mb-10 text-center md:px-6 lg:px-10">
            <h1>{heading}</h1>
          </div>
        )}
        <div
          className="yotpo yotpo-pictures-widget"
          data-gallery-id="5f7e043742c01f77eb9259d2"
        />
      </div>
    </div>
  );
}

InstagramFeed.displayName = 'InstagramFeed';
InstagramFeed.Schema = Schema;

import { useRouter } from 'next/router';
import { useProductByHandle } from '@backpackjs/storefront';

import { useYotpoRefreshOnMount } from './useYotpoRefreshOnMount';
import { Schema } from './ProductReviews.schema';

export function ProductReviews() {
  const router = useRouter();
  const handle = router?.query?.handle;
  const { product } = useProductByHandle({ handle });
  const isRiseGiftCard = product?.handle === 'rise-ai-giftcard';
  useYotpoRefreshOnMount({ delay: 50 });

  if (isRiseGiftCard || !product) return null;

  return (
    <div className="border-t border-[#e3e3e3]">
      <div
        id="YoptoProductReviews"
        data-comp="YoptoProductReviews"
        className="yotpo yotpo-main-widget md:!px-6 lg:!px-10 xl:!px-[100px] [&_.yotpo-label-container]:!border-none"
        data-product-id={product?.legacyResourceId}
        data-price={product?.variants[0]?.price}
        data-currency={product?.priceRange.maxVariantPrice.currencyCode}
        data-name={product?.title}
      />
    </div>
  );
}

ProductReviews.displayName = 'ProductReviews';
ProductReviews.Schema = Schema;

export function Schema() {
  return {
    category: 'Media',
    label: 'Products Grid',
    key: 'products-grid',
    previewSrc: '',
    fields: [
      {
        label: 'Products',
        name: 'products',
        component: 'group',
        description: 'Products',
        fields: [
          {
            label: 'Show image or product',
            name: 'showProduct',
            component: 'toggle',
            description: 'Select image or product',
            toggleLabels: {
              true: 'Product',
              false: 'Image',
            },
            defaultValue: false,
          },
          {
            label: 'Products per row',
            name: 'productsPerRow',
            component: 'number',
            description: 'Number of products per row',
            defaultValue: 3,
          },
          {
            label: 'Products per row mobile',
            name: 'productsPerRowMb',
            component: 'number',
            description:
              'Number of products per row in mobile. If empty, will use the same value as desktop',
            defaultValue: 0,
          },
          {
            label: 'Gap between products',
            name: 'gap',
            component: 'number',
            description: 'Gap between products',
            defaultValue: 4,
          },
          {
            label: 'Images',
            name: 'images',
            description: 'Grid requires four images',
            component: 'group-list',
            itemProps: {
              label: '{{item.alt}}',
            },
            fields: [
              {
                label: 'Image Alt',
                name: 'alt',
                component: 'text',
                description: 'Brief description of image',
              },
              {
                label: 'Image',
                name: 'image',
                component: 'image',
              },
            ],
            defaultItem: {
              alt: 'Man in white short sleeve shirt',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
              },
            },
            defaultValue: [
              {
                alt: 'Man in white short sleeve shirt',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
                },
              },
              {
                alt: 'Man in white short sleeve shirt',
                image: {
                  src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
                },
              },
            ],
          },
          {
            label: 'Products',
            name: 'productsList',
            component: 'group-list',
            itemProps: {
              label: '{{item.product.handle}}',
            },
            fields: [
              {
                name: 'product',
                component: 'productSearch',
                label: 'Product',
              },
            ],
            defaultItem: {
              product: {
                handle: 'nolen-long-sleeve-shirt-black',
              },
            },
            defaultValue: [
              {
                product: {
                  handle: 'nolen-long-sleeve-shirt-black',
                },
              },
              {
                product: {
                  handle: 'nolen-long-sleeve-shirt-black',
                },
              },
            ],
          },
        ],
        defaultValue: {
          showProduct: false,
          productsPerRow: 3,
          productsPerRowMb: 0,
          gap: 4,
          images: [
            {
              alt: 'Man in white short sleeve shirt',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
              },
            },
            {
              alt: 'Man in white short sleeve shirt',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
              },
            },
            {
              alt: 'Man in white short sleeve shirt',
              image: {
                src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/kenzie-kraft-9QW52RyBLao-unsplash.jpg?v=1672348135',
              },
            },
          ],
          productsList: [
            { product: { handle: 'nolen-long-sleeve-shirt-black' } },
            { product: { handle: 'nolen-long-sleeve-shirt-black' } },
            { product: { handle: 'nolen-long-sleeve-shirt-black' } },
          ],
        },
      },
      {
        label: 'Product Item Settings',
        name: 'productItem',
        component: 'group',
        description: 'Star rating, color variant selector, quick shop',
        fields: [
          {
            label: 'Enable Star Rating',
            name: 'enabledStarRating',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Color Variant Selector',
            name: 'enabledColorSelector',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Quick Shop',
            name: 'enabledQuickShop',
            component: 'toggle',
            description:
              'Note: Quick shop is hidden on mobile and will only show if the product item has only one variant or multiple variants through a single option, e.g. "Size',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Enable Product Name and Price',
            name: 'enableNamePrice',
            component: 'toggle',
            description: 'Shows product name and price on hover. ',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          enabledStarRating: false,
          enabledColorSelector: false,
          enabledQuickShop: false,
          enableNamePrice: false,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Full width',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width from contained styles',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          fullWidth: false,
        },
      },
    ],
  };
}

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import { usePicture } from './usePicture';
import { PictureSource } from './PictureSource';
import { PictureImage } from './PictureImage';

const breakpoints = [
  '30em', // 480px
  '40em', // 640px
  '48em', // 768px
  '64em', // 1024px
  '80em', // 1280px
  '96em', // 1440px
];

export function Picture({
  // inView settings
  aboveTheFold = false,
  alt = '',
  inputRef,
  images = [{ src: '/svgs/default-image.svg', width: 600, ratio: 1 }],
  imageClassName = '',
  rootMargin = '100px',
  threshold = 0.01,
  triggerOnce = true,
  // events
  onError = () => {},
  onLoad = () => {},
  ...props
}) {
  const picture = usePicture({ images });

  const [inViewRef, inView] = useInView({
    rootMargin,
    triggerOnce,
    threshold,
  });

  const setRefs = useCallback(
    (node) => {
      if (inputRef) inputRef.current = node;
      inViewRef(node);
    },
    [inViewRef]
  );

  return (
    <picture
      {...props}
      ref={setRefs}
      className={`relative box-border flex h-full w-full max-w-full overflow-hidden border-0 p-0 ${props.className}`}
      style={{ paddingBottom: picture.paddingBottom }}
    >
      {picture.sources.map((source, index, sources) => {
        const image = images?.[index] || null;
        const breakpoint = breakpoints?.[index] || null;
        const breakpointPrev = breakpoints?.[index - 1] || null;
        const isLast = index === sources.length - 1;
        if (!breakpoint || !image) return null;
        return (
          <PictureSource
            key={index}
            breakpoint={breakpoint}
            breakpointPrev={breakpointPrev}
            width={image.width}
            src={source}
            isLast={isLast}
          />
        );
      })}

      <PictureImage
        alt={alt}
        initialSrc={picture.sources.find(Boolean)}
        imageClassName={imageClassName}
        onError={onError}
        onLoad={onLoad}
        aboveTheFold={aboveTheFold}
        inView={inView}
      />
    </picture>
  );
}

Picture.displayName = 'Picture';
Picture.propTypes = {
  aboveTheFold: PropTypes.bool,
  alt: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  imageClassName: PropTypes.string,
  rootMargin: PropTypes.string,
  threshold: PropTypes.number,
  triggerOnce: PropTypes.bool,
  onError: PropTypes.func,
  onLoad: PropTypes.func,
};

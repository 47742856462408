import { Fragment } from 'react';

import { Badges } from '@snippets';
import { ProductImage } from './ProductImage';
import { ProductVideo } from './ProductVideo';

export function ProductMedia({
  product,
  selectedMedia,
  selectedVariant,
  handleMediaClick,
}) {
  return (
    <div className="grid grid-cols-2 gap-5">
      {selectedMedia?.map((media, index) => {
        if (media.mediaContentType === 'VIDEO') {
          return (
            <Fragment key={index}>
              <ProductVideo
                media={media}
                handleMediaClick={() => handleMediaClick(index)}
              />
            </Fragment>
          );
        }
        return (
          <Fragment key={index}>
            <ProductImage
              media={media}
              title={selectedVariant?.product?.title}
              priority
              handleMediaClick={() => handleMediaClick(index)}
            />
          </Fragment>
        );
      })}

      <div className="pointer-events-none absolute left-0 top-0 z-[1] p-2.5 xs:p-4 md:p-3 xl:p-4">
        <Badges
          isDraft={product?.status === 'DRAFT'}
          tags={selectedVariant?.product?.tags}
        />
      </div>
    </div>
  );
}

ProductMedia.displayName = 'ProductMedia';

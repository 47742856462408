import { Swiper, SwiperSlide } from 'swiper/react';

import { Image, Link } from '@snippets';
import { Schema } from './ImageTiles.schema';

export function ImageTiles({ cms }) {
  const { content, heading, section, tiles } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div className="lg-px-contained py-4 md:py-6">
      <div className={`mx-auto ${maxWidthClass}`}>
        {heading && (
          <h2 className="text-title-h2 mb-6 px-4 text-center md:mb-10">
            {heading}
          </h2>
        )}

        {tiles?.length > 0 && (
          <Swiper
            grabCursor
            slidesOffsetAfter={16}
            slidesOffsetBefore={16}
            slidesPerView={1.4}
            spaceBetween={16}
            breakpoints={{
              768: {
                slidesPerView: tiles.length < 3 ? 1.4 : 2.4,
                slidesOffsetBefore: 32,
                slidesOffsetAfter: 32,
                spaceBetween: 22,
              },
              1024: {
                slidesPerView: tiles.length < 3 ? 2 : 3,
                slidesOffsetBefore: 0,
                slidesOffsetAfter: 0,
                spaceBetween: 22,
              },
            }}
          >
            {tiles.slice(0, 3).map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <Link
                    aria-label={item.link.text}
                    className="w-full"
                    href={content?.clickableImage ? item.link.url : ''}
                    newTab={item.link.newTab}
                  >
                    <div
                      className={`relative bg-offWhite ${section?.aspectRatio}`}
                    >
                      {item.image?.src && (
                        <Image
                          alt={item.alt}
                          className={`${item.position}`}
                          fill
                          sizes="(min-width: 1440px) 800px, (min-width: 1024px) 30vw, (min-width: 768px) 50vw, 100vw"
                          src={item.image.src}
                        />
                      )}
                    </div>

                    <div
                      className={`pointer-events-none absolute inset-0 flex h-full w-full p-6 text-center md:p-8 ${
                        content?.darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : ''
                      } ${content?.contentPosition}`}
                    >
                      <div className="pointer-events-auto flex flex-col gap-3 lg:gap-4">
                        {item.heading && (
                          <h3
                            className="text-xl sm:text-2xl"
                            style={{ color: item.color }}
                          >
                            {item.heading}
                          </h3>
                        )}

                        {!content?.hideButton && item.link?.text && (
                          <div className="">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <Link
                              aria-label={item.link.text}
                              className={`${content?.buttonStyle}`}
                              href={
                                !content?.clickableImage ? item.link.url : ''
                              }
                              newTab={item.link.newTab}
                            >
                              {item.link.text}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
      </div>
    </div>
  );
}

ImageTiles.displayName = 'ImageTiles';
ImageTiles.Schema = Schema;

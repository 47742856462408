import { defaultValues } from './ProductFeatures.defaultValues';

export function Schema({ product }) {
  if (!product) return null;
  return {
    category: 'Product',
    label: 'Product Features',
    key: 'product-features',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/2597/4944/files/product-features-preview.png?v=1679506120',
    fields: [
      {
        name: 'productTypes',
        label: 'Product Groups',
        description:
          'A Product Group should narrowly target only the products that will share the same Product Features section via a shared Shopify Tag.',
        component: 'group-list',
        itemProps: {
          label: '{{item.title}}',
        },
        fields: [
          {
            name: 'title',
            label: 'Product Group Name',
            component: 'text',
          },
          {
            name: 'tags',
            label: 'Product Group Shopify Tags',
            component: 'tags',
            description:
              'Hit enter or spacebar to add tag. A tag must match exact spelling, casing, and spacing of the Shopify tag. If tag has any spaces, it must be copy and pasted in. Products that match ANY of these tags will be targeted.',
          },
          {
            name: 'headingOverride',
            component: 'text',
            label: 'Heading Override',
            description: 'Overrides "Fabrication" heading text.',
            defaultValue: '',
          },
          {
            name: 'productFeatures',
            label: 'Features',
            component: 'group-list',
            itemProps: {
              label: '{{item.poster}}',
            },
            fields: [
              {
                name: 'poster',
                label: 'Product Feature',
                component: 'select',
                options: [
                  {
                    label: '2-Way Stretch',
                    value: '2-way-stretch',
                  },
                  {
                    label: '4-Way Stretch',
                    value: '4-way-stretch',
                  },
                  {
                    label: 'Anti Dust & Pollen',
                    value: 'anti-dust',
                  },
                  {
                    label: 'Antimicrobial',
                    value: 'antimicrobial',
                  },
                  {
                    label: 'Breathable',
                    value: 'breathable',
                  },
                  {
                    label: 'Dobby Weave',
                    value: 'dobby-weave',
                  },
                  {
                    label: 'Double-Knit Jacquard Weave',
                    value: 'double-knit-jacquard-weave',
                  },
                  {
                    label: 'Easy Care',
                    value: 'easy-care',
                  },
                  {
                    label: 'Hidden Pockets',
                    value: 'hidden-pockets',
                  },
                  {
                    label: 'Linear Flex®',
                    value: 'linear-flex',
                  },
                  {
                    label: 'Luxseam®',
                    value: 'luxseam',
                  },
                  {
                    label: 'Polymuse',
                    value: 'polymuse',
                  },
                  {
                    label: 'Moisture Wicking',
                    value: 'moisture-wicking',
                  },
                  {
                    label: 'Quick Dry',
                    value: 'quick-dry',
                  },
                  {
                    label: 'Seven Pockets',
                    value: 'seven-pockets',
                  },
                  {
                    label: 'Shape Retention',
                    value: 'shape-retention',
                  },
                  {
                    label: 'Skin Friendly',
                    value: 'skin-friendly',
                  },
                  {
                    label: 'Soft Touch',
                    value: 'soft-touch',
                  },
                  {
                    label: 'Upcycle',
                    value: 'upcycle',
                  },
                  {
                    label: 'Washable',
                    value: 'washable',
                  },
                  {
                    label: 'Water Resistant',
                    value: 'water-resistant',
                  },
                  {
                    label: 'Wrinkle Free',
                    value: 'wrinkle-free',
                  },
                  {
                    label: 'Zipper Pockets',
                    value: 'zipper-pockets',
                  },
                ],
              },
              {
                name: 'heading',
                component: 'text',
                label: 'Heading',
                description: 'Add text to override default heading',
              },
              {
                name: 'description',
                component: 'text',
                label: 'Description',
                description: 'Add text to override default description',
              },
            ],
          },
        ],
        defaultValue: defaultValues,
      },
    ],
  };
}

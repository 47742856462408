import { useVariantPrices } from '@hooks';

export function ProductItemPrice({ selectedVariant, isCollectionPage, isGWP }) {
  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  return (
    <div className="flex gap-1.5">
      {compareAtPrice && (
        <p
          className={`${
            isCollectionPage ? 'text-sm md:text-lg' : 'text-sm'
          } leading-[1.5rem] text-mediumGray line-through`}
        >
          {compareAtPrice}
        </p>
      )}
      <p
        className={`${isCollectionPage ? 'text-sm md:text-lg' : 'text-sm'} ${
          isGWP ? 'line-through' : ''
        } leading-[1.5rem]`}
      >
        {price}
      </p>
    </div>
  );
}

ProductItemPrice.displayName = 'ProductItemPrice';

import { useLoopOnStore, useLoopParams } from '@hooks/loopReturns';
import Script from 'next/script';
import React from 'react';

export function LoopReturns() {
  useLoopOnStore();
  useLoopParams();
  return (
    <Script
      id="loop-onstore-sdk"
      src="https://cdn.jsdelivr.net/npm/@loophq/onstore-sdk@latest/dist/loop-onstore-sdk.min.js"
    />
  );
}

import { useEffect } from 'react';

export function WairFitAdvisor({ product }) {
  const handle = product?.handle;
  const image = product?.images?.[0]?.src;

  useEffect(() => {
    if (window && product) {
      (function (i, s, o, n, c, g, a) {
        for (let j = 0; j < n.length; j++) {
          a = s.createElement(o);
          a.id = n[j];
          a.type = 'text/javascript';
          a.async = 1;
          a.src = n[j];
          const m = document.getElementsByTagName(o)[0];
          m.parentNode.insertBefore(a, m);
        }
        i.addEventListener('onPredictLoad', function (event) {
          event.detail.predict.render({
            brandKey: '7diamonds.myshopify.com',
          });
        });
      })(window, document, 'script', [
        'https://predict-v4.getwair.com/default/predict.js',
      ]);
    }
  }, [product]);

  if (!product) return null;

  return (
    <div key="wairFitAdvisor">
      <div
        key="predictPlacement"
        id="predict-placement"
        className="predict-placement"
        data-predict-product-key={handle}
        data-predict-product-name={product.title}
        data-predict-product-img={image}
      />
    </div>
  );
}

WairFitAdvisor.displayName = 'WairFitAdvisor';

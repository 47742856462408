import { useCartCount, useSettings } from '@backpackjs/storefront';

import { useGlobalContext } from '@contexts';
import { Svg } from '@snippets/Svg';

export function SideDrawerHeader() {
  const settings = useSettings();
  const cartCount = useCartCount();
  const {
    state: { cartOpen, searchOpen },
    actions: { closeCart, openCart, openSearch },
  } = useGlobalContext();

  return (
    <div className="relative flex h-[var(--header-height)] items-center px-14 shadow-[0_3px_30px_rgba(0,0,0,0.08)]">
      <button
        aria-label="Close cart"
        className="absolute left-4 top-1/2 -translate-y-1/2"
        onClick={closeCart}
        type="button"
      >
        <Svg
          className="w-5 text-text"
          src="/svgs/arrow-left.svg#arrow-left"
          title="Arrow Left"
          viewBox="0 0 24 24"
        />
      </button>

      <div className="flex items-center">
        {cartOpen && (
          <>
            <h3 className="text-label text-[13px]">
              {settings?.cart?.heading}
            </h3>
            <p className="text-label ml-1 text-[13px]">({cartCount || 0})</p>
          </>
        )}

        {searchOpen && <h3 className="text-label text-[13px]">Search</h3>}
      </div>

      {cartOpen && (
        <button
          className="absolute right-4 top-1/2 flex -translate-y-1/2 items-center"
          onClick={() => openSearch()}
          type="button"
        >
          <Svg
            className="w-5 text-text"
            src="/svgs/search.svg#search"
            title="Search"
            viewBox="0 0 24 24"
          />
        </button>
      )}

      {searchOpen && (
        <button
          className="absolute right-4 top-1/2 flex -translate-y-1/2 items-center"
          onClick={() => openCart()}
          type="button"
        >
          <Svg
            className="w-5 text-text"
            src="/svgs/cart.svg#cart"
            title="Cart"
            viewBox="0 0 24 24"
          />
        </button>
      )}
    </div>
  );
}

SideDrawerHeader.displayName = 'SideDrawerHeader';

export const sizeMap = (value) => {
  switch (value.toLowerCase()) {
    case 'x-small':
      return 'XS';
    case 'small':
      return 'S';
    case 'medium':
      return 'M';
    case 'large':
      return 'L';
    case 'x-large':
      return 'XL';
    case 'xx-large':
      return 'XXL';
    case 'xxx-large':
      return '3XL';
    case 'x-small/small':
      return 'XS/S';
    case 'medium/large':
      return 'M/L';
    case 'x-large/xx-large':
      return 'XL/XXL';
    default:
      return value;
  }
};

import { useGlobalContext } from '@contexts';

import { QuickshopProductMain } from '@templates/product/QuickshopProductMain';

export function QuickShop({ selectedProduct }) {
  const {
    actions: { openModal },
  } = useGlobalContext();
  return (
    <div className="absolute bottom-4 z-10 mt-5 hidden w-full justify-center opacity-0 transition group-hover:opacity-100 lg:mt-6 lg:flex">
      <button
        type="button"
        className="btn-inverse-light w-[calc(100%-32px)]"
        onClick={(e) => {
          e.preventDefault();
          openModal(
            <QuickshopProductMain product={selectedProduct} />,
            {
              className: 'max-lg:hidden',
            },
            selectedProduct
          );
        }}
      >
        Quickshop
      </button>
    </div>
  );
}

QuickShop.displayName = 'QuickShop';

import {
  useCartItems,
  useCartCount,
  useCustomer,
  useSettings,
} from '@backpackjs/storefront';

import { useGlobalContext, useWishlistContext } from '@contexts';
import { cartViewGtm } from '@utils/dataLayer';
import { Link } from '../Link';
import { Svg } from '../Svg';

export function Menu({
  handleCloseSidebar,
  handleOpenSidebar,
  handleMenuDrawerClose,
  handleMenuHoverIn,
  handleMenuHoverOut,
  menuDrawerContent,
  menuSidebarOpen,
}) {
  const customer = useCustomer();
  const {
    state: { wishlist },
  } = useWishlistContext();
  const {
    actions: { openCart, openSearch },
  } = useGlobalContext();
  const cartItems = useCartItems();
  const cartCount = useCartCount();
  const settings = useSettings();
  const { menuItems } = { ...settings?.header?.menu };

  return (
    <div className="relative z-[70] grid h-[var(--header-height)] grid-cols-[1fr_auto_1fr] gap-4 bg-background px-5 shadow-[0px_3px_30px_rgba(0,0,0,0.08)] transition lg:h-[var(--header-height-desktop)] lg:gap-8 lg:px-8 xl:px-11">
      <div className="order-2 flex items-center lg:order-1">
        <Link aria-label="Go to homepage" href="/">
          <Svg
            className="h-8 text-text xs:h-10 sm:h-14"
            src="/svgs/logo.svg#logo"
            title="Storefront logo"
            viewBox="0 0 500 105"
          />
        </Link>
      </div>

      <div className="order-1 flex items-center lg:order-2 lg:justify-center">
        <nav className="hidden h-full lg:flex">
          <ul className="flex">
            {menuItems?.map((item, index) => {
              const isHovered =
                item.menuItem?.text === menuDrawerContent?.menuItem?.text;

              return (
                <li key={index} className="flex">
                  <Link
                    aria-label={item.menuItem?.text}
                    className={`group relative flex items-center px-4 transition ${
                      isHovered ? 'bg-offWhite' : 'bg-background'
                    }`}
                    href={item.menuItem?.url}
                    onClick={handleMenuDrawerClose}
                    onMouseEnter={() => handleMenuHoverIn(item)}
                    onMouseLeave={handleMenuHoverOut}
                  >
                    <p className="text-nav">{item.menuItem?.text}</p>

                    <div
                      className={`after:w-fill absolute top-[calc(100%_-_2px)] left-0 h-[3px] w-full origin-center scale-0 border-t-2 border-t-primary bg-transparent transition group-hover:scale-100 ${
                        isHovered ? 'scale-100' : 'scale-0'
                      }`}
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="flex items-center gap-4 lg:hidden">
          {menuSidebarOpen ? (
            <button
              aria-label="Close menu"
              className="w-6"
              onClick={handleCloseSidebar}
              type="button"
            >
              <Svg
                className="w-6"
                src="/svgs/close.svg#close"
                title="Close"
                viewBox="0 0 24 24"
              />
            </button>
          ) : (
            <button
              aria-label="Open menu"
              className="w-6"
              onClick={handleOpenSidebar}
              type="button"
            >
              <Svg
                className="w-6"
                src="/svgs/menu.svg#menu"
                title="Menu"
                viewBox="0 0 26 26"
              />
            </button>
          )}
        </div>
      </div>

      <div className="order-3 flex items-center justify-end gap-3 md:gap-4 xl:gap-12">
        <Link
          aria-label="Wishlist"
          href="/pages/wishlist"
          className="hidden w-[17px] md:block"
          type="button"
        >
          {wishlist?.length ? (
            <Svg
              className="w-[17px] fill-black"
              viewBox="0 0 24 24"
              alt="Favorite"
              src="/svgs/heart-filled.svg#heart-filled"
            />
          ) : (
            <Svg
              className="w-[17px]"
              viewBox="0 0 24 24"
              alt="Favorite"
              src="/svgs/heart.svg#heart"
            />
          )}
        </Link>

        <button
          aria-label="Open search"
          className="w-6 md:w-[17px]"
          onClick={openSearch}
          type="button"
        >
          <Svg
            className="w-6 text-text md:w-[17px]"
            src="/svgs/search.svg#search"
            title="Search"
            viewBox="0 0 26 26"
          />
        </button>

        <Link
          className="hidden lg:block"
          aria-label="Go to account page"
          href={customer ? '/account/orders' : '/account/login'}
        >
          {customer ? (
            <Svg
              className="w-6 text-text md:w-[17px]"
              src="/svgs/account.svg#account"
              title="Account"
              viewBox="0 0 26 26"
            />
          ) : (
            <Svg
              className="w-6 text-text md:w-[17px]"
              src="/svgs/login.svg#login"
              title="Login"
              viewBox="0 0 26 26"
            />
          )}
        </Link>

        <div className="relative flex items-center">
          <button
            aria-label="Open cart"
            className="flex items-center"
            onClick={() => {
              openCart();
              cartViewGtm({ lineItems: cartItems });
            }}
            type="button"
          >
            <Svg
              className="w-6 text-text md:w-[17px]"
              src="/svgs/cart.svg#cart"
              title="Cart"
              viewBox="0 0 26 26"
            />
            <p className="text-label-sm w-4 whitespace-nowrap pl-px font-bold">
              ({cartCount || 0})
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}

Menu.displayName = 'Menu';

import { useState } from 'react';

import { Menu } from './Menu';
import { MenuDrawer } from './MenuDrawer';
import { MenuSidebar } from './MenuSidebar';
import { Promobar } from './Promobar';
import { useMenuDrawer } from './useMenuDrawer';
import { useMenuSidebar } from './useMenuSidebar';

export function Header({ promobarDisabled }) {
  const {
    state: { menuDrawerContent },
    actions: { handleMenuDrawerClose, handleMenuHoverIn, handleMenuHoverOut },
  } = useMenuDrawer();

  const {
    state: { menuSidebarOpen, nestedSidebarContent, submenuSidebarContent },
    actions: {
      handleCloseSidebar,
      handleOpenSidebar,
      handleNestedSidebar,
      handleSubmenuSidebar,
    },
  } = useMenuSidebar();

  const [promobarHidden, setPromobarHidden] = useState(false);

  return (
    <header className="fixed right-0 top-0 left-0 z-20 flex flex-col bg-background transition-[height] duration-300 ease-out">
      <Promobar
        promobarDisabled={promobarDisabled}
        promobarHidden={promobarHidden}
        setPromobarHidden={setPromobarHidden}
      />

      <Menu
        handleCloseSidebar={handleCloseSidebar}
        handleOpenSidebar={handleOpenSidebar}
        handleMenuDrawerClose={handleMenuDrawerClose}
        handleMenuHoverIn={handleMenuHoverIn}
        handleMenuHoverOut={handleMenuHoverOut}
        menuDrawerContent={menuDrawerContent}
        menuSidebarOpen={menuSidebarOpen}
      />

      <MenuDrawer
        handleMenuDrawerClose={handleMenuDrawerClose}
        handleMenuHoverIn={handleMenuHoverIn}
        handleMenuHoverOut={handleMenuHoverOut}
        menuDrawerContent={menuDrawerContent}
      />

      <MenuSidebar
        handleCloseSidebar={handleCloseSidebar}
        menuSidebarOpen={menuSidebarOpen}
        handleNestedSidebar={handleNestedSidebar}
        nestedSidebarContent={nestedSidebarContent}
        handleSubmenuSidebar={handleSubmenuSidebar}
        submenuSidebarContent={submenuSidebarContent}
      />
    </header>
  );
}

Header.displayName = 'Header';
